import { useState, useEffect } from "react"
import { Alert, DatePicker, Form, Input, Tooltip } from "antd"

import FormSectionWrapper from "pages/dashboard/people/people/FormSectionWrapper"
import PrevNextButton from "pages/dashboard/people/people/form/PrevNextButton"

import { useGet } from "utils/useFetch"
import { IKYCData, KYCWalletBody } from "./interface/kyc"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { CustomInput } from "components/new-stuff/form"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import dayjs from "dayjs"

export const BvnTab = ({
  setActiveKey,
  isActive,
  setUserInfo,

  userInfo,
}: {
  isActive: boolean
  userInfo: KYCWalletBody
  setUserInfo: Function
  setActiveKey: React.Dispatch<React.SetStateAction<string[]>>
  // kyc: IKYCData | undefined
}) => {
  const [form] = Form.useForm()

  const [updateStatus, setUpdateStatus] = useState("")
  const [alpha2Code, setAlpha2Code] = useState<string>("")
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: KYCWalletBody) => {
      const res = await axiosInstance.post(
        `/admin/company/update_verification`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        mixPanelEvent("settings-integration-payment-config-edit-success")
      }
    },
  })

  const onFinish = (values: Values) => {
    const { first_name, last_name, bvn_number, birthday, mobileNumber } = values
    const DOB = dayjs(birthday).format("DD/MM/YYYY")

    setUserInfo({
      ...userInfo,
      rep_bvn: bvn_number,
      rep_first_name: first_name,
      rep_last_name: last_name,
      rep_phone: mobileNumber,
      rep_dob: DOB,
    })

    submit({
      rep_bvn: bvn_number,
      rep_first_name: first_name,
      rep_last_name: last_name,
      rep_phone: mobileNumber,
      rep_dob: DOB,
    })
    mixPanelEvent("settings-integration-payment-config-edit-init")
    setActiveKey(["3"])
  }
  const reqBody = {
    q: "",
    page: "",
  }

  const { isLoading: isGetKYCLoading, data: kyc } = useGet<IKYCData>({
    url: `/admin/company/view/settings?id=verify&x=`,
  })

  useEffect(() => {
    if (kyc?.verification) {
      setAlpha2Code(kyc?.verification?.country_code)
    }
  }, [kyc])

  return (
    <FormSectionWrapper
      name="BVN"
      title="BVN"
      subtitle="Enter bank account information where your withdrawals will be deposited"
      isActive={isActive}
      initialValues={{
        first_name:
          kyc?.verification?.verificationEntities?.[0]?.first_name ?? "",
        last_name:
          kyc?.verification?.verificationEntities?.[0]?.last_name ?? "",
        bvn_number: kyc?.verification?.verificationEntities?.[0]?.bvn ?? "",

        birthday: kyc?.verification?.verificationEntities?.[0]?.date_of_birth
          ? dayjs(
              kyc?.verification?.verificationEntities?.[0]?.date_of_birth,
              "DD/MM/YYYY"
            )
          : "",
        mobileNumber: kyc?.verification?.verificationEntities?.[0]?.phone ?? "",
      }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      style={{ maxWidth: 1024 }}
      autoComplete="off"
      footerBtns={
        <PrevNextButton
          showPrevButton={true}
          onClickPrev={() => {
            setActiveKey(["1"])
          }}
        />
      }
    >
      <div className=" px-4 md:px-6 xl:px-[50px]">
        <Alert
          message={
            "We take your security very seriously and so we will not store any information that may be an infringement on your privacy. What this means is that we will not have access to your other bank accounts or financial history. Your information is safe with us."
          }
          type="info"
          showIcon
          className="  font-avenir text-neutral500 text-sm w-full flex justify-between items-center "
        />
        <h6 className=" font-bold my-5">
          Kindly enter the BVN details of one of the directors of your
          organization
        </h6>
        <div className="flex flex-col md:grid grid-cols-2 gap-x-10">
          <CustomInput
            required
            label={
              <span className="text-neutral300 text-sm flex  items-center  ">
                First name of director{" "}
                <Tooltip title=" Kindly input the first name of a director in your organization">
                  <InformationCircleIcon className="w-4 h-4  ml-1" />
                </Tooltip>
              </span>
            }
            name="first_name"
          />
          <CustomInput
            required
            label={
              <span className="text-neutral300 text-sm flex  items-center  ">
                Last name of director{" "}
                <Tooltip title=" Kindly input the last name of a director in your organization">
                  <InformationCircleIcon className="w-4 h-4  ml-1" />
                </Tooltip>
              </span>
            }
            name="last_name"
          />
          {/* ---- Birthday ---- */}
          <Form.Item
            label={
              <span className="text-neutral300 text-sm  ">
                Date of birth of director
              </span>
            }
            name="birthday"
            rules={[{ required: true, message: "Please enter this field!" }]}
          >
            <DatePicker
              className="w-full"
              format={"DD/MM/YYYY"}
              // format={"YYYY-MM-DD"}
            />
          </Form.Item>

          {/* Phone number */}
          <Form.Item
            name="mobileNumber"
            label={
              <span className="text-neutral300 text-sm  ">
                Phone number of director
              </span>
            }
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
            ]}
            className="mb-2"
          >
            <Input
              placeholder="234 xxxxxxxx"
              type="number"
              maxLength={15}
            />
          </Form.Item>
          <div className="col-span-2">
            <Form.Item
              label={
                <span className="text-neutral300 text-sm flex items-center">
                  BVN (dial *565*0# if you don’t remember your BVN)
                  <Tooltip title=" Kindly input the BVN of a director in your organization">
                    <InformationCircleIcon className="w-4 h-4 ml-1" />
                  </Tooltip>
                </span>
              }
              name="bvn_number"
              rules={[
                { required: true, message: "Please enter this field" },
                { pattern: /^\d{11}$/, message: "BVN must be 11 digits" },
              ]}
            >
              <Input
                type="text"
                placeholder="11 digits"
                className="font-circular text-neutral400"
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </FormSectionWrapper>
  )
}
interface Values {
  first_name: string
  last_name: string
  bvn_number: string
  birthday: string
  mobileNumber: string
}
