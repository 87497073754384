import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useState } from "react"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { useAuthStore } from "stores"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { Spin } from "antd"
import { IEmployeeWalletRes } from "./transactions/interface"
import { availableFlags } from "pages/dashboard/wallets/crossBorderPayments/sampleData"
import CurrencyCard from "pages/dashboard/wallets/crossBorderPayments/component/CurrencyCard"
import CrossBorderTransTable from "pages/dashboard/wallets/crossBorderPayments/transactions/component/Table"
import RequestAccount from "pages/dashboard/wallets/crossBorderPayments/modals/RequestAccount"
import RequestCurCard from "pages/dashboard/wallets/crossBorderPayments/component/CustomCard"
import Rates from "pages/dashboard/wallets/crossBorderPayments/component/Rates"
import { rearrangeBalances } from "pages/dashboard/wallets/crossBorderPayments/Overview"
import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
import TransfersTable from "./transfers/component/TransfersTable"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { Button } from "components"
import { queryClient } from "index"
import WalletNotEnabled from "../WalletNotEnabled"
import NoWalletId from "./NoWalletId"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"

const CrossBorderEmployee = () => {
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const EmployeeWalletID = user?.userWalletId
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(7, "day").format("YYYY/MM/DD") || ""
  )
  const [hideDetails, setHideDetails] = useState(true)
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )

  const [openRequestModal, setOpenRequestModal] = useState(false)
  const [walletEnabled, setWalletEnabled] = useState(true)
  const {
    isLoading: iswalletLoading,
    data: walletData,
    refetch,
  } = useQuery<IWalletPade>({
    queryKey: [`wallets`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: EmployeeWalletID ? true : false,
    onSuccess: (data: any) => {
      if (data.status === "success") {
        mixPanelEvent(`empl-wallet-listing`)
        if (data?.data?.onboarded) {
          setWalletEnabled(true)
        } else {
          setWalletEnabled(false)
        }
      }
    },
  })

  const {
    isLoading: isTransactionsLoading,
    data: transactionsData,
    refetch: refetchTranst,
  } = useQuery<IEmployeeWalletRes>({
    queryKey: [`transactions`, startDate, endDate],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/transactions?limit=0&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: EmployeeWalletID ? true : false,
    onSuccess: (data: any) => {
      mixPanelEvent(`empl-wallet-transaction-listing`)
    },
  })

  const {
    isLoading: isTransfersLoading,
    data: transfersData,
    refetch: refetchTransfers,
  } = useQuery<any>({
    queryKey: [`transfers`, startDate, endDate],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/payment-transactions?limit=0&type=payment&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: EmployeeWalletID ? true : false,
    onSuccess: (data: any) => {
      mixPanelEvent(`empl-wallet-transfer-listing`)
    },
  })

  return (
    <DashboardWrapper>
      {EmployeeWalletID ? (
        <div className=" w-full">
          {iswalletLoading ? (
            <div className="h-screen w-full  flex justify-center items-center">
              <Spin />
            </div>
          ) : (
            <>
              {walletEnabled ? (
                <div className=" flex flex-col w-full ">
                  {/* HEADER */}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <h2 className=" text-padeBlack font-bold font-circular text-xl">
                        Wallet Balances{" "}
                      </h2>
                      <button
                        className="flex"
                        onClick={() => setHideDetails(!hideDetails)}
                      >
                        {hideDetails ? (
                          <EyeIcon className="text-black w-6 h-6 cursor-pointer" />
                        ) : (
                          <EyeSlashIcon className="text-black w-6 h-6 cursor-pointer" />
                        )}
                      </button>
                    </div>
                    <Button
                      color="primary"
                      onClick={() => {
                        window.location.reload()
                      }}
                    >
                      Refresh
                    </Button>
                  </div>
                  {/* BALANCES */}
                  <Spin spinning={iswalletLoading}>
                    <div className="mt-4 flex  justify-start  items-center max-w-full min-h-[156px] overflow-x-auto gap-4">
                      {walletData?.data?.balances &&
                        rearrangeBalances(walletData?.data?.balances)?.map(
                          (curr: any, index: any) => {
                            const flag = availableFlags.find(
                              (flag) => flag.code === curr.currency
                            )?.flag

                            return (
                              <CurrencyCard
                                key={index}
                                curr={curr}
                                flag={flag}
                                tag={
                                  curr?.currency === "USD"
                                    ? "Global Payout"
                                    : ""
                                }
                                handleClick={() => {
                                  // curr?.type === "default" &&
                                  curr?.currency === "NGN"
                                    ? navigate(
                                        user?.userStatusId === "2" ||
                                          user?.userStatusId === "3"
                                          ? "/employee/susp-exit/wallet/cross-border/local"
                                          : "/employee/dashboard/wallet/cross-border/local"
                                      )
                                    : navigate(
                                        user?.userStatusId === "2" ||
                                          user?.userStatusId === "3"
                                          ? `/employee/susp-exit/wallet/cross-border/view`
                                          : `/employee/dashboard/wallet/cross-border/view`,
                                        {
                                          state: {
                                            currencyCode: curr.currency,
                                          },
                                        }
                                      )
                                }}
                                hideDetails={hideDetails}
                              />
                            )
                          }
                        )}
                      <RequestCurCard
                      // onClick={ () => setOpenRequestModal(true) }
                      />
                    </div>
                  </Spin>
                  {/* RATES */}
                  <div className="mt-[31px] h-[159px]">
                    <Rates />
                  </div>
                  {/* TRANSACTION */}
                  <div className="mt-[25px]">
                    <CustomCard
                      title="Recent Transactions"
                      ctaBtn={
                        <button
                          className="text-primary500 flex text-sm items-center font-avenir"
                          onClick={() =>
                            navigate(
                              "/employee/dashboard/wallet/cross-border/transactions"
                            )
                          }
                        >
                          See All
                        </button>
                      }
                    >
                      <CrossBorderTransTable
                        transactionsData={transactionsData?.data?.docs?.slice(
                          0,
                          4
                        )}
                        loading={isTransactionsLoading}
                      />
                    </CustomCard>
                  </div>
                  {/* TRANSFERS */}
                  <div className="mt-[25px]">
                    <CustomCard
                      title="Transfers"
                      ctaBtn={
                        <button
                          className="text-primary500 flex text-sm items-center font-avenir"
                          onClick={() =>
                            navigate(
                              "/employee/dashboard/wallet/cross-border/transfers"
                            )
                          }
                        >
                          See All
                        </button>
                      }
                    >
                      <TransfersTable
                        transactionsData={transfersData?.data?.docs?.slice(
                          0,
                          4
                        )}
                        loading={isTransfersLoading}
                      />
                    </CustomCard>
                  </div>
                  <RequestAccount
                    open={openRequestModal}
                    onClose={() => setOpenRequestModal(false)}
                  />
                </div>
              ) : (
                <WalletNotEnabled />
              )}
            </>
          )}
        </div>
      ) : (
        <NoWalletId />
      )}
    </DashboardWrapper>
  )
}

export default CrossBorderEmployee
