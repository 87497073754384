import React, { useEffect, useState } from "react"
import {
  ArrowLeftIcon,
  ArrowRightOnRectangleIcon,
  BellIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  LockClosedIcon,
  UserCircleIcon,
  ArrowTopRightOnSquareIcon,
  BellAlertIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline"
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom"
import PadeLogoNoName from "assets/logos/pade-logo-noname.svg"

import { useAuthStore } from "stores"

import { useGet } from "utils/useFetch"
import { ArrowRightIcon } from "@heroicons/react/24/solid"
import { Badge, Dropdown, Spin } from "antd"
import ImageComponent from "components/custom/image"
import { P2 } from "components/typography/P2"
import { throttle } from "utils/throttle"
import { TextLink } from "components/links/TextLink"
import { createPortal } from "react-dom"
import { isPadeDomain } from "utils/utils"

const UserProfile: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isSidebarOpen, toggleSidebar }) => {
  const navigate = useNavigate()
  const { signOut, user, authToken, loginSwitch } = useAuthStore()
  const [toSwitch, toggleToSwitch] = useState(false)

  const handleSignout = () => {
    signOut()
  }

  const handleSwitch = async () => {
    toggleToSwitch(true)

    const userId = localStorage.getItem("bgr")

    const response = await loginSwitch(userId || "")
    if (response.status === "Success") {
      const authToken = response.access_token
      const encodedAuthToken = encodeURIComponent(authToken)
      const encodedUserId = encodeURIComponent(userId || "")

      const redirectUrl =
        "https://portal.paidhr.com/account/switch?token=" +
        encodedAuthToken +
        "&code=" +
        encodedUserId
      // window.open(redirectUrl, "_blank");
      window.location.href = redirectUrl
    } else {
      toggleToSwitch(false)
      navigate("/auth/login")
    }
  }

  const { data, isLoading } = useGet<any>({
    url: "/employee/notifications/select",
  })

  return (
    <div className="relative h-auto">
      {toSwitch ? (
        <>
          {createPortal(
            <div className=" flex  items-center justify-center py-10 bg-a11y/50 h-screen w-screen fixed inset-0 z-[9999999] ">
              <Spin size="large" />
            </div>,
            document.body
          )}
        </>
      ) : null}

      <div className="flex items-center gap-4 md:gap-6">
        <Dropdown
          trigger={["click"]}
          dropdownRender={(menu) => (
            <div className="bg-white rounded-lg drop-shadow-2xl  border py-4">
              <div className="px-4 font-semibold font-avenir ">
                Notifications
              </div>
              <hr className="my-2 text-neutral50" />
              {data?.data?.filter((it: any) => it?.is_read !== "True")?.length >
              0 ? (
                <div className="w-full px-4">
                  <hr className="my-2 text-neutral50" />
                  <Link
                    to="notifications"
                    className="w-full flex gap-3 items-center px-1  text-neutral500 font-avenir"
                  >
                    <ArrowRightIcon className="w-4 h-4" />
                    Read All
                  </Link>
                </div>
              ) : (
                <div className="w-full">
                  <div className="flex gap-2 items-center px-4 my-6">
                    <div className="bg-primary500 text-a11y p-2 rounded-[100%] flex justify-center align-middle">
                      <BellAlertIcon className="w-4 h-4" />
                    </div>

                    <div className="text-[#74788d] font-avenir">
                      Your unread notifications will appear here
                    </div>
                  </div>
                  <hr className="my-2 text-neutral50" />
                  <Link
                    to="notifications"
                    className="w-full flex gap-3 justify-center  text-neutral500 text-center px-4 font-avenir"
                  >
                    <ArrowRightIcon className="w-4 h-4" />
                    Read All
                  </Link>
                </div>
              )}
            </div>
          )}
        >
          <button className="cursor-pointer flex">
            <Badge
              showZero
              count={
                data?.data?.filter((it: any) => it?.is_read !== "True")
                  ?.length || null
              }
            >
              {/* <div className="rounded-full p-2.5 bg-primary500 bg-opacity-[18%]"> */}
              <BellIcon className="w-6 stroke-2 text-primary500" />
              {/* </div> */}
            </Badge>
          </button>
        </Dropdown>

        {/* ===================== */}
        {/* ====Avatar & Name==== */}
        <Dropdown
          trigger={["click"]}
          dropdownRender={(menu) => (
            <div>
              <div className="w-full  p-4 bg-white rounded-lg drop-shadow-2xl border">
                <div className="flex flex-col w-full gap-5">
                  <div className="flex items-center gap-3">
                    {/* <div className=" ">
                      <img
                        className=" rounded-full border-[4px] border-primary50 w-9 aspect-square"
                        src={
                          user?.userAvatarFilename ??
              ``
                        }
                        alt="User Profile"
                      />
                    </div> */}
                    <div className="flex flex-col flex-wrap w-full">
                      <P2 className="font-semibold font-avenir text-neutral500 whitespace-nowrap">
                        {user?.userFullName}
                      </P2>
                      <p className="text-neutral300 font-avenir flex-wrap  text-xs w-11/12  ">
                        {user?.userName}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-2">
                    {user?.isAdmin === "False" ? null : (
                      <Link
                        to="/dashboard/"
                        className="flex w-full gap-3 rounded-[4px] px-1 py-2 item text-neutral500 hover:bg-primary500 hover:text-a11y"
                      >
                        <UserCircleIcon className="w-4 stroke-2" />
                        <P2 className="font-avenir">Admin Profile</P2>
                      </Link>
                    )}
                    <Link
                      to="/employee/dashboard/personal"
                      className="flex w-full gap-3 rounded-[4px] px-1 py-2 item text-neutral500 hover:bg-primary500 hover:text-a11y"
                    >
                      <UserCircleIcon className="w-4 stroke-2" />
                      <P2 className="font-avenir">My Profile</P2>
                    </Link>

                    <Link
                      to="/employee/dashboard/profile"
                      className="flex w-full rounded-[4px] gap-3 px-1 py-2 item text-neutral500 hover:bg-primary500 hover:text-a11y"
                    >
                      <UserCircleIcon className="w-4 stroke-2" />
                      <P2 className=" font-avenir">Preferences</P2>
                    </Link>
                  </div>
                </div>
                <hr className="my-2 text-neutral50" />
                {/* {isPadeDomain() === true ? (
                  <button
                    onClick={handleSwitch}
                    className="w-full rounded-[4px] flex gap-3 items-center px-1 py-2 text-neutral500 hover:bg-primary500 hover:text-a11y"
                  >
                    <ArrowTopRightOnSquareIcon className="w-4 stroke-2" />
                    <P2 className=" font-avenir">Switch to old version</P2>
                  </button>
                ) : null} */}

                {user?.lastLogin ? (
                  <div className="flex w-full gap-3 px-1 py-2 item text-neutral500">
                    <LockClosedIcon className="w-4 stroke-2" />
                    <P2 className="text-sm  font-avenir">
                      Last Login: {user?.lastLogin}{" "}
                    </P2>
                  </div>
                ) : null}

                <button
                  onClick={handleSignout}
                  className="w-full rounded-[4px] flex gap-3 items-center px-1 py-2 text-neutral500 hover:bg-primary500 hover:text-a11y"
                >
                  <ArrowRightOnRectangleIcon className="w-4 stroke-2" />
                  <P2 className=" font-avenir">Log Out</P2>
                </button>
              </div>
            </div>
          )}
        >
          <div className="flex items-center gap-2 cursor-pointer">
            <div className="rounded-full border-[4px] border-primary50 w-9 h-9 md:w-[45px] md:h-[45px] flex justify-center items-center ">
              <ImageComponent
                className="object-cover rounded-full h-full w-full"
                src={user?.userAvatarFilename ?? ``}
                alt="User Profile"
              />
            </div>
            <P2 className="hidden md:block font-semibold font-avenir text-neutral500">
              {user?.userFullName}
            </P2>
            <ChevronUpIcon
              className={`hidden md:block ease-in-out rotate-180 transition-all w-4 stroke-[3px] text-neutral500`}
            />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

type SubPageType = {
  title?: string
  path: string
}

type PageType = {
  title: string
  path: string
  subPages?: SubPageType[]
}

export const TopNavigationBar: React.FC<{
  isSidebarOpen: boolean
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ isSidebarOpen, toggleSidebar }) => {
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const [pages] = useState<PageType[]>([
    {
      title: "Survey",
      path: "/survey",
    },
  ])

  const { pathname } = useLocation()
  const [currentPage, setCurrentPage] = useState<PageType>()
  const [currentSubPage, setCurrentSubPage] = useState<SubPageType>()
  const [isSubPage, toggleIsSubPage] = useState<boolean>(false)

  // find current page & subpage
  useEffect(() => {
    return throttle(() => {
      let page = pages.find((page) => page.path === pathname)
      setCurrentPage(page)

      const subPages = pages
        .map((page) => page.subPages!)
        .filter(Boolean)
        .reduce((a, e) => [...a, ...e], [])

      const subPage = subPages.find(
        (page) => matchRoutes([page], pathname) !== null
      )!

      if (page === undefined && subPage !== undefined) {
        setCurrentSubPage(subPage)
        toggleIsSubPage(true)

        page = pages.find((page) => {
          return (
            page.subPages !== undefined &&
            page.subPages.length > 0 &&
            page.subPages.find((page) => page.path === subPage.path)
          )
        })
        setCurrentPage(page)
      } else {
        setCurrentSubPage(undefined)
        toggleIsSubPage(false)
      }
    }, 200)
  }, [pages, pathname])

  return (
    <div className=" flex items-center justify-between w-full h-full px-4 md:px-6 lg:px-10 mx-auto ">
      {/* ========================= */}
      {/* ====Logo===== */}
      <div className="flex gap-4 md:hidden ">
        {isPadeDomain() ? (
          <img
            src={PadeLogoNoName}
            alt="PaidHR Logo"
            className=""
            width={30}
            height={30}
          />
        ) : // <img
        //   src={customizationSettings?.brand?.menuLogoUrl}
        //   alt=""
        //   className="w-[100px]"
        // />
        null}

        {/* ========================= */}
        {/* ====Menu Drawer Btn===== */}
        <button
          className=" w-[27px] "
          type="button"
          onClick={() => {
            toggleSidebar(!isSidebarOpen)
            document.body.classList.add("overflow-hidden")
          }}
        >
          <Bars3Icon className="w-6 h-6" />
        </button>
      </div>
      <div className="flex gap-4">
        {isSubPage ? (
          <button
            className="hidden md:flex items-center"
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className="w-5 h-5 text-neutral800" />
          </button>
        ) : null}
        <div className="hidden md:flex items-center gap-2">
          {currentPage !== undefined ? (
            isSubPage === true ? (
              currentSubPage?.title === undefined ||
              currentSubPage.title.length === 0 ? (
                <TextLink
                  to={currentPage.path}
                  title={currentPage.title}
                  className="text-base font-semibold no-underline text-neutral700 font-avenir"
                >
                  {currentPage.title}
                </TextLink>
              ) : (
                <>
                  <TextLink
                    to={currentPage.path}
                    title={currentPage.title}
                    className="text-base font-semibold no-underline text-neutral700 font-avenir"
                  >
                    {currentPage.title}
                  </TextLink>
                  <ChevronRightIcon className="w-4 stroke-[3] text-neutral900" />
                  <p className="text-base font-semibold  font-avenir text-neutral100">
                    {currentSubPage?.title!}
                  </p>
                </>
              )
            ) : (
              <p className="text-base font-semibold font-avenir text-neutral700">
                {currentPage?.title}
              </p>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <UserProfile
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
    </div>
  )
}
