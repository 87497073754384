import { useEffect, useState } from "react"

import LogoWhite from "assets/logos/paidhr-logo-white.svg"
import { Button, TextLink } from "../../components"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Carousel, Form, Input, Spin } from "antd"
import { useGet } from "utils/useFetch"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { CheckCircleIcon } from "@heroicons/react/24/solid"
import { useAppNotificationStore } from "stores"
import NewAuthWrapper from "pages/new-auth/NewAuthWrapper"
import { isPadeDomain } from "utils/utils"
import { useCustomizationSettingsStore } from "stores/customization-settings"

interface Values {
  password: string
}

const EmployeeSetPassword = () => {
  const [isPasswordSet, setIsPasswordSet] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { customizationSettings } = useCustomizationSettingsStore()

  const userId = searchParams.get("user_id")
  const code = searchParams.get("code")

  const { data: userData, isLoading: isUserDataLoading } = useGet<IUser>({
    url: `/account/view/setpassword?user_id=${encodeURIComponent(
      userId || ""
    )}&code=${encodeURIComponent(code || "")}`,
  })

  useEffect(() => {
    if (userData && userData.isPasswordSet) {
      navigate("/auth/login")
    }
  }, [userData])

  return (
    <div>
      {isPadeDomain() === true ? (
        <NewAuthWrapper
          heading={isPasswordSet ? "You password is set" : "Set your password"}
          subHeading={
            "Remember to keep your password secure and never share it with anyone. Consider using a password manager to keep track of all your passwords safely."
          }
          showGetHelp
          showCompanies={false}
        >
          {isUserDataLoading ? (
            <div className="grid place-content-center grow min-h-full">
              <Spin size="large" />
            </div>
          ) : isPasswordSet ? (
            <div className=" flex flex-col  items-center justify-center min-h-full">
              <CheckCircleIcon className="text-[#38CB89] w-[87px] aspect-square" />
              <h2 className="font-circular font-bold text-neutral700 text-[25px] leading-[32px] mt-4 mb-2">
                Your password is set
              </h2>
              <p className="font-avenir font-medium text-[16px] leading-[20.8px] text-neutral300 mb-4 ">
                Now sign in to continue setting up your account so that you get
                can paid.
              </p>
              <TextLink
                className="text-primary500 text-[16px] leading-[21.86px] no-underline"
                to={"/auth/login"}
                title="Sign in"
              >
                Sign in
              </TextLink>
            </div>
          ) : (
            <div className="">
              <h2 className="font-circular font-bold text-3xl mb-4">
                Welcome to {userData?.organisationFullName}
              </h2>
              <p className="font-avenir font-semibold text-base text-neutral400 mb-8 ">
                Get started by choosing a secure password at least 8 characters
                long with both upper and lower case letters and number
              </p>
              <h3 className="font-circular font-bold text-[18px] leading-[120.5%] text-[#15294B] mb-2">
                Email
              </h3>
              <p className="font-avenir font-medium text-[16px] leading-[20.8px] text-[#5D6B82] mb-[28px]">
                {userData?.email}
              </p>
              <div className="flex justify-end mb-4">
                <button
                  className="text-primary500 font-extrabold text-[16px] leading-[21.86px]"
                  onClick={() => navigate("/auth/forgot-password")}
                >
                  Request another token
                </button>
              </div>

              <SetPasswordForm
                userData={userData}
                setIsPasswordSet={setIsPasswordSet}
              />
            </div>
          )}
        </NewAuthWrapper>
      ) : (
        <div className="flex flex-col md:flex-row items-stretch">
          <div className="hidden md:block bg-[#004AF5] w-full md:max-w-[600px] 2xl:max-w-[650px] h-[100svh] pt-[40.49px] pb-[60px]">
            <div className="ml-[38px] ">
              {customizationSettings?.brand?.logoUrl ? (
                <img
                  className=""
                  src={customizationSettings?.brand?.logoUrl}
                  alt=""
                  width={120}
                />
              ) : null}
            </div>
            <LHS />
          </div>

          {/* form */}
          {isUserDataLoading ? (
            <div className="grid place-content-center grow ">
              <Spin size="large" />
            </div>
          ) : isPasswordSet ? (
            <div className="py-6 md:py-10 grow px-4 md:px-[50px]">
              <div className="flex justify-end items-center text-[#62738D] font-avenir">
                Having troubles?{" "}
                <button
                  className="text-primary500 text-[16px] leading-[21.86px] ml-1"
                  title="Get help"
                >
                  Get help
                </button>
                <ArrowRightIcon className="w-4 h-4 ml-2" />
              </div>

              <div className="mt-10 md:mt-[100px] flex flex-col  items-center ">
                <CheckCircleIcon className="text-[#38CB89] w-[87px] aspect-square" />
                <h2 className="font-circular font-bold text-[#243757] text-[25px] leading-[32px] mt-4 mb-2">
                  Your password is set
                </h2>
                <p className="font-avenir font-medium text-[16px] leading-[20.8px] text-[#5D6B82] mb-4 text-center">
                  Now sign in to continue setting up your account so that you
                  get can paid.
                </p>
                <TextLink
                  className="text-primary500 text-[16px] leading-[21.86px] no-underline "
                  to={"/auth/login"}
                  title="Sign in"
                >
                  Sign in
                </TextLink>
              </div>
            </div>
          ) : (
            <div className="grow w-full md:max-w-[554px] flex items-center xl:pt-10 md:pl-[40px] xl:pl-[100px]">
              <div className="grow px-4  mt-6 md:mt-0">
                <h2 className="font-circular font-bold text-[#243757] text-[25px] leading-[32px] mb-[19px]">
                  Set your password
                </h2>
                <h3 className="font-circular font-bold text-[18px] leading-[120.5%] text-[#15294B] mb-2">
                  Welcome to {userData?.organisationFullName}
                </h3>
                <p className="font-avenir font-medium text-[16px] leading-[20.8px] text-[#5D6B82] mb-[43px]">
                  Get started by choosing a secure password at least 8
                  characters long with both upper and lower case letters and
                  number
                </p>
                <h3 className="font-circular font-bold text-[18px] leading-[120.5%] text-[#15294B] mb-2">
                  Email
                </h3>
                <p className="font-avenir font-medium text-[16px] leading-[20.8px] text-[#5D6B82] mb-[28px]">
                  {userData?.email}
                </p>
                <div className="flex justify-end mb-4">
                  <button
                    className="text-primary500 font-extrabold text-[16px] leading-[21.86px]"
                    onClick={() => navigate("/auth/forgot-password")}
                  >
                    Request another token
                  </button>
                </div>

                <div>
                  <SetPasswordForm
                    userData={userData}
                    setIsPasswordSet={setIsPasswordSet}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default EmployeeSetPassword

const SetPasswordForm = ({
  userData,
  setIsPasswordSet,
}: {
  userData: IUser | undefined
  setIsPasswordSet: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { toast } = useAppNotificationStore()

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(`/account/setpassword`, body)
      return res.data
    },
    onSuccess: (data) => {
      setIsPasswordSet(true)
    },
    onError() {
      toast.error("Something went wrong, please try again")
    },
  })

  const validatePassword = (_: unknown, value: string) => {
    if (!value) {
      return Promise.reject("Password is required.")
    }
    if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(value)) {
      return Promise.reject(
        "Please choose a secure password at least 8 characters long with both upper and lower case letters and numbers."
      )
    }
    return Promise.resolve()
  }

  const onFinish = (values: Values) => {
    submit({
      userId: userData?.userId,
      email: userData?.email,
      code: userData?.code,
      password: values.password,
    })
  }
  return (
    <Form
      name="set password"
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        name="password"
        hasFeedback
        rules={[
          {
            required: true,
            message: `Please enter password.`,
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
            message:
              "Password must contain at least 8 characters, one uppercase, one lowercase and one number",
          },
        ]}
        label={
          <span className="font-avenir font-medium text-[14px] text-neutral400">
            Password
          </span>
        }
      >
        <Input.Password className="h-10" />
      </Form.Item>

      <Form.Item
        name="cpassword"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              )
            },
          }),
        ]}
        label={
          <span className="font-avenir font-medium text-[14px] text-neutral400">
            Re-enter Password
          </span>
        }
      >
        <Input.Password className="h-10" />
      </Form.Item>
      <div className="pt-4">
        <Button
          className="w-full "
          type="submit"
          submitting={isSubmitting}
          disabled={isSubmitting}
          color="primary"
        >
          Set password
        </Button>
      </div>
    </Form>
  )
}

const LHS = () => {
  const { customizationSettings } = useCustomizationSettingsStore()
  return (
    <Carousel
      autoplay
      className="h-full"
      dots={{
        className:
          "!bottom-[-30px] [&_button]:!rounded-full [&_button]:!w-[12px] [&_button]:!h-[12px]",
      }}
    >
      {(isPadeDomain() === true
        ? loop_data
        : customizationSettings?.pages?.login
      )?.map((item, idx) => (
        <div
          className="flex flex-col justify-center items-center px-6 2xl:space-y-24 h-full pt-10"
          key={idx}
        >
          <div className="md:min-h-[300px] flex justify-center">
            <img
              className="md:h-[320px]"
              src={
                "https://padefiles.blob.core.windows.net/images/2e2b9249-f40a-47b3-be29-9cb870e7dc43.png"
              }
              alt="logo-white"
              width={426}
              height={288}
            />
          </div>

          <div className="space-y-6 min-h-[140px] grow flex flex-col justify-end ">
            <div className="flex flex-col justify-center items-center gap-4 mx-auto">
              <h4 className=" font-circular font-bold text-[20px] leading-[25px] text-white text-center">
                {item.title}
              </h4>

              <p className="max-w-[500px] mx-auto font-avenir font-light text-[15px] leading-[20px] text-white text-center">
                {item.description}
              </p>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  )
}

const loop_data = [
  {
    img: "https://padefiles.blob.core.windows.net/images/2e2b9249-f40a-47b3-be29-9cb870e7dc43.png",
    title: "HR at your fingertips",
    description:
      "PaidHR gives you the power to manage your workforce from anywhere, at any time. Keep track of employee information, track performance, and stay compliant with just a few clicks.",
  },
  {
    img: "https://padefiles.blob.core.windows.net/images/5ad9721d-7b31-47b6-8aed-e0fcc2f95b88.png",
    title: "Empower your employees, Elevate your business.",
    description:
      "PaidHR gives you the tools to manage your workforce effectively and efficiently, allowing you to focus on growing your business",
  },
]

interface IUser {
  userId: string
  code: string
  organisationFullName: string
  email: string
  isPasswordSet: boolean
}
