import { FormInstance } from "antd"
import AntDesignSelect from "components/antd/AntDesignSelect"
import React, { Dispatch, FC, useMemo } from "react"
import { useGet } from "utils/useFetch"

type Props = {
  filteredEmployees?: any[]
  onUpdate: Dispatch<string[]>
  form: FormInstance<any>
  employees: string[]
  useCustomData: boolean
  fieldName: string
  label?: string
  isDisabled?: boolean
  enableAll?: boolean
  maxTagCount?: number
  mode?: "multiple" | "tags" | undefined
}

export const AppPeoplePicker: FC<Props> = ({
  filteredEmployees = [],
  onUpdate,
  form,
  employees,
  useCustomData,
  fieldName = "employees",
  label = "Employees",
  isDisabled = false,
  maxTagCount = 5,
  mode = "multiple",
}) => {
  const { isLoading: isGetPeopleLoading, data: peopleData = [] } = useGet<
    any[]
  >({
    url: `/admin/people/getforselection?active=true`,
  })

  filteredEmployees = useMemo(
    () => (useCustomData ? filteredEmployees : peopleData),
    [useCustomData, filteredEmployees, peopleData]
  )

  const uniqueItems = useMemo(() => {
    return filteredEmployees?.filter(
      (item: any, index: number) =>
        filteredEmployees.findIndex(
          (i: any) => i.full_name === item.full_name
        ) === index
    )
  }, [filteredEmployees])

  return (
    <div className=" box-border w-full">
      <div className="flex justify-between gap-2 my-2">
        <div>
          {label && (
            <span className="font-circular text-neutral300 text-sm ">
              {label}
            </span>
          )}
        </div>
        {!isDisabled && (
          <div className="flex gap-4 justify-end">
            <span
              className="cursor-pointer text-primary500"
              onClick={() => {
                onUpdate(
                  filteredEmployees?.map((person: any) => person.full_name)
                )
                form.setFieldValue(
                  fieldName,
                  filteredEmployees?.map((person: any) => person.full_name)
                )
              }}
            >
              Add all
            </span>
            <span
              className="cursor-pointer text-primary500"
              onClick={() => {
                onUpdate([])
                form.setFieldValue(fieldName, [])
                form.resetFields([fieldName])
              }}
            >
              Remove all
            </span>
          </div>
        )}
      </div>

      <AntDesignSelect
        label=""
        name={fieldName}
        className="!w-full"
        options={
          uniqueItems &&
          uniqueItems?.map((option: any, index) => ({
            label: option.full_name,
            value: option.full_name,
            key: `${index}-${option.person_id}`,
          }))
        }
        virtual={false}
        style={{
          width: "100%",
        }}
        placeholder=""
        // isLoading={isGetPeopleLoading}
        maxTagCount={maxTagCount}
        mode={mode}
        key={fieldName}
        optionFilterProp="children"
        value={employees?.length ? Array.from(new Set(employees)) : []}
        onSelect={(value, option) => {
          const items = employees?.length ? employees : []
          option && onUpdate([...items, option.value])
          option && form.setFieldValue(fieldName, [...items, option.value])
        }}
        onDeSelect={(value, option) => {
          const items = employees?.length ? employees : []
          onUpdate(items.filter((item) => item !== option.value))
          form.setFieldValue(
            fieldName,
            items.filter((item) => item !== option.value)
          )
        }}
      />
    </div>
  )
}
