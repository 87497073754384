import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

/**
 * An optimized button.
 * Pass the `title` props, else `⚠` is returned
 */
export const Button: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    submitting?: boolean
    disabled?: boolean
    // size?: "sm" | "md" | "lg"
    color?:
      | "primary"
      | "secondary"
      | "success"
      | "danger"
      | "danger-outline"
      | "neutral"
      | "neutral-outline"
      | "destructive"
      | "light-blue"
    outline?: Boolean
  }
> = ({ disabled, title, submitting, color, outline, className, ...props }) => {
  let dynamicClasses = ""

  switch (color) {
    case "primary":
      dynamicClasses =
        "bg-primary500 hover:bg-primary700 text-a11y focus:ring-primary500 active:bg-primary700 focus:bg-primary700"
      break
    case "secondary":
      dynamicClasses =
        "bg-neutral20 border border-neutral30 hover:bg-neutral30 active:bg-neutral30 focus:bg-neutral30 text-neutral300 focus:ring-neutral100"
      break
    case "success":
      dynamicClasses =
        "bg-success500 hover:bg-success700 active:bg-success800 focus:bg-success800 text-white"
      break
    case "danger":
      dynamicClasses =
        "bg-danger500 hover:bg-danger700 active:bg-danger800 focus:bg-danger800 text-white"
      break
    case "danger-outline":
      dynamicClasses =
        "box-border border-2 border-danger500 bg-white hover:bg-danger100 active:bg-danger100 focus:bg-danger100 active:border-danger800 focus:border-danger800 text-danger500"
      break
    case "neutral":
      dynamicClasses =
        "bg-neutral20 border border-neutral30 hover:bg-neutral30 active:bg-neutral30 focus:bg-neutral30 text-neutral300 focus:ring-neutral100"
      break
    case "neutral-outline":
      dynamicClasses =
        "box-border border-2 border-neutral30 bg-transparent hover:bg-neutral30 hover:text-neutral300 text-neutral10"
      break
    case "destructive":
      dynamicClasses =
        "bg-[#fee7ec] border border-[#ffdde4] hover:bg-[#ffdde4] active:bg-[#ffdde4] focus:bg-[#ffdde4] text-[#EB3949] focus:ring-[#EB3949]"
      break
    case "light-blue":
      dynamicClasses =
        "bg-[#CCDBFD] text-[#5D6B82] focus:ring-[#CCDBFD] active:bg-[#CCDBFD] focus:bg-[#CCDBFD]"
      break
    default:
      break
  }

  return (
    <button
      disabled={disabled || submitting}
      // aria-label={title}
      {...props}
      className={`rounded-5px disabled:opacity-50 focus:outline-none focus:shadow-none disabled:cursor-not-allowed py-2 px-4 text-sm min-w-[106px] font-avenir focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 font-semibold transition-all duration-100 ease-linear ${dynamicClasses}
      ${
        outline!
          ? "bg-transparent border border-neutral300 hover:border-[#9babc720] hover:bg-[#9babc720] active:bg-[#9babc720] focus:bg-[#9babc720] "
          : ""
      }

      ${className}
      `}
    >
      <div className={`w-full h-full flex gap-2 justify-center items-center`}>
        {submitting && (
          <LoadingOutlined
            spin
            className="w-4 h-4 "
          />
        )}
        <>{props.children}</>
      </div>
    </button>
  )
}
