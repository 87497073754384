import React, { useState } from "react"
import { Button } from "components"

import { DatePicker, Dropdown, Input, Spin, Table } from "antd"
import { formatCurrencyNoSymbol } from "utils/currency"
import {
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { newBaseUrl } from "utils/newbaseurl"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import CustomInfoCard from "components/new-stuff/ui/CustomInfoCard"
import { CSVLink } from "react-csv"
import dayjs from "dayjs"
import { dateData } from "../wallets/Statements"
import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance, ewaAxiosInstance } from "utils/request"
import EWANotEnabled from "./EWANotEnabled"
import { useAppNotificationStore } from "stores"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"

const Withdrawals: React.FC<{}> = () => {
  const { toast } = useAppNotificationStore()
  const [search, setSearch] = useState("")
  const [activeId, setActiveId] = useState("")
  const [approveModal, setApproveModal] = useState(false)
  const [declineModal, setDeclineModal] = useState(false)

  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(1, "month").format("YYYY/MM/DD") || ""
  )
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )
  const [isCustom, setIsCustom] = useState(false)
  const [activePeriod, setActivePeriod] = useState<dateData>({
    id: "1",
    period: "One Week",
    startDate: "",
    endDate: "",
  })

  const {
    isLoading: isWithdrawalsLoading,
    data: withdrawalsData,
    refetch,
  } = useQuery<any>({
    queryKey: [
      `${newBaseUrl}/v1/employer/withdrawals?populate=employee&limit=0&startDate=${
        startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
      }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
    ],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${newBaseUrl}/v1/employer/withdrawals?populate=employee&limit=0&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`
      )
      return res.data
    },

    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
  })

  const { mutate: approveWithdrawal, isLoading: loading } = useMutation({
    mutationFn: async (data: { status: "APPROVED" | "DECLINED" }) => {
      const res = await ewaAxiosInstance.post(
        `/v1/employer/withdrawals/${activeId}/approval-status`,
        data
      )
      return res.data
    },
    onSuccess() {
      refetch()
    },
    onError(error: any) {
      toast.error(error?.response?.data?.message)
    },
  })

  const TableData =
    withdrawalsData?.data?.docs
      ?.filter((emp: any) => {
        const name = `${emp?.employee?.firstName}`
        return name?.toLowerCase().includes(search?.toLowerCase())
      })
      ?.map((transaction: any) => {
        return {
          key: transaction?._id,
          date: {
            date: formatDateReadable(String(transaction?.createdAt)),
            time: formatTimeReadble(String(transaction?.createdAt)),
          },
          type: transaction.channel,
          amount: `₦ ${formatCurrencyNoSymbol(
            Number(transaction?.amountToPayBack)
          )}`,
          employee: {
            name: `${transaction?.employee?.firstName} ${transaction?.employee?.lastName}`,
          },
          idNumber: transaction?.employee?.employeeId,
          charge: `₦ ${formatCurrencyNoSymbol(Number(transaction?.fee))}`,
          reference: transaction.reference,
          // balance: `₦ ${formatCurrencyNoSymbol(
          //   Number(transaction.currentBalance)
          // )}`,
          status: {
            id: transaction.status,
            title: transaction.status,
          },
          action: {
            key: transaction?._id,
            id: transaction?._id,
            status: transaction.status,
          },
        }
      }) || []
  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Employee",
        dataIndex: "employee",
        render: (employee: { name: string }) => (
          <div className="flex items-center gap-2">
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]`}
            >
              {employee.name}
            </span>
          </div>
        ),
      },
      {
        title: "ID Number",
        dataIndex: "idNumber",
        render: (idNumber: string) => (
          <div className="flex items-center gap-2">
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]`}
            >
              {idNumber}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {amount}{" "}
          </span>
        ),
      },

      {
        title: "Status",
        dataIndex: "status",
        render: (status: { id: string; title: any; method: string }) => (
          <span
            className={`text-sm font-medium py-1 block text-center w-[80px] lowercase rounded-[4px]
                    ${
                      status?.title == "APPROVED"
                        ? "bg-success100 text-success800"
                        : ""
                    }
                    ${
                      status?.title == "PAID"
                        ? "bg-success100 text-success800"
                        : ""
                    }

                    ${
                      status?.title == "PROCESSING"
                        ? "bg-[#FEE3B1] text-[#E69702]"
                        : ""
                    }
                    ${
                      status?.title == "PENDING"
                        ? "bg-[#FEE3B1] text-[#E69702]"
                        : ""
                    }
                    ${status?.title == "DECLINED" ? "bg-neutral40 " : ""}
                    ${status?.title == "FAILED" ? "bg-neutral40 " : ""}
                    ${status?.title == "CUTOFF" ? "bg-neutral40 " : ""}

                    `}
          >
            {status?.title}
          </span>
        ),
      },
      {
        title: "",
        dataIndex: "action",
        render: (action: { key: string; id: string; status: string }) => (
          <Dropdown
            menu={{
              items: actionItems(action),
            }}
          >
            <button>
              <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
            </button>
          </Dropdown>
        ),
      },
    ] || []
  const actionItems = (action: { key: string; id: string; status: string }) => {
    const { key, id, status } = action
    let arrayOptions: any = []
    {
      id &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "1",
            label: <span>View</span>,
          },
        ])
    }
    {
      status == "PENDING" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "2",
            label: (
              <button
                className="w-full"
                onClick={() => {
                  setActiveId(id)
                  setApproveModal(true)
                }}
              >
                Approve
              </button>
            ),
          },
        ])
    }
    {
      status == "PENDING" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "3",
            label: (
              <button
                className="w-full"
                onClick={() => {
                  setActiveId(id)
                  setDeclineModal(true)
                }}
              >
                Decline
              </button>
            ),
          },
        ])
    }

    return arrayOptions
  }

  const headers = [
    { label: "Date", key: "createdAt" },
    { label: "First name", key: "employee.firstName" },
    { label: "Last name", key: "employee.lastName" },
    { label: "Email", key: "employee.email" },
    { label: "ID Number", key: "employee.employeeId" },
    { label: "Amount", key: "amountToPayBack" },
    { label: "Bank", key: "employee.bankName" },
    { label: "Status", key: "status" },
    { label: "Channel", key: "channel" },
  ]

  const csvProps = {
    filename: "withdrawal.csv",
    headers: headers,
    data: withdrawalsData?.data?.docs || [],
  }

  const periods: dateData[] = [
    {
      id: "1",
      period: "One Week",
      startDate: "",
      endDate: "",
    },
    {
      id: "2",
      period: "One Month",
      startDate: "",
      endDate: "",
    },

    {
      id: "3",
      period: "Custom",
      startDate: "",
      endDate: "",
    },
  ]
  return (
    <DashboardWrapper>
      {isWithdrawalsLoading ? (
        <div className="flex justify-center w-full h-screen items-center">
          <Spin />
        </div>
      ) : withdrawalsData ? (
        <CustomInfoCard
          title=" Withdrawal history"
          actions={
            withdrawalsData?.data?.docs.length ? (
              <CSVLink {...csvProps}>
                <Button
                  color="primary"
                  title="Download"
                >
                  Download
                </Button>
              </CSVLink>
            ) : (
              <></>
            )
          }
        >
          <div
            className="flex font-avenir flex-col  gap-6 p-6"
            id="ewa-withdrawals-list"
          >
            <div className="flex items-center justify-between">
              <div className="w-[200px] h-9">
                <Input
                  placeholder="Search"
                  prefix={
                    <MagnifyingGlassIcon className="w-4 h-4 text-neutral-300" />
                  }
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="font-avenir "
                />
              </div>
              <div className="flex gap-2 justify-end items-center mb-4">
                <div className="bg-[#F3F5FB] rounded-sm cursor-pointer flex ">
                  {periods?.map((it: dateData) => (
                    <div
                      onClick={() => {
                        if (it?.id === "1") {
                          setStartDate(
                            dayjs(new Date())
                              .subtract(7, "day")
                              .format("YYYY/MM/DD") || ""
                          )
                          setEndDate(
                            dayjs(new Date())?.format("YYYY/MM/DD") || ""
                          )
                        } else if (it?.id === "2") {
                          setStartDate(
                            dayjs(new Date())
                              .subtract(30, "day")
                              .format("YYYY/MM/DD") || ""
                          )
                          setEndDate(
                            dayjs(new Date())?.format("YYYY/MM/DD") || ""
                          )
                        } else {
                          setIsCustom(true)
                          setActivePeriod({
                            id: "3",
                            period: "Custom",
                            startDate: "",
                            endDate: "",
                          })
                          return
                        }
                        setActivePeriod(it)
                        setIsCustom(false)
                      }}
                      key={it?.id}
                      className={`${
                        it.id === activePeriod?.id && "bg-primary500 text-white"
                      } whitespace-nowrap text-[#42526D] h-full w-full p-2 rounded-md`}
                    >
                      {it?.period}
                    </div>
                  ))}
                </div>
                {isCustom && (
                  <div className="flex gap-2">
                    <DatePicker
                      placeholder="Start date"
                      onChange={(value) => {
                        setStartDate(value?.format("YYYY/MM/DD") || "")
                        setEndDate("")
                      }}
                      disabledDate={(date) => {
                        return date.isAfter(dayjs())
                      }}
                    />
                    <DatePicker
                      placeholder="End date"
                      disabled={!startDate}
                      onChange={(value) => {
                        setEndDate(value?.format("YYYY/MM/DD") || "")
                      }}
                      disabledDate={(date) => {
                        const testDate = dayjs(startDate)
                        const before = date.isBefore(testDate)
                        const after = date.isAfter(testDate.add(1, "M"))
                        const afterToday = date.isAfter(dayjs())

                        return before || after || afterToday
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <Table
              columns={TableColumns}
              dataSource={TableData}
              loading={isWithdrawalsLoading}
              className="text-[#42526D]"
              pagination={{
                defaultPageSize: 20,
              }}
              scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
            />
          </div>
        </CustomInfoCard>
      ) : (
        <EWANotEnabled />
      )}
      {/* APPROVe */}
      <CustomModal
        open={approveModal}
        title="Approve Withdrawal"
        footer={[
          <div className="w-full justify-end flex gap-4 mt-8">
            <Button
              title={`Cancel`}
              color="neutral"
              className="font-circular"
              key="1"
              onClick={() => {
                setApproveModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                approveWithdrawal({
                  status: "APPROVED",
                })
              }}
              key="2"
              submitting={loading}
              type="submit"
            >
              Confirm
            </Button>
          </div>,
        ]}
      >
        <div>
          <div className="text-padeBlack">
            Are you sure you want to approve this withdrawal? Click confirm to
            continue action.
          </div>
        </div>
      </CustomModal>
      {/* Decline */}
      <CustomModal
        open={declineModal}
        title="Decline Withdrawal"
        footer={[
          <div className="w-full justify-end flex gap-4 mt-8">
            <Button
              title={`Cancel`}
              color="neutral"
              className="font-circular"
              key="1"
              onClick={() => {
                setDeclineModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                approveWithdrawal({
                  status: "DECLINED",
                })
              }}
              key="2"
              submitting={loading}
              type="submit"
            >
              Confirm
            </Button>
          </div>,
        ]}
      >
        <div>
          <div className="text-padeBlack">
            Are you sure you want to decline this withdrawal? Click confirm to
            continue action.
          </div>
        </div>
      </CustomModal>
    </DashboardWrapper>
  )
}
export default Withdrawals
