import { Alert, Upload } from "antd"
import { CustomSelect } from "components/new-stuff/form"
import { Button, H6, P3 } from "components"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { InboxOutlined } from "@ant-design/icons"
import { axiosInstance } from "utils/request"
import { newBaseUrl, walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useMutation } from "@tanstack/react-query"
import { useAppNotificationStore, useAuthStore } from "stores"
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface"
import type { UploadChangeParam } from "antd/es/upload"
import { getBase64 } from "pages/dashboard/people/employee/Profile"

const ProofOfResidence = ({
  setCurrentPage,
  formBody,
  setFormBody,
  isActive,
  form,
  Form,
  status,
  remarks,
}: {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  formBody: any
  setFormBody: any
  isActive: boolean
  form: any
  Form: any
  remarks: any
  status?: "not setup" | "completed" | "pending" | string
}) => {
  const { Dragger } = Upload
  const { user } = useAuthStore()
  const { toast } = useAppNotificationStore()
  const [isLoading, setIsLoading] = useState(false)
  const EmployeeWalletID = user?.userWalletId
  const navigate = useNavigate()
  const [file, setFile] = useState<any>(null)
  const [imgUrl, setImgUrl] = useState("")

  const { isLoading: isLoadingSubmit, mutate: submit } = useMutation({
    mutationFn: async (body: IBody) => {
      const res: any = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/kyc/proof-of-residence`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res
    },
    onSuccess: (data: any) => {
      if (data?.data?.success) {
        data?.data?.message && toast.success(data?.data?.message)
        toast.success("File uploaded successfully!")
        navigate("/employee/dashboard/wallet/cross-border/overview")
      } else {
        data?.data?.message && toast.error(data?.data?.message)
        navigate("/employee/dashboard/wallet/cross-border/overview")
      }
    },
    onError: (data: any) => {
      {
        data?.response?.data?.message &&
          toast.error(data?.response?.data?.message)
      }
    },
  })
  const { isLoading: isAddingFile, mutate: submitFile } = useMutation({
    mutationFn: async (formData: FormData) => {
      const res = await axiosInstance.post(`${newBaseUrl}/v1/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      return res?.data
    },
    onSuccess: (data) => {
      submit({
        identificationType: documentType,
        identificationFileUrl: data?.data,
        walletId: EmployeeWalletID ? EmployeeWalletID : "",
      })
    },
  })

  const handleUpload = () => {
    if (file) {
      const formData = new FormData()
      formData.append("file", file)
      submitFile(formData)
    }
  }

  const beforeUpload = (file: RcFile) => {
    const isLimit = file.size / 1024 / 1024 < 1
    if (!isLimit) {
      toast.error("File must be smaller than 1MB!")
    }
    return isLimit
  }

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setIsLoading(true)
      return
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setIsLoading(false)
        setFile(info.file.originFileObj)
        setImgUrl(url)
      })
    }
  }
  const customRequest = async (options: any) => {
    setTimeout(() => {
      options.onSuccess("ok")
    }, 0)
  }

  const documentType = Form.useWatch("document_type", form)
  const onFinish = (values: any) => {
    setFormBody({
      ...formBody,
    })

    // navigate("/employee/dashboard/wallet/create-pin")
  }

  const proofType = [
    { value: "utilityBill", title: "Utility Bill" },
    { value: "driversLicense", title: "Drivers License" },
    { value: "tenancyAgreement", title: "Tenancy Agreement" },
    { value: "votersCard", title: "Voters Card" },
  ]
  return (
    <FormSectionWrapper
      isActive={isActive}
      name="Tier 3 KYC ( Proof of residence)"
      title="Tier 3 KYC ( Proof of residence)"
      subtitle="Increase your daily withdrawal limit to N2,000,000.00"
      form={form}
      layout="vertical"
      onFinish={onFinish}
      showDropdown
      onDropDown={() => {
        isActive ? setCurrentPage(0) : setCurrentPage(3)
      }}
      status={status}
      footerBtns={
        <div className="flex justify-end items-center gap-4 ">
          <Button
            color="secondary"
            type="button"
            onClick={() => setCurrentPage(2)}
          >
            Previous
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={isAddingFile}
            submitting={isAddingFile}
            onClick={(e) => {
              e.preventDefault()
              handleUpload()
            }}
          >
            Submit
          </Button>
        </div>
      }
    >
      <div className="space-y-6 pb-4">
        <div className="px-4 md:px-6 lg:px-8 xl:px-[60px] lg:gap-x-10 items-start">
          {remarks && (
            <Alert
              message={remarks}
              type="error"
              showIcon
              className="  font-avenir text-[#F41454] text-sm  flex justify-between items-center  bg-[#FEE7EC]"
            />
          )}
          <div className="border border-[#6c7a9326] mt-[10px] rounded-5px ]">
            <div className="px-[29px] py-[21px] border-b-2 border-[#6c7a9326] ">
              <h6 className=" font-bold">
                Please upload the required documentation containing your address
              </h6>
            </div>
            <div className="w-full px-[29px] py-6">
              <CustomSelect
                label="Document type"
                name="document_type"
                onChange={(selected) => {}}
                options={proofType?.map((state) => ({
                  label: state.title,
                  value: state.value,
                }))}
                placeholder="Please select"
                disabled={status == "Awaiting confirmation" ? true : false}
              />
              <Alert
                message={"What do we need?"}
                description={
                  "Your proof of address must clearly indicate your name and address"
                }
                type="info"
                showIcon
                className="font-avenir text-neutral500 text-sm w-full flex justify-between items-center "
              />
              <div className="mt-[30px]">
                <span className="text-padeBlack font-circular text-sm">
                  Upload proof{" "}
                </span>
                <Dragger
                  name="upload-document"
                  className=" font-avenir"
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  customRequest={customRequest}
                  multiple={false}
                  maxCount={1}
                  onDrop={(e) => {
                    e.preventDefault()
                  }}
                  disabled={status == "Awaiting confirmation" ? true : false}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <H6 className="text-dark10 font-bold  text-sm leading-[21px]">
                    Drop your files here or
                  </H6>

                  <P3 className="text-sm pb-5 text-dark30 text-[12px] leading-[18px]">
                    Only pdf, doc, docx, png, jpg, jpeg, with max size of 1MB
                  </P3>
                  <Button
                    color="primary"
                    title="Choose file"
                    className=" mt-4 "
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    Choose file
                  </Button>
                </Dragger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormSectionWrapper>
  )
}

export default ProofOfResidence
type IdentificationType =
  | "utilityBill"
  | "driversLicense"
  | "tenancyAgreement"
  | "votersCard"

interface IBody {
  identificationType: IdentificationType
  identificationFileUrl: string
  walletId: string
}
