import { Button, RadioInput } from "components"
import { useState } from "react"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { axiosInstance } from "utils/request"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useAppNotificationStore, useAuthStore } from "stores"
import { Drawer, Form, Radio, Space, Spin, Tag } from "antd"
import { formatCurrencyNoSymbol } from "utils/currency"
import SegmentWrapper from "components/custom/SegmentWrapper"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { IWalletPade } from "pages/dashboard/wallets/interface/wallet"
import SingleContractor from "./SingleContractor"
import MultipleContractor from "./MultipleContractor"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import DynamicContractor from "./DynamicContractor"
import { usePost } from "utils/useFetch"
import { ICurrencies } from "pages/dashboard/wallets/integration/interface/kyc"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import usePermissions from "hooks/usePermissions"
import { useNavigate } from "react-router-dom"

const ContractorPaymentSchedule = () => {
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()

  const walletId = user?.organisationWalletId
  const [form] = Form.useForm()

  const [transferType, setTransferType] = useState("Single")
  const [canCreate] = usePermissions({
    allowedPermissions: ["can_create_pay_schedules"],
  })
  const [loading, setLoading] = useState(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [formBodySingle, setFormBodySingle] = useState<IFormData>({
    description: "",
    person_id: "",
    contractor_id: "",
    person_name: "",
    person_bank: "",
    person_account_number: "",
    amount: "",
    au_comment: "",
    state: "",
    currency_symbol: "",
    currency_code: "",
    source_currency_code: "",
  })
  const [formBodyMultipleEmp, setFormBodyMultipleEmp] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })
  const [formBodyMultipleCont, setFormBodyMultipleCont] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })
  const [formBodyDynamicEmp, setFormBodyDynamicEmp] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })
  const [formBodyDynamicCont, setFormBodyDynamicCont] = useState<any>({
    description: "",
    amount: "",
    persons: [],
    others: [],
    au_comment: "",
    state: "",
  })
  const { isLoading: isLoadingOverview, data: walletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${walletId}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })
  const { isLoading: isCurrencyLoading, data: currencies } = usePost<
    ICurrencies[]
  >({
    url: "/commonutils/getcurrencies",
    body: {
      q: "",
      page: "",
    },
  })

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        "/admin/payment_schedules/create_custom_payment_schedule",
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data?.status !== "error") {
        data.msg && toast.success(data.msg)
        mixPanelEvent("pay-schedule-custom-add-success")
        navigate("/dashboard/payment-schedules/custom")
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  return (
    <DashboardWrapper>
      <div className=" flex flex-col lg:flex-row items-start justify-start mt-5 gap-10">
        <div className="w-full lg:w-10/12">
          <SegmentWrapper
            title="Custom Schedule for Contractors"
            // subtitle="You can send payment schedules to your employees."
          >
            <Form
              layout="vertical"
              form={form}
              initialValues={{
                includeExited: false,
              }}
            >
              <div className="w-full  bg-white  rounded-md border-neutral-300 px-4 md:px-6 lg:px-8 xl:px-[60px]">
                <AntDesignRadio
                  label="Choose type of transfer"
                  name="sameAmount"
                  value={transferType}
                  id="custom-payment-transfer-type"
                >
                  <Space direction="horizontal">
                    <Radio
                      value={"Single"}
                      checked={transferType == "Single" ? true : false}
                      onChange={() => {
                        setTransferType("Single")
                      }}
                    >
                      Single
                    </Radio>
                    <Radio
                      value={"Multiple"}
                      onChange={() => {
                        setTransferType("Multiple")
                        setFormBodyDynamicCont(sampleBody)
                        setFormBodyDynamicEmp(sampleBody)
                      }}
                      checked={transferType == "Multiple" ? true : false}
                    >
                      Multiple
                    </Radio>
                    <Radio
                      value={"Dynamic"}
                      onChange={() => {
                        setTransferType("Dynamic")
                        setFormBodyMultipleCont(sampleBody)
                        setFormBodyMultipleEmp(sampleBody)
                      }}
                      checked={transferType == "Dynamic" ? true : false}
                    >
                      Dynamic Payout{" "}
                    </Radio>
                  </Space>
                </AntDesignRadio>

                {transferType == "Single" ? (
                  <SingleContractor
                    formBodySingle={formBodySingle}
                    setFormBodySingle={setFormBodySingle}
                    currencies={currencies}
                    setShowPreview={setShowPreview}
                    showPreview={showPreview}
                  />
                ) : transferType == "Multiple" ? (
                  <MultipleContractor
                    formBodyMultipleEmp={formBodyMultipleEmp}
                    formBodyMultipleCont={formBodyMultipleCont}
                    setFormBodyMultipleCont={setFormBodyMultipleCont}
                    setFormBodyMultipleEmp={setFormBodyMultipleEmp}
                    currencies={currencies}
                    setShowPreview={setShowPreview}
                    showPreview={showPreview}
                    setLoading={setLoading}
                  />
                ) : (
                  <DynamicContractor
                    setFormBodyDynamicEmp={setFormBodyDynamicEmp}
                    formBodyDynamicEmp={formBodyDynamicEmp}
                    setFormBodyDynamicCont={setFormBodyDynamicCont}
                    formBodyDynamicCont={formBodyDynamicCont}
                    currencies={currencies}
                    setShowPreview={setShowPreview}
                    showPreview={showPreview}
                    setLoading={setLoading}
                  />
                )}
              </div>
            </Form>
          </SegmentWrapper>
        </div>{" "}
        <Spin spinning={loading}>
          <Drawer
            title="Preview"
            open={!!showPreview}
            width={500}
            closeIcon={false}
            footer={
              <div className="flex items-center justify-end gap-4 mr-4">
                <Button
                  color="secondary"
                  onClick={() => {
                    setShowPreview(false)
                    if (transferType == "Single") {
                      setFormBodySingle((prev) => {
                        const updatedBody = Object.fromEntries(
                          Object.entries(prev).filter(
                            ([key]) => key !== "split"
                          )
                        )

                        return updatedBody as IFormData
                      })
                    } else if (transferType == "Multiple") {
                      setFormBodyMultipleEmp((prev: any) => ({
                        ...prev,
                        persons: prev.persons.map((person: any) =>
                          Object.fromEntries(
                            Object.entries(person).filter(
                              ([key]) => key !== "split"
                            )
                          )
                        ),
                      }))
                    } else if (transferType == "Dynamic") {
                      setFormBodyDynamicEmp((prev: any) => ({
                        ...prev,
                        persons: prev.persons.map((person: any) =>
                          Object.fromEntries(
                            Object.entries(person).filter(
                              ([key]) => key !== "split"
                            )
                          )
                        ),
                      }))
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  submitting={isSubmitting}
                  onClick={() => {
                    if (canCreate) {
                      if (transferType == "Single") {
                        const singleBody = [
                          {
                            description: formBodySingle.description,
                            amount: formBodySingle.amount,
                            au_comment: "",
                            contractor_id: formBodySingle?.contractor_id,
                            state: "",
                            source_currency_code:
                              formBodySingle?.currency_code === "USD" &&
                              formBodySingle?.source_currency_code === "USD"
                                ? ""
                                : formBodySingle?.source_currency_code === "USD"
                                ? formBodySingle?.source_currency_code
                                : "",
                          },
                        ]

                        submit(singleBody)
                      } else if (transferType == "Multiple") {
                        const found = formBodyMultipleEmp?.persons?.find(
                          (per: any, idx: any) => per.amount == ""
                        )

                        if (found) {
                          // setRequiredAmountError(true)
                        } else {
                          const multipleBodyEmp =
                            formBodyMultipleEmp.persons?.map(
                              (per: any, index: any) => {
                                return {
                                  description: formBodyMultipleEmp.description,
                                  person_id: String(per.person_id),
                                  contractor_id: "",
                                  amount: String(per.amount),
                                  au_comment: "",
                                  state: "",
                                  source_currency_code:
                                    per?.currency_code === "USD" &&
                                    per?.source_currency_code === "USD"
                                      ? ""
                                      : per?.source_currency_code === "USD"
                                      ? per?.source_currency_code
                                      : "",
                                }
                              }
                            )
                          const multipleBodyCont =
                            formBodyMultipleCont.persons?.map(
                              (per: any, index: any) => {
                                return {
                                  description: formBodyMultipleEmp.description,
                                  contractor_id: String(per.contractor_id),
                                  person_id: "",
                                  amount: String(per.amount),
                                  au_comment: "",
                                  state: "",
                                  source_currency_code:
                                    per?.currency_code === "USD" &&
                                    per?.source_currency_code === "USD"
                                      ? ""
                                      : per?.source_currency_code === "USD"
                                      ? per?.source_currency_code
                                      : "",
                                }
                              }
                            )

                          const updatedBody = [
                            ...multipleBodyEmp,
                            ...multipleBodyCont,
                          ]

                          submit(updatedBody)
                        }
                      } else if (transferType == "Dynamic") {
                        const found = formBodyDynamicEmp?.persons?.find(
                          (per: any, idx: any) => per.amount == ""
                        )

                        if (found) {
                          // setRequiredAmountError(true)
                        } else {
                          const multipleBodyEmp =
                            formBodyDynamicEmp.persons?.map(
                              (per: any, index: any) => {
                                return {
                                  description: formBodyDynamicEmp.description,
                                  person_id: String(per.person_id),
                                  contractor_id: "",
                                  amount: String(per.amount),
                                  au_comment: "",
                                  state: "",
                                  source_currency_code:
                                    per?.currency_code === "USD" &&
                                    per?.source_currency_code === "USD"
                                      ? ""
                                      : "USD",
                                }
                              }
                            )
                          const multipleBodyCont =
                            formBodyDynamicCont.persons?.map(
                              (per: any, index: any) => {
                                return {
                                  description: formBodyDynamicEmp.description,
                                  contractor_id: String(per.contractor_id),
                                  person_id: "",
                                  amount: String(per.amount),
                                  au_comment: "",
                                  state: "",
                                  source_currency_code:
                                    per?.currency_code === "USD" &&
                                    per?.source_currency_code === "USD"
                                      ? ""
                                      : "USD",
                                }
                              }
                            )

                          const updatedBody = [
                            ...multipleBodyEmp,
                            ...multipleBodyCont,
                          ]

                          submit(updatedBody)
                        }
                      }
                    }
                  }}
                >
                  Proceed
                </Button>
              </div>
            }
          >
            {loading ? (
              <div className="!h-full  flex items-center justify-center w-full">
                <Spin spinning />
              </div>
            ) : (
              <div className="flex-grow overflow-y-scroll flex justify-center">
                {(formBodySingle.contractor_id !== "" ||
                  formBodyMultipleEmp?.persons?.length > 0 ||
                  formBodyMultipleCont?.persons?.length > 0 ||
                  formBodyDynamicCont?.persons?.length > 0 ||
                  formBodyDynamicEmp?.persons?.length > 0) && (
                  <div className="w-full  border-#C2C7D0">
                    <div className="h-auto bg-primary700  rounded-[10px] px-4 py-5 justify-between flex flex-col mb-7 ">
                      <h3 className="text-white font-extrabold text-lg mb-2">
                        Wallet Balance
                      </h3>
                      <div className="border  border-white w-full" />
                      {isLoadingOverview ? (
                        <div className="w-full flex justify-center items-center py-3 mt-4">
                          <LoadingIndicatorWhite />
                        </div>
                      ) : (
                        <>
                          {walletData?.data?.balances?.map((item, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-2 my-2"
                            >
                              <p className="text-white text-sm">
                                {item?.currency}
                              </p>

                              <p className="text-white text-sm font-bold text-right">
                                {item?.symbol}
                                {formatCurrencyNoSymbol(
                                  Number(item?.balance) || 0
                                )}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    <div className=" overflow-y-scroll scrollbar-hide mt-6">
                      {transferType == "Single" &&
                        formBodySingle.amount !== "" &&
                        formBodySingle.contractor_id !== "" && (
                          <div className="mt-6">
                            <div className="space-y-4 border-y border-[#C2C7D0] mb-4 py-4">
                              <div className="grid grid-cols-2">
                                <span className="text-[#98A1B0] text-sm">
                                  Beneficiary
                                </span>
                                <span className="text-[#243757] font-bold text-sm">
                                  {formBodySingle.person_name}
                                </span>
                              </div>
                              <div className="grid grid-cols-2">
                                <span className="text-[#98A1B0] text-sm">
                                  {" "}
                                  Account
                                </span>
                                <span className="text-[#243757] font-bold text-sm">
                                  {formBodySingle.person_bank}
                                </span>
                              </div>
                              <div className="grid grid-cols-2">
                                <span className="text-[#98A1B0] text-sm">
                                  Account Number
                                </span>
                                <span className="text-[#243757] font-bold text-sm">
                                  {formBodySingle.person_account_number}
                                </span>
                              </div>
                              <div className="grid grid-cols-2">
                                <span className="text-[#98A1B0] text-sm">
                                  Amount
                                </span>
                                <span className="text-[#243757] font-bold text-sm">
                                  {formBodySingle?.source_currency_code !==
                                  "USD"
                                    ? formBodySingle?.currency_symbol
                                    : "$"}

                                  {formatCurrencyNoSymbol(
                                    Number(formBodySingle.amount)
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                      {transferType == "Multiple" &&
                        (formBodyMultipleEmp?.persons?.length > 0 ||
                          formBodyMultipleCont?.persons?.length > 0) && (
                          <div className="  ">
                            {formBodyMultipleCont?.persons?.map(
                              (item: any, index: any) => (
                                <div
                                  key={index}
                                  className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                                >
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Beneficiary
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      {item.person_name}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Account
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      {item.person_bank}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Account Number
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      {item.person_account_number}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Amount
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      {item?.source_currency_code !== "USD"
                                        ? item?.currency_symbol
                                        : "$"}
                                      {""}
                                      {formatCurrencyNoSymbol(
                                        Number(item.amount || 0)
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                            {formBodyMultipleEmp?.persons?.map(
                              (item: any, index: any) => (
                                <div
                                  key={index}
                                  className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                                >
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Beneficiary
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      {item.person_name}
                                    </span>
                                  </div>
                                  {item?.split ? (
                                    <div className="flex flex-col gap-2">
                                      {[
                                        {
                                          account:
                                            item?.split?.configOneAccountNumber,
                                          bank: item?.split?.configOneBank,
                                          amount: item?.split?.configOneamount,
                                        },
                                        {
                                          account:
                                            item?.split?.configTwoAccountNumber,
                                          bank: item?.split?.configTwoBank,
                                          amount: item?.split?.configTwoamount,
                                        },
                                      ].map((acc, idx) => (
                                        <div
                                          key={idx}
                                          className="flex flex-col gap-2"
                                        >
                                          <Tag
                                            color="blue"
                                            className="font-bold text-padeBlack w-fit"
                                          >
                                            Account {idx + 1}
                                          </Tag>
                                          {acc?.bank && (
                                            <div className="grid grid-cols-2">
                                              <span className="text-[#98A1B0] text-sm">
                                                {" "}
                                                Account
                                              </span>
                                              <span className="text-[#243757] font-bold text-sm">
                                                {acc?.bank}
                                              </span>
                                            </div>
                                          )}
                                          {acc?.account && (
                                            <div className="grid grid-cols-2">
                                              <span className="text-[#98A1B0] text-sm">
                                                Account Number
                                              </span>
                                              <span className="text-[#243757] font-bold text-sm">
                                                {acc?.account}
                                              </span>
                                            </div>
                                          )}
                                          {acc?.amount && (
                                            <div className="grid grid-cols-2">
                                              <span className="text-[#98A1B0] text-sm">
                                                Amount
                                              </span>
                                              <span className="text-[#243757] font-bold text-sm">
                                                {item?.source_currency_code !==
                                                "USD"
                                                  ? item?.currency_symbol
                                                  : "$"}
                                                {""}
                                                {formatCurrencyNoSymbol(
                                                  Number(acc.amount || 0)
                                                )}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="grid grid-cols-2">
                                        <span className="text-[#98A1B0] text-sm">
                                          Account
                                        </span>
                                        <span className="text-[#243757] font-bold text-sm">
                                          {item.person_bank}
                                        </span>
                                      </div>
                                      <div className="grid grid-cols-2">
                                        <span className="text-[#98A1B0] text-sm">
                                          Account Number
                                        </span>
                                        <span className="text-[#243757] font-bold text-sm">
                                          {item.person_account_number}
                                        </span>
                                      </div>
                                      <div className="grid grid-cols-2">
                                        <span className="text-[#98A1B0] text-sm">
                                          Amount
                                        </span>
                                        <span className="text-[#243757] font-bold text-sm">
                                          {item?.source_currency_code !== "USD"
                                            ? item?.currency_symbol
                                            : "$"}
                                          {""}
                                          {formatCurrencyNoSymbol(
                                            Number(item.amount || 0)
                                          )}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      {transferType == "Dynamic" &&
                        (formBodyDynamicCont?.persons?.length > 0 ||
                          formBodyDynamicEmp?.persons?.length > 0) && (
                          <div className="">
                            {formBodyDynamicCont?.persons?.map(
                              (item: any, index: any) => (
                                <div
                                  key={index}
                                  className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                                >
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Beneficiary
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      {item.person_name}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Account
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      {item.person_bank}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Account Number
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      {item.person_account_number}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Amount
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      $
                                      {formatCurrencyNoSymbol(
                                        Number(item.amount || 0)
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                            {formBodyDynamicEmp?.persons?.map(
                              (item: any, index: any) => (
                                <div
                                  key={index}
                                  className="space-y-4 border-b border-[#C2C7D0] mb-4 pb-4"
                                >
                                  <div className="grid grid-cols-2">
                                    <span className="text-[#98A1B0] text-sm">
                                      Beneficiary
                                    </span>
                                    <span className="text-[#243757] font-bold text-sm">
                                      {item.person_name}
                                    </span>
                                  </div>
                                  {item?.split ? (
                                    <div className="flex flex-col gap-2">
                                      {[
                                        {
                                          account:
                                            item?.split?.configOneAccountNumber,
                                          bank: item?.split?.configOneBank,
                                          amount: item?.split?.configOneamount,
                                        },
                                        {
                                          account:
                                            item?.split?.configTwoAccountNumber,
                                          bank: item?.split?.configTwoBank,
                                          amount: item?.split?.configTwoamount,
                                        },
                                      ].map((acc, idx) => (
                                        <div
                                          key={idx}
                                          className="flex flex-col gap-2"
                                        >
                                          <Tag
                                            color="blue"
                                            className="font-bold text-padeBlack w-fit"
                                          >
                                            Account {idx + 1}
                                          </Tag>
                                          {acc?.bank && (
                                            <div className="grid grid-cols-2">
                                              <span className="text-[#98A1B0] text-sm">
                                                {" "}
                                                Account
                                              </span>
                                              <span className="text-[#243757] font-bold text-sm">
                                                {acc?.bank}
                                              </span>
                                            </div>
                                          )}
                                          {acc?.account && (
                                            <div className="grid grid-cols-2">
                                              <span className="text-[#98A1B0] text-sm">
                                                Account Number
                                              </span>
                                              <span className="text-[#243757] font-bold text-sm">
                                                {acc?.account}
                                              </span>
                                            </div>
                                          )}
                                          {acc?.amount && (
                                            <div className="grid grid-cols-2">
                                              <span className="text-[#98A1B0] text-sm">
                                                Amount
                                              </span>
                                              <span className="text-[#243757] font-bold text-sm">
                                                $
                                                {formatCurrencyNoSymbol(
                                                  Number(acc.amount || 0)
                                                )}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="grid grid-cols-2">
                                        <span className="text-[#98A1B0] text-sm">
                                          Account
                                        </span>
                                        <span className="text-[#243757] font-bold text-sm">
                                          {item.person_bank}
                                        </span>
                                      </div>
                                      <div className="grid grid-cols-2">
                                        <span className="text-[#98A1B0] text-sm">
                                          Account Number
                                        </span>
                                        <span className="text-[#243757] font-bold text-sm">
                                          {item.person_account_number}
                                        </span>
                                      </div>
                                      <div className="grid grid-cols-2">
                                        <span className="text-[#98A1B0] text-sm">
                                          Amount
                                        </span>
                                        <span className="text-[#243757] font-bold text-sm">
                                          $
                                          {formatCurrencyNoSymbol(
                                            Number(item.amount || 0)
                                          )}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Drawer>
        </Spin>
      </div>
    </DashboardWrapper>
  )
}

export default ContractorPaymentSchedule

export interface IFormData {
  description: string
  person_id?: string
  contractor_id?: string
  person_name?: string
  person_bank?: string
  person_account_number?: string
  amount: string
  au_comment: string
  state: string
  employees?: any[]
  currency_symbol?: string
  currency_code?: string
  source_currency_code?: string
  split?: {
    configOneamount: number
    configOnePercentage: number
    configOneBank: string
    configOneAccountNumber: number
    configTwoamount: number
    configTwoPercentage: number
    configTwoBank: string
    configTwoAccountNumber: number
  }
}
export const sampleBody = {
  description: "",
  amount: "",
  persons: [],
  others: [],
  au_comment: "",
  state: "",
}
