import {
  ClipboardDocumentIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { useQuery } from "@tanstack/react-query"
import { Drawer, Spin } from "antd"
import { P2 } from "components"
import useCopyToClipboard from "hooks/useCopyToClipboard"
import React from "react"
import { useAppNotificationStore } from "stores"
import { walletAxiosInstance } from "utils/request"

const globalThingsToKeepInMind = [
  "Receive into this International account via International Transfers (SWIFT).",
  "Incoming payments are typically received in 1-5 working days.",
  <>
    You can recieve payments from the countries on{" "}
    <a
      href="https://support.paidhr.com/en/articles/10037940-what-countries-can-i-receive-payments-from"
      target="_blank"
      rel="noopener noreferrer"
      className="text-primary500 underline"
    >
      this list
    </a>
  </>,
]

const localThingsToKeepInMind = [
  "Receive into this local account via  CHAPS, BACS & Faster payments.",
  "Incoming payments are typically received in minutes but can take up a few hours during peak times",
]

const transformData = (data: Res["data"]): AccountItem[] => {
  // const localData = data?.find((item) => item?.paymentRoute === "local")
  // const globalData = data?.find(
  //   (item) => item?.paymentRoute === "international"
  // )
  const localData = {
    accountName: "Pade HCM Inc.",
    accountNumber: "96567533",
    bankCode: "608382",
    bankName: "BankingCircle",
    bankAddress: "24 KING WILLIAM STREET, LONDON, EC4R 9AT, United Kingdom",
  }
  const globalData = {
    accountName: "Pade HCM Inc.",
    accountNumber: "GB95SAPY60838296567533",
    bankCode: "",
    bankName: "BankingCircle",
    bankAddress: "AMERIKA PLADS 38, KOEBENHAVN, 2900, Denmark",
  }

  return [
    {
      title: "Account name",
      local: localData?.accountName || "",
      global: globalData?.accountName || "",
    },
    {
      title: "Account number",
      local: localData?.accountNumber || "",
      global: globalData?.accountNumber || "",
    },
    {
      title: "Bank code",
      local: localData?.bankCode || "",
      global: globalData?.bankCode || "",
    },
    {
      title: "Bank name",
      local: localData?.bankName || "",
      global: globalData?.bankName || "",
    },
    {
      title: "Bank address",
      local: localData?.bankAddress || "",
      global: globalData?.bankAddress || "",
    },
  ]
}

const FundGBPDrawer = ({
  open,
  setOpen,
  walletId,
  currency,
}: {
  walletId: string
  currency: string

  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [activeTab, setActiveTab] = React.useState("local")
  const [_, copy] = useCopyToClipboard()
  const { toast } = useAppNotificationStore()

  const { isLoading: isaccountLoading, data: accountData } = useQuery<Res>({
    queryKey: ["funding-account", currency],
    queryFn: async () => {
      const res = await walletAxiosInstance.get(
        `/v1/wallets/funding-account/${currency}`
      )
      return res.data
    },
  })

  const items = transformData(accountData?.data || [])

  return (
    <Drawer
      width={580}
      title={
        <div className="text-lg text-[#0C1B3D] font-circular flex justify-between items-center gap-2">
          Bank Details
          <XMarkIcon
            className="text-[#111827] w-5"
            role="button"
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
      }
      closeIcon={null}
      onClose={() => {
        setOpen(false)
      }}
      open={open}
    >
      <Spin spinning={isaccountLoading}>
        <div className="">
          <div className="">
            {/* <P2 className="text-padeBlack font-semibold font-circular mb-2">
              Choose preferred funding method
            </P2> */}
            <div className="flex gap-4 bg-[#F3F5FB] py-1.5 px-2 rounded-lg">
              {/* {["local", "global"].map((tab, index) => ( */}
              {["local"].map((tab, index) => (
                <div
                  role="button"
                  key={index}
                  className={`grow py-2 px-4 text-center text-sm font-circular font-medium ${
                    activeTab === tab
                      ? "bg-white text-padeBlack"
                      : "bg-transparent text-padeSubtext"
                  } capitalize transition-all ease-linear duration-100`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </div>
              ))}
            </div>
          </div>

          <div className="bg-[#FCBF231F]  flex justify-start items-center gap-3 py-4 px-4 mt-6">
            <ExclamationCircleIcon className="w-7 text-[#f39f03] shrink-0" />
            <div className="f">
              <P2 className="text-neutral400 ">
                This account is authorized to receive payments only from your
                registered company’s bank accounts.
              </P2>
              <P2 className="text-neutral400  mt-2">
                Any payments from individuals will be returned to sender.
              </P2>
              <P2 className="text-red-500 mt-2 font-bold">
                <span className=" inline-flex items-center ">
                  Please use Reference ID {walletId}{" "}
                  <button
                    onClick={() => {
                      copy(`Reference ID: ${walletId}`)
                      toast.success("Copied")
                    }}
                    className="shrink-0 text-primary500 "
                  >
                    <ClipboardDocumentIcon className="w-4 h-4" />
                  </button>
                </span>{" "}
                as your narration when making payments to this account.
              </P2>
            </div>
          </div>

          <div className="mb-2 flex items-center gap-2 mt-6">
            <P2 className="text-padeBlack font-semibold font-circular">
              Copy {activeTab} details{" "}
            </P2>
            <button
              onClick={() => {
                // copy all details
                const details = items
                  .map((item) => {
                    return `${item.title}: ${
                      activeTab === "local" ? item.local : item.global
                    }`
                  })
                  .join("\n")

                copy(details)

                toast.success("Copied to clipboard")
              }}
              className="shrink-0 text-primary500"
            >
              <ClipboardDocumentIcon className="w-5 h-5" />
            </button>
          </div>
          <div className="grid gap-y-4 mb-8 p-4 bg-primary50 rounded-lg shadow">
            {items?.map((item, idx) => (
              <div
                key={idx}
                className="flex justify-between gap-4"
              >
                <span className="font-avenir text-neutral300 text-sm shrink-0">
                  {item.title}
                </span>
                <div className="flex items-center gap-2">
                  <span className="font-circular text-padeBlack text-sm font-semibold capitalize text-end max-w-[350px]">
                    {activeTab === "local" ? item.local : item.global}
                  </span>
                  <button
                    onClick={() => {
                      copy(activeTab === "local" ? item.local : item.global)
                      toast.success("Copied")
                    }}
                    className="shrink-0 text-primary500 "
                  >
                    <ClipboardDocumentIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="border py-4 px-4 mt-10 rounded-lg">
            <P2 className="text-padeBlack font-semibold font-circular mb-4">
              Things to keep in mind
            </P2>
            <ul>
              <li className="text-padeBlack font-avenir text-sm mt-1 list-disc list-inside">
                Use{" "}
                <span className="font-bold inline-flex items-center ">
                  Reference ID {walletId}{" "}
                  <button
                    onClick={() => {
                      copy(`Reference ID: ${walletId}`)
                      toast.success("Copied")
                    }}
                    className="shrink-0 text-primary500 "
                  >
                    <ClipboardDocumentIcon className="w-4 h-4" />
                  </button>
                </span>{" "}
                as your narration when making payments to this account.
              </li>
              {(activeTab === "local"
                ? localThingsToKeepInMind
                : globalThingsToKeepInMind
              ).map((item, index) => (
                <li
                  key={index}
                  className="text-padeBlack font-avenir text-sm mt-1 list-disc list-inside"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Spin>
    </Drawer>
  )
}

export default FundGBPDrawer

interface Res {
  success: boolean
  code: number
  message: string
  data: {
    bankCode: string
    bankAddress: string
    bankName: string
    accountName: string
    accountNumber: string
    paymentRoute: "local" | "international"
  }[]
}

interface AccountItem {
  title: string
  local: string
  global: string
}
