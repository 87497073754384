import { useState } from "react"

import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"

import { DatePicker } from "antd"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useAuthStore } from "stores"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import dayjs, { Dayjs } from "dayjs"
import { dateData } from "pages/dashboard/wallets/Statements"
import { IEmployeeWalletRes } from "pages/dashboard/wallets/crossBorderPayments/transactions/interface"
import TransfersTable from "./component/TransfersTable"

const CrossBorderTransfers = () => {
  const { RangePicker } = DatePicker

  const [search, setSearch] = useState("")
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(1, "month").format("YYYY/MM/DD") || ""
  )
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )
  const [isCustom, setIsCustom] = useState(false)

  const [activePeriod, setActivePeriod] = useState<dateData>({
    id: "",
    period: "",
    startDate: "",
    endDate: "",
  })

  const { isLoading: isTransfersLoading, data: transfersData } =
    useQuery<IEmployeeWalletRes>({
      queryKey: [`transfers`, startDate, endDate],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/payment-transactions?limit=0&type=payment&startDate=${
            startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
          }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess: (data: any) => {},
    })

  const periods: dateData[] = [
    {
      id: "1",
      period: "One Week",
      startDate: "",
      endDate: "",
    },
    {
      id: "2",
      period: "One Month",
      startDate: "",
      endDate: "",
    },

    {
      id: "3",
      period: "Custom",
      startDate: "",
      endDate: "",
    },
  ]
  return (
    <>
      <DashboardWrapper>
        <div className="">
          <div className=" bg-white my-4 px-4 pt-5">
            <div className="flex gap-2 justify-end items-center mb-4">
              <div className="bg-[#F3F5FB] rounded-sm cursor-pointer flex ">
                {periods?.map((it: dateData) => (
                  <div
                    onClick={() => {
                      if (it?.id === "1") {
                        setStartDate(
                          dayjs(new Date())
                            .subtract(7, "day")
                            .format("YYYY/MM/DD") || ""
                        )
                        setEndDate(
                          dayjs(new Date())?.format("YYYY/MM/DD") || ""
                        )
                      } else if (it?.id === "2") {
                        setStartDate(
                          dayjs(new Date())
                            .subtract(30, "day")
                            .format("YYYY/MM/DD") || ""
                        )
                        setEndDate(
                          dayjs(new Date())?.format("YYYY/MM/DD") || ""
                        )
                      } else {
                        setIsCustom(true)
                        setActivePeriod({
                          id: "3",
                          period: "Custom",
                          startDate: "",
                          endDate: "",
                        })
                        return
                      }
                      setActivePeriod(it)
                      setIsCustom(false)
                    }}
                    key={it?.id}
                    className={`${
                      it.id === activePeriod?.id && "bg-primary500 text-white"
                    } whitespace-nowrap text-[#42526D] h-full w-full p-2 rounded-md`}
                  >
                    {it?.period}
                  </div>
                ))}
              </div>
              {isCustom && (
                <div className="flex gap-2">
                  <RangePicker
                    format="YYYY/MM/DD"
                    placeholder={[" Start Date", "End Date"]}
                    onChange={(
                      dates: null | (Dayjs | null)[],
                      dateStrings: string[]
                    ) => {
                      if (dates) {
                        setStartDate(dateStrings[0])
                        setEndDate(dateStrings[1])
                      } else {
                      }
                    }}
                    className=" w-full sm:w-[250px] rounded-[5px]  text-neutral600 border  border-neutral50  h-full bg-white"
                    disabledDate={(date) => {
                      const endDate = dayjs()

                      const afterEndDate = date.isAfter(endDate)

                      return afterEndDate
                    }}
                  />
                </div>
              )}
            </div>
            <TransfersTable
              transactionsData={transfersData?.data?.docs}
              loading={isTransfersLoading}
            />
          </div>
        </div>
      </DashboardWrapper>
    </>
  )
}
export default CrossBorderTransfers
