import axios, { AxiosInstance } from "axios"
import { useAppNotificationStore, useAuthStore } from "../stores"
import { baseUrl } from "./constants"
import { queryClient } from "../index"
import {
  extendedAppUrl,
  newBaseUrl,
  walletBaseUrl,
  walletPublicKey,
} from "./newbaseurl"

// Request configuration type
type Request = {
  url: string // API endpoint or external resource URL
  body?: object | string | URLSearchParams | FormData | File | Blob
  params?: URLSearchParams
  headers?: Record<string, string>
  external?: boolean
}

type RequestMethod = "GET" | "POST" | "PATCH" | "DELETE" | "PUT" | "HEAD"

let checkSessionPromise: Promise<any> | null = null

/** Helper to create Axios instances */
const createAxiosInstance = (baseURL: string): AxiosInstance => {
  return axios.create({ baseURL })
}

/** Set up interceptors for an Axios instance */
const setupInterceptors = (instance: AxiosInstance, authRequired = true) => {
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("pade_auth_token")
      const refreshToken = localStorage.getItem("refresh_token")
      const userId = localStorage.getItem("skv")
      const organisationId = localStorage.getItem("xfr")
      const currentCompanyId = localStorage.getItem("cgm")

      if (authRequired && token) {
        config.headers["access_token"] = `${token}`
        config.headers["refresh_token"] = `${refreshToken}`
        config.headers["api_key"] = `${walletPublicKey}`
      }

      if (userId && organisationId && currentCompanyId) {
        const padeVersion = `${organisationId}.${currentCompanyId}.${userId}`
        config.headers["Api-Version"] = padeVersion
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error.config

      if (error.response?.status === 401 && !prevRequest._retry) {
        queryClient.cancelQueries() // Cancel ongoing queries
        prevRequest._retry = true

        if (!checkSessionPromise) {
          checkSessionPromise = new Promise(async (resolve, reject) => {
            try {
              const { checkUserSession } = useAuthStore.getState()
              const res = await checkUserSession() // Call session check
              if (res?.status === "success") {
                resolve(res)
              } else {
                useAuthStore.getState().signOut(true) // Sign out if session check fails
                reject("Session expired")
              }
            } catch (error) {
              useAuthStore.getState().signOut(true) // Sign out on error
              reject(error)
            } finally {
              checkSessionPromise = null // Reset promise after completion
            }
          })
        }

        try {
          await checkSessionPromise // Wait for session check
          return instance(prevRequest) // Retry the original request
        } catch (err) {
          return Promise.reject(err) // Reject if session check fails
        }
      }

      if (error.response?.status === 406) {
        useAuthStore.getState().signOut(true)
      }

      return Promise.reject(error)
    }
  )
}

/** Axios instances */
export const axiosInstance = createAxiosInstance(baseUrl as string)
setupInterceptors(axiosInstance)

export const extendedAxiosInstance = createAxiosInstance(
  extendedAppUrl as string
)
setupInterceptors(extendedAxiosInstance)

export const publicExtendedAxiosInstance = createAxiosInstance(
  extendedAppUrl as string
)

export const walletAxiosInstance = createAxiosInstance(walletBaseUrl as string)
setupInterceptors(walletAxiosInstance)

export const ewaAxiosInstance = createAxiosInstance(newBaseUrl as string)
setupInterceptors(ewaAxiosInstance)

/** Core function to make API requests */
const requestCore = async (method: RequestMethod, config: Request) => {
  try {
    const { url, body, params, headers, external = false } = config

    const response = await axiosInstance({
      baseURL: external ? url : baseUrl,
      url: external ? "" : url,
      method,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      data: ["GET", "HEAD"].includes(method) ? undefined : body,
      params,
      validateStatus: (status) => status >= 200 && status < 500,
    })

    return response.data
  } catch (error: any) {
    if (error.code === "ERR_NETWORK") {
      useAppNotificationStore
        .getState()
        .toast.error("Please check your network connection!")
    } else {
      console.error(error.response?.data?.message || error.message)
      throw new Error(
        error.response?.statusText || "Request failed. Please try again."
      )
    }
  }
}

/** API Request Utility */
export const request = {
  get: (config: Request) => requestCore("GET", config),
  post: (config: Request) => requestCore("POST", config),
  patch: (config: Request) => requestCore("PATCH", config),
  put: (config: Request) => requestCore("PUT", config),
  delete: (config: Request) => requestCore("DELETE", config),
}
