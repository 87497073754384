import { useState } from "react"
import { IConversationRoundItem } from "../interface"
import { Form, Input, Spin } from "antd"
import dayjs from "dayjs"
import { Button } from "components"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline"

interface props {
  item: IConversationRoundItem
  refetch: () => void
}
const Note = ({ item, refetch }: props) => {
  const { toast } = useAppNotificationStore()

  const [form] = Form.useForm()
  const editNote = Form.useWatch("edit-note", form)

  const [editNoteId, setEditNoteId] = useState("")
  const [deleteNoteId, setDeleteNoteId] = useState(0)

  const { isLoading: isEditingNote, mutate: submitEditNote } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/employee/performance/edit_conversationround_note?id=${editNoteId}`,
        body
      )
      return res.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data?.msg && toast.success(data?.msg)
        refetch()
        form.setFieldValue("edit-note", "")
        form.resetFields()
        setEditNoteId("")
      }
    },
  })
  const { isLoading: isDeletingNote, mutate: DeleteNote } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/employee/performance/delete_conversationround_note?id=${deleteNoteId}`
      )
      return res.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data?.msg && toast.success(data?.msg)
        refetch()
      }
    },
  })
  return (
    <Form
      form={form}
      initialValues={{
        "edit-action": item?.description,
        "action-start": item?.start_date
          ? dayjs(item.start_date, "DD/MM/YYYY")
          : null,
        "action-end": item?.due_date
          ? dayjs(item.due_date, "DD/MM/YYYY")
          : null,
      }}
    >
      <div className="rounded bg-[#F4F5F7] px-3.5 py-2.5 text-padeBlack font-medium font-circular text-sm flex justify-between items-center">
        {String(item?.conversation_round_item_id) === editNoteId ? (
          <div className=" w-full">
            <Form.Item
              name="edit-note"
              className="col-span-2"
            >
              <Input.TextArea
                rootClassName="!h-[50px]"
                className="w-full "
                placeholder="Edit note"
                defaultValue={item?.description}
              />
            </Form.Item>
            <div className="flex justify-end  items-center gap-2">
              <Button
                color="secondary"
                onClick={() => setEditNoteId("")}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                submitting={isEditingNote}
                onClick={() => {
                  const body = {
                    note: editNote,
                  }

                  submitEditNote(body)
                }}
              >
                Update
              </Button>
            </div>
          </div>
        ) : (
          <>
            <p> {item?.description}</p>
            <div className="flex items-center gap-2">
              <button
                onClick={() => {
                  setEditNoteId(String(item?.conversation_round_item_id))
                }}
              >
                <PencilIcon className="h-4 w-4" />
              </button>{" "}
              <button
                onClick={() => {
                  const body = {
                    id: String(item?.conversation_round_item_id),
                  }
                  setDeleteNoteId(item?.conversation_round_item_id)
                  DeleteNote(body)
                }}
              >
                {item?.conversation_round_item_id === deleteNoteId &&
                isDeletingNote ? (
                  <Spin spinning />
                ) : (
                  <TrashIcon className="h-4 w-4" />
                )}
              </button>{" "}
            </div>
          </>
        )}
      </div>
    </Form>
  )
}

export default Note
