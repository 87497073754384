import { Button, DashboardContainer, InfoCardDetails } from "components"
import InfoCard from "pages/employee-dashboard/shared/Card"
import { Link, useNavigate } from "react-router-dom"
import { useGet, usePost } from "utils/useFetch"
import { IAddress, ICompany, IOfficeAddress } from "./typings"
import { SwitchFilingMailingAddress } from "./Popups"
import { useState } from "react"

type Company = {
  filing_address_id: number
  filing_address_key: string
  mailing_address_id: number
  mailing_address_key: string
  officeAddresses: IAddress[]
}

const ManageWorkplace = () => {
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const { data: company, isLoading: isCompanyLoading } = useGet<Company>({
    url: "admin/company/view/locations",
  })

  const filingAddress = company?.officeAddresses.find(
    (item) => item.office_address_id === company?.filing_address_id
  )

  const mailingAddress = company?.officeAddresses.find(
    (item) => item.office_address_id === company?.mailing_address_id
  )

  return (
    <DashboardContainer>
      <InfoCard
        title="Workplaces"
        className="mb-4"
        description=""
        loading={isCompanyLoading}
        actions={
          <div className="flex gap-2">
            <Button
              color="secondary"
              onClick={() => {
                company?.filing_address_id === -1 ||
                company?.mailing_address_id === -1
                  ? navigate("/dashboard/company-details/add-workplace")
                  : setShowModal(true)
              }}
            >
              {company?.filing_address_id === -1 ||
              company?.mailing_address_id === -1
                ? "Add Workplace"
                : "Switch filing & Mailing addresses"}
            </Button>

            {/* <Link to="/dashboard/company-details/edit-workplace/231">
              <Button
                title="Edit"
                color="secondary"
              >
                Edit
              </Button>
            </Link> */}
          </div>
        }
      >
        <>
          {company?.filing_address_id === -1 ||
          company?.mailing_address_id === -1 ? (
            <div className="py-5 px-4 lg:px-6">
              <p className="text-neutral500 font-avenir">
                Looks like you have not added any Workplaces yet. Please add
                your Workplaces in order to be able to file your taxes correctly
                and receive mails.
              </p>
            </div>
          ) : (
            <>
              <InfoCardDetails
                address={filingAddress?.full_address || ""}
                phone={filingAddress?.phone || ""}
                heading={
                  company?.filing_address_id === company?.mailing_address_id
                    ? "Filing & Mailing Address"
                    : "Filing Address"
                }
                title={filingAddress?.display_name || ""}
                withEditIcon
                id={String(company?.filing_address_id)}
                addressKey={company?.filing_address_key}
              />
              {company?.filing_address_id !== company?.mailing_address_id && (
                <InfoCardDetails
                  address={mailingAddress?.full_address || ""}
                  phone={mailingAddress?.phone || ""}
                  heading="Mailing Address"
                  title={mailingAddress?.display_name || ""}
                  withEditIcon
                  id={String(company?.mailing_address_id)}
                  key={company?.mailing_address_key}
                />
              )}
            </>
          )}
        </>
      </InfoCard>

      <>
        {company?.filing_address_id === -1 ||
        company?.mailing_address_id === -1 ? null : (
          <InfoCard
            title="Other Workplaces"
            description=""
            loading={isCompanyLoading}
            actions={
              <Button
                title="Edit"
                color="secondary"
                onClick={() =>
                  navigate("/dashboard/company-details/add-workplace")
                }
              >
                Add Workplace
              </Button>
            }
          >
            {company?.officeAddresses
              ?.filter(
                (item) =>
                  item.office_address_id !== company?.filing_address_id &&
                  item.office_address_id !== company?.mailing_address_id &&
                  item.office_address_id !== 0
              )
              ?.map((item, i) => {
                const lastItem = i === company?.officeAddresses?.length - 1
                return (
                  <div
                    className={`${
                      lastItem ? "border-none pb-16" : "border-b"
                    } border-[#DFE2E6]`}
                    key={i}
                  >
                    <InfoCardDetails
                      address={item.full_address || ""}
                      phone={item.phone || ""}
                      withEditIcon
                      title={item.display_name || ""}
                      className="pb-5"
                      id={String(item.office_address_id || "")}
                      addressKey={item.office_address_key || ""}
                      taxId={item.tax_id || ""}
                    />
                  </div>
                )
              })}
          </InfoCard>
        )}
      </>
      <>
        {showModal && (
          <SwitchFilingMailingAddress
            company={company}
            isLoading={isCompanyLoading}
            toggleEditSwitchFilingMailingAddress={setShowModal}
          />
        )}
      </>
    </DashboardContainer>
  )
}

export default ManageWorkplace
