import { Modal } from "antd"
import { Button, H5, P2 } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { useEffect, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { useNavigate } from "react-router-dom"
import { useAuthStore } from "stores"
import { useAuthModalStore } from "stores/auth-modal"

// Set timeout values
const timeout = 1000 * 60 * 30 // 30 minutes
// time modal will be shown before logout
const promptBeforeIdle = 1000 * 60 * 5 // 5 minute

// const timeout = 1000 * 10
// const promptBeforeIdle = 1000 * 5

const IdleTimer = () => {
  // Time before idle
  const [remaining, setRemaining] = useState(timeout)
  const [openIdleModal, setOpenIdleModal] = useState(false)
  const { open: openLoginModal, isOpen } = useAuthModalStore((state) => state)
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)

  const navigate = useNavigate()

  const onPrompt = () => {
    setOpenIdleModal(true)
  }

  // this function is called when the user idle time limit is reached
  const onIdle = () => {
    setOpenIdleModal(false)
    handleLogOut()
  }

  // this function is called when the user resumes activity
  const onActive = () => {
    setOpenIdleModal(false)
    reset()
  }

  const { getRemainingTime, isPrompted, activate, reset } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
    crossTab: true,
    disabled: !isAuthenticated || isOpen,
  })

  const handleStillHere = () => {
    // setOpenIdleModal(false)
    activate()
    reset()
  }

  const handleLogOut = () => {
    useAuthStore.getState().clearAuthDataStorage()

    // const presentLocation = window.location.href
    // navigate(`/auth/login?redirect=${encodeURIComponent(presentLocation)}`)

    const referrer = localStorage.getItem("referrer")

    if (!!referrer) {
      window.location.href = referrer
    } else {
      openLoginModal()
    }

    reset()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  return (
    <CustomModal
      title="For your security!"
      closable={false}
      closeIcon={null}
      footer={
        <div className="w-full flex justify-end gap-4">
          <Button
            color="secondary"
            className="font-avenir"
            onClick={() => {
              const referrer = localStorage.getItem("referrer")
              useAuthStore.getState().clearAuthDataStorage()
              reset()
              if (!!referrer) {
                window.location.href = referrer
              } else {
                navigate(`/auth/login}`)
              }
            }}
            type="button"
          >
            Log out
          </Button>
          <Button
            color="primary"
            onClick={handleStillHere}
          >
            Stay connected
          </Button>
        </div>
      }
      open={openIdleModal && !isOpen}
      width={500}
      destroyOnClose={true}
    >
      <div className="">
        <P2 className="font-avenir mt-4">
          You will be signed out of your account due to inactivity.
          <br />
        </P2>
        <P2 className="font-avenir mt-2">
          Signing out in <span className="font-bold">{remaining}</span> seconds
        </P2>
      </div>
    </CustomModal>
  )
}

export default IdleTimer
