import React, { FC, ReactNode, useMemo } from "react"
import { formatCurrencyNoSymbol } from "utils/currency"
import {
  IEmployeePayrollItem,
  IPayrollItem,
  IPayrollJournalData,
} from "./DetailsSummary"

type Props = {
  data: IPayrollJournalData
  groupedEmployeeData: IEmployeePayrollItem[]
  groupPayPeriodsData: any[]
  user: any
  activeItem: any
}

interface IInformation {
  description: string
  type: "amortization" | "contribution" | "total"
}
/* -------------------------------------------------------------------------- */
/*  Payroll Item Types                                                        */
/*  1                                                                         */
/*  2                                                                         */
/*  3                                                                         */
/*  4                                                                         */
/*  5 - Employer Contributions                                                */
/* -------------------------------------------------------------------------- */

export const DetailsSummaryTable: FC<Props> = ({
  activeItem,
  data,
  groupPayPeriodsData,
  groupedEmployeeData,
  user,
}) => {
  const contributions: string[] = data?.contributions
    ? groupedEmployeeData?.[0]?.contributions
    : []
  const deductions: string[] = data?.deductions
    ? groupedEmployeeData?.[0]?.deductions?.concat(["Tax", "Total"])
    : []
  const employeeEarnings: string[] = data?.employeeEarnings
    ? groupedEmployeeData?.[0]?.employeeEarnings?.concat(["Total"])
    : []

  const amortizations: string[] = data?.payrollEarnings?.length
    ? Array.from(new Set(data?.payrollEarnings?.map((it) => it?.description)))
    : []

  const information: IInformation[] = contributions
    .map(
      (it) =>
        ({
          description: it,
          type: "contribution",
        } as IInformation)
    )
    .concat(
      amortizations.map(
        (it) =>
          ({
            description: it,
            type: "amortization",
          } as IInformation)
      )
    )
    ?.concat([
      {
        description: "Total",
        type: "total",
      },
    ])

  const employeeInformation: {
    key: string
    label: string
  }[] = [
    {
      key: "identification_number",
      label: "Emp No.",
    },
    {
      key: "full_name",
      label: "Full Name",
    },
    {
      key: "department",
      label: "Department",
    },
    {
      key: "office_display_name",
      label: "Workplace",
    },
    {
      key: "paygrade",
      label: "Paygrade",
    },
    {
      key: "hire_date",
      label: "Hire Date",
    },
    {
      key: "employee_title",
      label: "Job Title",
    },
  ]

  const currencies = Array.from(
    new Set(
      groupedEmployeeData
        ?.flatMap((it) => it?.groups)
        ?.map((it) => it?.currency_symbol)
    )
  )
  const items = useMemo(() => {
    const elements: any[] = []

    for (let i = 0; i < groupedEmployeeData.length; i++) {
      const employee: IEmployeePayrollItem = groupedEmployeeData[i]
      const groups: IPayrollItem[] = employee?.groups

      const activePayrollData = activeItem?.employeeData
        ?.map((v: any) => [...v?.groups])
        .flat()

      elements.push(
        <>
          <tr
            className={`${
              i % 2 == 0 && "bg-[#f8f9fa] "
            } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
          >
            {employeeInformation?.map(({ key }, idx) => {
              return (
                <td className="">
                  <span className="whitespace-nowrap p-3 text-start">
                    {/* @ts-ignore */}
                    {employee?.[key] ?? "-"}
                  </span>
                </td>
              )
            })}

            {data?.employeeEarnings &&
              employeeEarnings?.map((it, idx) => {
                return (
                  <>
                    {idx === employeeEarnings.length - 1 ? (
                      <td className="border-r-[#000] border-r-1 p-3">
                        {employee?.currency_symbol}
                        {formatCurrencyNoSymbol(
                          groups
                            ?.filter(
                              (g) =>
                                g?.payroll_item_type_id == 1 ||
                                g?.payroll_item_type_id == 3 ||
                                g?.payroll_item_type_id == 4
                            )
                            ?.map((g) => g?.amount)
                            ?.reduce((a, b) => a + b, 0) || 0
                        )}
                      </td>
                    ) : (
                      <td className="border-r-[#000] border-r-1 p-3">
                        {employee?.currency_symbol}
                        {formatCurrencyNoSymbol(
                          groups?.find((g: any) => g?.description == it)
                            ?.amount || 0
                        )}
                      </td>
                    )}
                  </>
                )
              })}
            {data?.deductions &&
              deductions?.map((it, idx) => {
                return (
                  <>
                    {idx === deductions.length - 1 ? (
                      <td className="border-r-[#000] border-r-1 p-3">
                        {employee?.currency_symbol}
                        {formatCurrencyNoSymbol(
                          (groups
                            ?.filter((g) => g?.payroll_item_type_id == 2)
                            ?.map((g) => g?.amount)
                            ?.reduce((a, b) => a + b, 0) || 0) +
                            groups
                              ?.filter(
                                (g) =>
                                  g?.payroll_item_type_id == 1 ||
                                  g?.payroll_item_type_id == 3 ||
                                  g?.payroll_item_type_id == 4
                              )
                              ?.map((g) => g?.tax_amount || 0)
                              ?.reduce((a, b) => a + b, 0) || 0
                        )}
                      </td>
                    ) : it === "Tax" ? (
                      <>
                        {(() => {
                          return (
                            <td className="border-r-[#000] border-r-1 p-3">
                              {employee?.currency_symbol}
                              {formatCurrencyNoSymbol(
                                groups
                                  ?.filter(
                                    (g) =>
                                      g?.payroll_item_type_id == 1 ||
                                      g?.payroll_item_type_id == 3 ||
                                      g?.payroll_item_type_id == 4
                                  )
                                  ?.map((g) => g?.tax_amount || 0)
                                  ?.reduce((a, b) => a + b, 0) || 0
                              )}
                            </td>
                          )
                        })()}
                      </>
                    ) : (
                      <td className="border-r-[#000] border-r-1 p-3">
                        {employee?.currency_symbol}
                        {formatCurrencyNoSymbol(
                          groups?.find((g) => g?.description == it)?.amount || 0
                        )}
                      </td>
                    )}
                  </>
                )
              })}
            {/* {data?.contributions &&
              contributions?.map((it, idx) => {
                return (
                  <>
                    {idx === contributions.length - 1 ? (
                      <td className="border-r-[#000] border-r-1 p-3">
                        {employee?.currency_symbol}
                        {formatCurrencyNoSymbol(
                          groups
                            ?.filter((g) => g?.payroll_item_type_id == 5)
                            ?.map((g) => g?.amount)
                            ?.reduce((a, b) => a + b, 0) || 0
                        )}
                      </td>
                    ) : (
                      <td className="border-r-[#000] border-r-1 p-3">
                        {employee?.currency_symbol}
                        {formatCurrencyNoSymbol(
                          groups?.find((g: any) => g?.description == it)
                            ?.amount || 0
                        )}
                      </td>
                    )}
                  </>
                )
              })} */}
            <td className="border-r-[#000] border-r-1 p-3">
              {employee?.currency_symbol}
              {personNetPay(employee)}
            </td>
            {information
              // ?.filter(
              //   (it) => it.type === "amortization" || it.type === "total"
              // )
              // .map((it) => it.description)
              .map((it, informationIndex, arr) => {
                const employeeAmortizations = data?.payrollEarnings?.filter(
                  (it) => it?.person_id === employee.person_id
                )

                const amount =
                  employeeAmortizations
                    ?.filter(
                      (g) =>
                        g?.description == it.description &&
                        it.type == "amortization"
                    )
                    ?.map((g) => g?.amount)
                    ?.reduce((a, b) => a + b, 0) ?? 0

                /**
                 *
                 *  <td className="border-r-[#000] border-r-1 p-3 whitespace-nowrap">
                        {employee?.currency_symbol}&nbsp;
                        {idx === amortizations.length - 1 ? (
                          <>{formatCurrencyNoSymbol(totalEmployeeAmount)}</>
                        ) : (
                          <>{formatCurrencyNoSymbol(amount)}</>
                        )}
                      </td>
                 */

                const totalEmployeeAmount =
                  employeeAmortizations
                    ?.map((g) => g?.amount)
                    ?.reduce((a, b) => a + b, 0) ?? 0

                const contributionsTotal =
                  groups
                    ?.filter((g) => g?.payroll_item_type_id == 5)
                    ?.map((g) => g?.amount)
                    ?.reduce((a, b) => a + b, 0) || 0

                const total = totalEmployeeAmount + contributionsTotal

                return informationIndex == information.length - 1 ? (
                  <td className="border-r-[#000] border-r-1 p-3">
                    {employee?.currency_symbol}&nbsp;
                    {formatCurrencyNoSymbol(total)}
                  </td>
                ) : it.type === "amortization" ? (
                  <td className="border-r-[#000] border-r-1 p-3">
                    {employee?.currency_symbol}
                    {formatCurrencyNoSymbol(amount)}
                  </td>
                ) : it.type === "contribution" ? (
                  <td className="border-r-[#000] border-r-1 p-3">
                    {employee?.currency_symbol}
                    {formatCurrencyNoSymbol(
                      groups?.find(
                        (g) =>
                          g?.description == it.description &&
                          it.type == "contribution"
                      )?.amount || 0
                    )}
                  </td>
                ) : (
                  false
                )
              })}
          </tr>
          {i === groupedEmployeeData.length - 1 && (
            <>
              {currencies?.map((currency, idx) => {
                return (
                  <tr
                    className={`${
                      idx % 2 == 0 && "bg-[#f8f9fa] "
                    } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
                  >
                    {idx === 0 ? (
                      <>
                        {employeeInformation?.map((it, index) => {
                          return index === 0 ? (
                            <td className="border-r-[#000] border-r-1 p-3">
                              Totals
                            </td>
                          ) : (
                            <td className="border-r-[#000] border-r-1 p-3"></td>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        {employeeInformation?.map((it, index) => {
                          return (
                            <td className="border-r-[#000] border-r-1 p-3"></td>
                          )
                        })}
                      </>
                    )}
                    {(() => {
                      return employeeEarnings?.map((it) => {
                        let total = 0
                        if (it == "Total") {
                          total =
                            groupedEmployeeData
                              ?.flatMap((it) => it?.groups)
                              ?.filter(
                                (g) =>
                                  (g?.payroll_item_type_id == 1 ||
                                    g.payroll_item_type_id == 3 ||
                                    g.payroll_item_type_id == 4) &&
                                  g.currency_symbol == currency
                              )
                              ?.map((x) => x.amount)
                              ?.reduce((a, b) => a + b, 0) || 0
                        } else {
                          total =
                            groupedEmployeeData
                              ?.flatMap((it) => it?.groups)
                              ?.filter(
                                (g) =>
                                  g?.description == it &&
                                  g.currency_symbol == currency &&
                                  (g?.payroll_item_type_id == 1 ||
                                    g?.payroll_item_type_id == 3 ||
                                    g?.payroll_item_type_id == 4)
                              )
                              ?.map((x) => x.amount)
                              ?.reduce((a, b) => a + b, 0) || 0
                        }
                        return (
                          <td className="border-r-[#000] border-r-1 p-3">
                            {currency}
                            {formatCurrencyNoSymbol(total)}
                          </td>
                        )
                      })
                    })()}
                    {(() => {
                      return deductions?.map((it) => {
                        let total = 0
                        if (it == "Total") {
                          total =
                            (groupedEmployeeData
                              ?.flatMap((it) => it?.groups)
                              ?.filter(
                                (g) =>
                                  g?.payroll_item_type_id == 2 &&
                                  g.currency_symbol == currency
                              )
                              ?.map((x) => x.amount)
                              ?.reduce((a, b) => a + b, 0) || 0) +
                              groupedEmployeeData
                                ?.flatMap((it) => it?.groups)
                                ?.filter(
                                  (g) =>
                                    (g?.payroll_item_type_id == 1 ||
                                      g.payroll_item_type_id == 3 ||
                                      g.payroll_item_type_id == 4) &&
                                    g.currency_symbol == currency
                                )
                                ?.map((x) => x.tax_amount ?? 0)
                                ?.reduce((a, b) => a + b, 0) || 0
                        } else if (it == "Tax") {
                          total =
                            groupedEmployeeData
                              ?.flatMap((it) => it?.groups)
                              ?.filter(
                                (g) =>
                                  (g?.payroll_item_type_id == 1 ||
                                    g.payroll_item_type_id == 3 ||
                                    g.payroll_item_type_id == 4) &&
                                  g.currency_symbol == currency
                              )
                              ?.map((x) => x.tax_amount ?? 0)
                              ?.reduce((a, b) => a + b, 0) || 0
                        } else {
                          total =
                            groupedEmployeeData
                              ?.flatMap((it) => it?.groups)
                              ?.filter(
                                (g) =>
                                  g?.description == it &&
                                  g?.payroll_item_type_id == 2 &&
                                  g.currency_symbol == currency
                              )
                              ?.map((x) => x.amount)
                              ?.reduce((a, b) => a + b, 0) || 0
                        }
                        return (
                          <td className="border-r-[#000] border-r-1 p-3">
                            {currency}
                            {formatCurrencyNoSymbol(total)}
                          </td>
                        )
                      })
                    })()}
                    {/* {(() => {
                      return contributions?.map((it) => {
                        let total = 0
                        if (it == "Total") {
                          total =
                            groupedEmployeeData
                              ?.flatMap((it) => it?.groups)
                              ?.filter(
                                (g) =>
                                  g?.payroll_item_type_id == 5 &&
                                  g.currency_symbol == currency
                              )
                              ?.map((x) => x.amount)
                              ?.reduce((a, b) => a + b, 0) || 0
                        } else {
                          total =
                            groupedEmployeeData
                              ?.flatMap((it) => it?.groups)
                              ?.filter(
                                (g) =>
                                  g?.description == it &&
                                  g.payroll_item_type_id == 5 &&
                                  g.currency_symbol == currency
                              )
                              ?.map((x) => x.amount)
                              ?.reduce((a, b) => a + b, 0) || 0
                        }

                        return (
                          <td className="border-r-[#000] border-r-1 p-3">
                            {currency}
                            {formatCurrencyNoSymbol(total)}
                          </td>
                        )
                      })
                    })()} */}
                    {(() => {
                      const items = (
                        activePayrollData as IPayrollItem[]
                      )?.filter((it) => it.currency_symbol == currency)
                      return (
                        <td className="border-r-[#000] border-r-1 p-3">
                          {currency}
                          {totalNetPay(items)}
                        </td>
                      )
                    })()}
                    {(() => {
                      return information?.map((it) => {
                        let total = 0
                        if (it.type == "total") {
                          const totalContribution =
                            groupedEmployeeData
                              ?.flatMap((it) => it?.groups)
                              ?.filter(
                                (g) =>
                                  g?.payroll_item_type_id == 5 &&
                                  g.currency_symbol == currency
                              )
                              ?.map((x) => x.amount)
                              ?.reduce((a, b) => a + b, 0) || 0
                          const totalAmortization =
                            data?.payrollEarnings
                              ?.filter((g) => g.currency_symbol == currency)
                              ?.map((x) => x.amount)
                              ?.reduce((a, b) => a + b, 0) || 0
                          total = totalContribution + totalAmortization
                        } else {
                          total =
                            it.type === "amortization"
                              ? data?.payrollEarnings

                                  ?.filter(
                                    (g) =>
                                      g?.description == it.description &&
                                      it.type === "amortization" &&
                                      g.currency_symbol == currency
                                  )
                                  ?.map((x) => x.amount)
                                  ?.reduce((a, b) => a + b, 0) || 0
                              : it.type === "contribution"
                              ? (total =
                                  groupedEmployeeData
                                    ?.flatMap((it) => it?.groups)
                                    ?.filter(
                                      (g) =>
                                        g?.description == it.description &&
                                        g.payroll_item_type_id == 5 &&
                                        g.currency_symbol == currency
                                    )
                                    ?.map((x) => x.amount)
                                    ?.reduce((a, b) => a + b, 0) || 0)
                              : 0
                        }

                        return (
                          <td className="border-r-[#000] border-r-1 p-3">
                            {currency}
                            {formatCurrencyNoSymbol(total)}
                          </td>
                        )
                      })
                    })()}
                  </tr>
                )
              })}
            </>
          )}
        </>
      )
    }

    return elements
  }, [groupedEmployeeData, data])

  return (
    <>
      <thead className="bg-gray-100 group">
        <tr className="box-border text-[rgba(0, 0, 0, 0.88)] font-[600] border-b-[#f0f0f0] border ">
          {employeeInformation?.map((it, idx) => {
            return idx === 0 ? (
              <th
                // colSpan={1}
                className="border-r-[#000]  text-start text-lg "
              >
                <span className="px-3 text-start text-lg whitespace-nowrap">
                  Employee Information
                </span>
              </th>
            ) : (
              <th
                // colSpan={1}
                className="border-r-[#000]  text-start text-lg"
              >
                <span className="px-3 text-start text-lg"></span>
              </th>
            )
          })}
          {/* <th
            colSpan={1}
            className="border-r-[#000]  text-start text-lg"
          >
            <span className="py-3 text-start text-lg">
              Employee Information
            </span>
          </th> */}
          {data?.employeeEarnings ? (
            <>
              {employeeEarnings?.map((it, idx) =>
                idx === 0 ? (
                  <th
                    colSpan={1}
                    className="border-r-[#000] text-start"
                  >
                    <span className="px-3 text-start text-lg">Earnings</span>
                  </th>
                ) : (
                  <th
                    colSpan={1}
                    className="border-r-[#000] text-start px-3"
                  >
                    <span className="text-start"></span>
                  </th>
                )
              )}
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 px-3 text-start"
            >
              <span className="">Total Earnings</span>
            </th>
          )}
          {data?.deductions ? (
            <>
              {deductions?.map((it, idx) =>
                idx === 0 ? (
                  <th
                    colSpan={1}
                    className="border-r-[#000] text-start px-3"
                  >
                    <span className="text-start text-lg">Deductions</span>
                  </th>
                ) : (
                  <th
                    colSpan={1}
                    className="border-r-[#000] text-start px-3"
                  >
                    <span className="text-start"></span>
                  </th>
                )
              )}
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 text-start"
            >
              <span className="px-3 text-start">Total Deductions</span>
            </th>
          )}
          {/* {data?.contributions && (
            <>
              {contributions?.map((it, idx) =>
                idx === 0 ? (
                  <th
                    colSpan={1}
                    className="border-r-[#000] text-start py-3 pe-3"
                  >
                    <span className="p-3 text-start text-lg">
                      Employer Contributions
                    </span>
                  </th>
                ) : (
                  <th
                    colSpan={1}
                    className="border-r-[#000] text-start py-3 pe-3"
                  >
                    <span className="py-3 text-start"></span>
                  </th>
                )
              )}
            </>
          )} */}

          {data?.totals ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="px-3 text-lg">Totals</span>
              </th>
              {/* <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="p-3"></span>
              </th> */}
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 text-start"
            >
              <span className="px-3">Net Pay</span>
            </th>
          )}
          <>
            {information?.map((it, idx) =>
              idx === 0 ? (
                <th
                  colSpan={1}
                  className="border-r-[#000]"
                >
                  <span className="px-3 text-start text-lg">Information</span>
                </th>
              ) : (
                <th
                  colSpan={1}
                  className="border-r-[#000] text-start px-3"
                >
                  <span className="text-start"></span>
                </th>
              )
            )}
          </>
        </tr>
        <tr className="">
          {/* {data?.customInfo && (
            <th className="border-r-[#000] border-r-1">klll</th>
          )} */}
          {employeeInformation?.map((it, idx) => {
            return (
              <th
                colSpan={1}
                className="border-r-[#000]  text-start px-3"
              >
                <span className="whitespace-nowrap">{it?.label}</span>
              </th>
            )
          })}

          {data?.employeeEarnings ? (
            <>
              {employeeEarnings?.map((it, idx) => (
                <>
                  <th className="text-left">
                    <span className="whitespace-nowrap px-3">{it}</span>
                  </th>
                </>
              ))}
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
          {data?.deductions ? (
            <>
              {deductions?.map((it, idx) => (
                <>
                  <th className="text-left">
                    <span className="whitespace-nowrap px-3">{it}</span>
                  </th>
                </>
              ))}
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
          {/* {data?.contributions && (
            <>
              {contributions?.map((it, idx) => (
                <>
                  <th className="">
                    <span className="whitespace-nowrap px-3">{it}</span>
                  </th>
                </>
              ))}
            </>
          )} */}
          {/* {data?.employeeTaxes ? (
            <>
              {deductions?.map((it, idx) => (
                <>
                  <th className="">
                    <span className="whitespace-nowrap">{it}</span>
                  </th>
                </>
              ))}
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )} */}
          {data?.totals ? (
            <>
              <th className="text-left">
                <span className="px-3">Net Pay</span>
              </th>
              {/* <th className="border-r-[#000] border-r-1 p-3">
                <span className="p-3">Amount</span>
              </th> */}
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}

          <>
            {information?.map((it) => (
              <th className="px-3 whitespace-nowrap text-left">
                <span className=" px-3">
                  {it.type === "amortization"
                    ? `${it.description} amortization`
                    : it.description}
                </span>
              </th>
            ))}
          </>
        </tr>
      </thead>
      <tbody>{items}</tbody>
    </>
  )
}

export const FormatADetailsSummaryTable: FC<Props> = ({
  activeItem,
  data,
  groupPayPeriodsData,
  groupedEmployeeData,
  user,
}) => {
  const amortizations: string[] = data?.payrollEarnings?.length
    ? Array.from(new Set(data?.payrollEarnings?.map((it) => it?.description)))
    : []
  const items = useMemo(() => {
    let elements: any[] = []

    let rowCount = 0
    for (let it of groupedEmployeeData) {
      rowCount++

      let dataCount = 1
      if (data?.customInfo) {
        const groupedData = [...it?.groups]

        const getUniqueItems = (arr: string[]) => {
          return Array.from(new Set(arr))
        }

        let count = 1
        if (data?.employeeEarnings) {
          const countForEarnings = Array.from(
            new Set(
              groupedData
                ?.filter(
                  (x: any) =>
                    x?.payroll_item_type_id == 1 ||
                    x?.payroll_item_type_id == 3 ||
                    x?.payroll_item_type_id == 4
                )
                ?.map((it: any) => it?.description)
            )
          )?.length

          count = Math.max(count, countForEarnings)
        }

        if (data?.deductions) {
          const countForDeductions = getUniqueItems(
            groupedData
              ?.filter((x: any) => x?.payroll_item_type_id == 2)
              ?.map((it: any) => it?.description)
          )?.length

          count = Math.max(count, countForDeductions)
        }

        if (data?.contributions) {
          const countForContributions = getUniqueItems(
            groupedData
              ?.filter((x: any) => x?.payroll_item_type_id == 5)
              ?.map((v: any) => v?.description)
          )?.length
          count = Math.max(count, countForContributions)
        }
        if (data?.payrollEarnings?.length) {
          const countforAmortization = amortizations?.length
          count = Math.max(count, countforAmortization)
        }

        if (data?.totals) {
          const countForTotals = 2
          count = Math.max(count, countForTotals)
        }
        dataCount = count
      }

      const employeeData = [
        "full_name",
        "employee_title",
        "department",
        "office_display_name",
        "days_worked",
      ]
      dataCount = Math.max(dataCount, employeeData.length)

      const finalCount = dataCount

      for (let i = 0; i < finalCount; i++) {
        const currencySymbol = it?.currency_symbol
        elements?.push(
          <>
            <tr
              className={`${
                rowCount % 2 == 0 && "bg-[#f8f9fa] "
              } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
            >
              {i == 0 && it?.full_name ? (
                <td className="border-r-[#000] border-r-1 p-3">
                  {it?.full_name}&nbsp;({it?.identification_number})
                </td>
              ) : i == 1 ? (
                <td className="border-r-[#000] border-r-1 p-3">
                  <div>{it?.employee_title}</div>
                </td>
              ) : i == 2 ? (
                <td className="border-r-[#000] border-r-1 p-3">
                  Department: <>{it?.department}</>
                </td>
              ) : i == 3 ? (
                <td className="border-r-[#000] border-r-1 p-3">
                  Workplace: <>{it?.office_display_name ?? "No Workplace"}</>
                </td>
              ) : i == 4 ? (
                <td className="border-r-[#000] border-r-1 p-3">
                  <div>
                    Days worked: {it?.days_worked}/{it?.total_days}
                  </div>
                </td>
              ) : (
                <td className="border-r-[#000] border-r-1 p-3"></td>
              )}
              {data?.employeeEarnings ? (
                <>
                  {(() => {
                    if (
                      it?.groups?.filter(
                        (i: any) =>
                          i.payroll_item_type_id == 1 ||
                          i.payroll_item_type_id == 3 ||
                          i.payroll_item_type_id == 4
                      )?.length > i
                    ) {
                      const descriptions: any[] = Array.from(
                        new Set(
                          it?.groups
                            ?.filter(
                              (it: any) =>
                                (it?.payroll_item_type_id == 1 ||
                                  it?.payroll_item_type_id == 3 ||
                                  it?.payroll_item_type_id == 4) &&
                                it?.description
                            )

                            ?.map((b: any) => b?.description)
                            ?.flat()
                        )
                      )

                      const amounts = (desc: string) =>
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              (it?.payroll_item_type_id == 1 ||
                                it?.payroll_item_type_id == 3 ||
                                it?.payroll_item_type_id == 4) &&
                              it?.description == desc
                          )

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

                      return (
                        <>
                          {descriptions[i] ? (
                            <>
                              <td>
                                <span>{descriptions[i] || "NA"}</span>
                              </td>

                              <td className="border-r-[#000] border-r-1 p-3">
                                {currencySymbol}
                                {formatCurrencyNoSymbol(
                                  amounts(descriptions[i])
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </>
                      )
                    }

                    return (
                      <>
                        <td></td>
                        <td className="border-r-[#000] border-r-1 p-3"></td>
                      </>
                    )
                  })()}
                </>
              ) : (
                <>
                  {i == 0 ? (
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 1 ||
                              it?.payroll_item_type_id == 3 ||
                              it?.payroll_item_type_id == 4
                          )

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  ) : (
                    <td className="border-r-[#000] border-r-1 p-3"></td>
                  )}
                </>
              )}
              {data?.deductions ? (
                <>
                  {(() => {
                    if (
                      it?.groups?.filter(
                        (i: any) => i.payroll_item_type_id == 2
                      )?.length > i
                    ) {
                      const descriptions: any[] = Array.from(
                        new Set(
                          it?.groups
                            ?.filter(
                              (it: any) =>
                                it?.payroll_item_type_id == 2 && it?.description
                            )

                            ?.map((b: any) => b?.description)
                        )
                      )

                      const amounts = (desc: string) =>
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 2 &&
                              it?.description == desc
                          )

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

                      return (
                        <>
                          {descriptions[i] ? (
                            <>
                              <td>
                                <span>{descriptions[i]}</span>
                              </td>

                              <td className="border-r-[#000] border-r-1 p-3">
                                {currencySymbol}
                                {formatCurrencyNoSymbol(
                                  amounts(descriptions[i])
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td className="border-r-[#000] border-r-1 p-3"></td>
                            </>
                          )}
                        </>
                      )
                    }
                    return (
                      <>
                        <td></td>
                        <td className="border-r-[#000] border-r-1 p-3"></td>
                      </>
                    )
                  })()}
                </>
              ) : (
                <>
                  {i == 0 ? (
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter((it: any) => it?.payroll_item_type_id == 2)

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  ) : (
                    <td className="border-r-[#000] border-r-1 p-3"></td>
                  )}
                </>
              )}
              {data?.contributions && (
                <>
                  {(() => {
                    if (
                      it?.groups?.filter(
                        (i: any) => i.payroll_item_type_id == 5
                      )?.length > i
                    ) {
                      const descriptions: any[] = Array.from(
                        new Set(
                          it?.groups
                            ?.filter(
                              (it: any) =>
                                it?.payroll_item_type_id == 5 && it?.description
                            )

                            ?.map((b: any) => b?.description)
                        )
                      )

                      const amounts = (desc: string) =>
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 5 &&
                              it?.description == desc
                          )

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

                      return (
                        <>
                          {descriptions[i] ? (
                            <>
                              <td>
                                <span>{descriptions[i]}</span>
                              </td>

                              <td className="border-r-[#000] border-r-1 p-3">
                                {currencySymbol}
                                {formatCurrencyNoSymbol(
                                  amounts(descriptions[i])
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </>
                      )
                    }
                    return (
                      <>
                        <td></td>
                        <td className="border-r-[#000] border-r-1 p-3"></td>
                      </>
                    )
                  })()}
                </>
              )}
              {data?.employeeTaxes ? (
                <>
                  {(() => {
                    if (
                      it?.groups?.filter(
                        (i: any) =>
                          i.payroll_item_type_id == 1 ||
                          i.payroll_item_type_id == 3 ||
                          i.payroll_item_type_id == 4
                      )?.length > i
                    ) {
                      const descriptions: any[] = Array.from(
                        new Set(
                          it?.groups
                            ?.filter(
                              (it: any) =>
                                (it?.payroll_item_type_id == 1 ||
                                  it?.payroll_item_type_id == 3 ||
                                  it?.payroll_item_type_id == 4) &&
                                it?.description
                            )

                            ?.map((b: any) => b?.description)
                            ?.flat()
                        )
                      )

                      const amounts = (desc: string) =>
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              (it?.payroll_item_type_id == 1 ||
                                it?.payroll_item_type_id == 3 ||
                                it?.payroll_item_type_id == 4) &&
                              it?.description == desc
                          )

                          ?.map((x: any) => x?.tax_amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

                      return (
                        <>
                          {descriptions[i] ? (
                            <>
                              <td>{descriptions[i] || "NA"}</td>

                              <td className="border-r-[#000] border-r-1 p-3">
                                {currencySymbol}
                                {formatCurrencyNoSymbol(
                                  amounts(descriptions[i])
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </>
                      )
                    }
                    return (
                      <>
                        <td></td>
                        <td className="border-r-[#000] border-r-1 p-3"></td>
                      </>
                    )
                  })()}
                </>
              ) : (
                <>
                  {i == 0 ? (
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 1 ||
                              it?.payroll_item_type_id == 3 ||
                              it?.payroll_item_type_id == 4
                          )

                          ?.map((x: any) => x?.tax_amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  ) : (
                    <td className="border-r-[#000] border-r-1"></td>
                  )}
                </>
              )}
              {data?.totals ? (
                <>
                  {(() => {
                    if (i == 0) {
                      return (
                        <>
                          <td>Net Pay</td>
                          <td className="border-r-[#000] border-r-1 p-3">
                            {currencySymbol}
                            {personNetPay(it)}
                          </td>
                        </>
                      )
                    }
                    if (i == 1) {
                      return (
                        <>
                          <td>Employer Cost</td>
                          <td className="border-r-[#000] border-r-1 p-3">
                            {currencySymbol}
                            {formatCurrencyNoSymbol(
                              it?.groups

                                ?.filter(
                                  (it: any) =>
                                    it?.payroll_item_type_id == 1 ||
                                    it?.payroll_item_type_id == 3 ||
                                    it?.payroll_item_type_id == 4
                                )

                                ?.map((x: any) =>
                                  x?.amount > 0 ? x?.amount : 0
                                )
                                ?.reduce(
                                  (a: any, b: any) => Number(a) + Number(b),
                                  0
                                ) -
                                it?.groups

                                  ?.filter(
                                    (it: any) => it?.payroll_item_type_id == 2
                                  )

                                  ?.map((x: any) =>
                                    x?.deducted_amount > 0
                                      ? x?.deducted_amount
                                      : 0
                                  )
                                  ?.reduce(
                                    (a: any, b: any) => Number(a) + Number(b),
                                    0
                                  ) +
                                it?.groups

                                  ?.filter(
                                    (it: any) =>
                                      (it?.payroll_item_type_id == 2 &&
                                        data?.statutoryBenefits
                                          ?.map((g: any) => g?.description)
                                          .includes(it?.description)) ||
                                      it?.description ==
                                        "Health Service Provider"
                                  )

                                  ?.map((x: any) =>
                                    x?.amount > 0 ? x?.amount : 0
                                  )
                                  ?.reduce(
                                    (a: any, b: any) => Number(a) + Number(b),
                                    0
                                  ) +
                                it?.groups

                                  ?.filter(
                                    (it: any) =>
                                      (it?.payroll_item_type_id == 5 &&
                                        data?.statutoryBenefits
                                          ?.map((g: any) => g?.description)
                                          .includes(it?.description)) ||
                                      it?.description?.includes(
                                        "Earned Wage Withdrawal"
                                      )
                                  )

                                  ?.map((x: any) =>
                                    x?.amount > 0 ? x?.amount : 0
                                  )
                                  ?.reduce(
                                    (a: any, b: any) => Number(a) + Number(b),
                                    0
                                  )
                            )}
                          </td>
                        </>
                      )
                    }

                    if (i > 1)
                      return (
                        <>
                          <td></td>
                          <td className="border-r-[#000] border-r-1"></td>
                        </>
                      )
                  })()}
                </>
              ) : (
                <>
                  {i == 0 ? (
                    <>
                      <td className="border-r-[#000] border-r-1 p-3">
                        {currencySymbol}
                        {personNetPay(it)}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border-r-[#000] border-r-1 p-3"></td>
                    </>
                  )}
                </>
              )}
              {data?.payrollEarnings?.length > 0 && (
                <>
                  {(() => {
                    if (
                      data?.payrollEarnings?.filter(
                        (i) => i.person_id == it?.person_id
                      )?.length > i
                    ) {
                      const descriptions: string[] = Array.from(
                        new Set(
                          data?.payrollEarnings
                            ?.filter((it) => it?.description)

                            ?.map((b) => b?.description)
                        )
                      )

                      const amounts = (desc: string) =>
                        data?.payrollEarnings

                          ?.filter((it) => it?.description == desc)

                          ?.map((x: any) => x?.amount)
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)

                      return (
                        <>
                          {descriptions[i] ? (
                            <>
                              <td>
                                <span>{descriptions[i]}</span>
                              </td>

                              <td className="border-r-[#000] border-r-1 p-3">
                                {currencySymbol}
                                {formatCurrencyNoSymbol(
                                  amounts(descriptions[i])
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </>
                      )
                    }
                    return (
                      <>
                        <td></td>
                        <td className="border-r-[#000] border-r-1 p-3"></td>
                      </>
                    )
                  })()}
                </>
              )}
            </tr>

            {data?.customInfo && i + 1 === finalCount ? (
              <tr
                className={`${
                  rowCount % 2 == 0 && "bg-[#f8f9fa] "
                } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
              >
                <td className="p-3"></td>
                {data?.employeeEarnings ? (
                  <>
                    <td>Total</td>
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 1 ||
                              it?.payroll_item_type_id == 3 ||
                              it?.payroll_item_type_id == 4
                          )

                          ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
                {data?.deductions ? (
                  <>
                    <td>Total</td>
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter((it: any) => it?.payroll_item_type_id == 2)

                          ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
                {data?.contributions ? (
                  <>
                    <td>Total</td>
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter((it: any) => it?.payroll_item_type_id == 5)

                          ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
                {data?.employeeTaxes ? (
                  <>
                    <td>Total</td>
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        it?.groups

                          ?.filter(
                            (it: any) =>
                              it?.payroll_item_type_id == 1 ||
                              it?.payroll_item_type_id == 3 ||
                              it?.payroll_item_type_id == 4
                          )

                          ?.map((x: any) =>
                            x?.tax_amount > 0 ? x?.tax_amount : 0
                          )
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
                {data?.totals ? (
                  <>
                    <td></td>
                    <td className="border-r-[#000] border-r-1 p-3"></td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
                {data?.payrollEarnings?.length > 0 ? (
                  <>
                    <td>Total</td>
                    <td className="border-r-[#000] border-r-1 p-3">
                      {currencySymbol}
                      {formatCurrencyNoSymbol(
                        data?.payrollEarnings

                          ?.filter((x) => it?.person_id == x.person_id)

                          ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                          ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                      )}
                    </td>
                  </>
                ) : (
                  <td className="border-r-[#000] border-r-1 p-3"></td>
                )}
              </tr>
            ) : (
              <></>
            )}
          </>
        )
      }
    }

    const activePayrollData = activeItem?.employeeData
      ?.map((v: any) => [...v?.groups])
      .flat()

    const currencySymbol = activePayrollData?.[0]?.currency_symbol

    const currencies: string[] = Array.from(
      new Set(activePayrollData?.map((it: any) => it?.currency_symbol))
    )

    currencies?.forEach((it, index) => {
      const currencySymbol = it
      elements.push(
        <>
          <tr
            className={`${
              rowCount % 2 == 0 && "bg-[#f8f9fa] "
            } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
          >
            {index === 0 ? (
              <td className="p-3">Payroll Totals</td>
            ) : (
              <td className="p-3"></td>
            )}

            {data?.employeeEarnings ? (
              <>
                <td>Total Earnings in {currencySymbol}</td>
                <td className="border-r-[#000] border-r-1 p-3">
                  {currencySymbol}
                  {formatCurrencyNoSymbol(
                    activePayrollData

                      ?.filter(
                        (it: any) =>
                          (it?.payroll_item_type_id == 1 ||
                            it?.payroll_item_type_id == 3 ||
                            it?.payroll_item_type_id == 4) &&
                          it.currency_symbol === currencySymbol
                      )

                      ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                      ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                  )}
                </td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  activePayrollData

                    ?.filter(
                      (it: any) =>
                        (it?.payroll_item_type_id == 1 ||
                          it?.payroll_item_type_id == 3 ||
                          it?.payroll_item_type_id == 4) &&
                        it.currency_symbol === currencySymbol
                    )

                    ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            )}
            {data?.deductions ? (
              <>
                <td>Total Deductions in {currencySymbol}</td>
                <td className="border-r-[#000] border-r-1 p-3">
                  {currencySymbol}
                  {formatCurrencyNoSymbol(
                    activePayrollData

                      ?.filter(
                        (it: any) =>
                          it?.payroll_item_type_id == 2 &&
                          it.currency_symbol === currencySymbol
                      )

                      ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                      ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                  )}
                </td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  activePayrollData

                    ?.filter(
                      (it: any) =>
                        it?.payroll_item_type_id == 2 &&
                        it.currency_symbol === currencySymbol
                    )

                    ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            )}
            {data?.contributions ? (
              <>
                <td>Total Contributions in {currencySymbol}</td>
                <td className="border-r-[#000] border-r-1 p-3">
                  {currencySymbol}
                  {formatCurrencyNoSymbol(
                    activePayrollData

                      ?.filter(
                        (it: any) =>
                          it?.payroll_item_type_id == 5 &&
                          it.currency_symbol === currencySymbol
                      )

                      ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                      ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                  )}
                </td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  activePayrollData

                    ?.filter(
                      (it: any) =>
                        it?.payroll_item_type_id == 5 &&
                        it.currency_symbol === currencySymbol
                    )

                    ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            )}
            {data?.employeeTaxes ? (
              <>
                <td>Total Tax in {currencySymbol}</td>
                <td className="border-r-[#000] border-r-1 p-3">
                  {currencySymbol}
                  {formatCurrencyNoSymbol(
                    activePayrollData

                      ?.filter(
                        (it: any) =>
                          (it?.payroll_item_type_id == 1 ||
                            it?.payroll_item_type_id == 3 ||
                            it?.payroll_item_type_id == 4) &&
                          it.currency_symbol === currencySymbol
                      )

                      ?.map((x: any) => (x?.tax_amount > 0 ? x?.tax_amount : 0))
                      ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                  )}
                </td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  activePayrollData

                    ?.filter(
                      (it: any) =>
                        (it?.payroll_item_type_id == 1 ||
                          it?.payroll_item_type_id == 3 ||
                          it?.payroll_item_type_id == 4) &&
                        it.currency_symbol === currencySymbol
                    )

                    ?.map((x: any) => (x?.tax_amount > 0 ? x?.tax_amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            )}
            {data?.totals ? (
              <>
                <td>Net Pay</td>
                <td className="border-r-[#000] border-r-1 p-3">
                  {currencySymbol}
                  {totalNetPay(
                    activePayrollData?.filter(
                      (it: any) => it.currency_symbol === currencySymbol
                    )
                  )}
                </td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3"></td>
            )}
            {data?.payrollEarnings?.length > 0 ? (
              <>
                <td>Total Information in {currencySymbol}</td>
                <td className="border-r-[#000] border-r-1 p-3">
                  {currencySymbol}
                  {formatCurrencyNoSymbol(
                    data?.payrollEarnings

                      ?.filter((it) => it.currency_symbol === currencySymbol)

                      ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                      ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                  )}
                </td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3">
                {currencySymbol}
                {formatCurrencyNoSymbol(
                  data?.payrollEarnings

                    ?.filter((it) => it.currency_symbol === currencySymbol)

                    ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                    ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                )}
              </td>
            )}
          </tr>
          <tr
            className={`${
              rowCount % 2 == 0 && "bg-[#f8f9fa] "
            } border-[#eff2f7] border-t-[1px] border-b-[1px]`}
          >
            <td className="p-3"></td>
            {data?.employeeEarnings ? (
              <>
                <td></td>
                <td className="border-r-[#000] border-r-1 p-3"></td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3"></td>
            )}
            {data?.deductions ? (
              <>
                <td></td>
                <td className="border-r-[#000] border-r-1 p-3"></td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3"></td>
            )}
            {data?.contributions ? (
              <>
                <td></td>
                <td className="border-r-[#000] border-r-1 p-3"></td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3"></td>
            )}
            {data?.employeeTaxes ? (
              <>
                <td></td>
                <td className="border-r-[#000] border-r-1 p-3"></td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3"></td>
            )}
            {data?.totals ? (
              <>
                <td>Employer Cost</td>
                <td className="border-r-[#000] border-r-1 p-3">
                  {currencySymbol}
                  {formatCurrencyNoSymbol(
                    activePayrollData

                      ?.filter(
                        (it: any) =>
                          it?.payroll_item_type_id == 1 ||
                          it?.payroll_item_type_id == 3 ||
                          it?.payroll_item_type_id == 4
                      )

                      ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                      ?.reduce((a: any, b: any) => Number(a) + Number(b), 0) -
                      activePayrollData

                        ?.filter(
                          (it: any) =>
                            it?.payroll_item_type_id == 2 &&
                            it.currency_symbol === currencySymbol
                        )

                        ?.map((x: any) =>
                          x?.deducted_amount > 0 ? x?.deducted_amount : 0
                        )
                        ?.reduce((a: any, b: any) => Number(a) + Number(b), 0) +
                      activePayrollData

                        ?.filter(
                          (it: any) =>
                            (it?.payroll_item_type_id == 2 &&
                              it.currency_symbol === currencySymbol &&
                              data?.statutoryBenefits
                                ?.map((x: any) => x?.description)
                                ?.includes(it?.description)) ||
                            it?.description == "Health Service Provider"
                        )

                        ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                        ?.reduce((a: any, b: any) => Number(a) + Number(b), 0) +
                      activePayrollData

                        ?.filter(
                          (it: any) =>
                            (it?.payroll_item_type_id == 5 &&
                              it.currency_symbol === currencySymbol &&
                              data?.statutoryBenefits
                                ?.map((x: any) => x?.description)
                                ?.includes(it?.description)) ||
                            it?.description?.includes("Earned Wage Withdrawal")
                        )

                        ?.map((x: any) => (x?.amount > 0 ? x?.amount : 0))
                        ?.reduce((a: any, b: any) => Number(a) + Number(b), 0)
                  )}
                </td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3"></td>
            )}
            {data?.payrollEarnings?.length > 0 ? (
              <>
                <td></td>
                <td className="border-r-[#000] border-r-1 p-3"></td>
              </>
            ) : (
              <td className="border-r-[#000] border-r-1 p-3"></td>
            )}
          </tr>
        </>
      )
    })

    return elements
  }, [groupedEmployeeData, data])

  /**
   * thead {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
  }
   */

  return (
    <>
      <thead className="bg-gray-100">
        <tr className="box-border p-2 text-[rgba(0, 0, 0, 0.88)] font-[600] border-b-[#f0f0f0] border !text-start">
          <th
            colSpan={1}
            className="border-r-[#000]  text-start"
          >
            <span className="py-3 text-start font-[16px]">
              Employee Information
            </span>
          </th>
          {data?.employeeEarnings ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] text-start py-3"
              >
                <span className="font-[16px]">Earnings</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] text-start"
              >
                <span className="p-3 text-start"></span>
              </th>
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 p-3 text-start"
            >
              <span>Total Earnings</span>
            </th>
          )}
          {data?.deductions ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3 text-start font-[16px]">Deductions</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="p-3 text-start"></span>
              </th>
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 text-start"
            >
              <span className="p-3 text-start">Total Deductions</span>
            </th>
          )}
          {data?.contributions && (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start py-3"
              >
                <span className="text-start font-[16px] whitespace-nowrap">
                  Employer Contributions
                </span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start p-3"
              >
                <span className="text-start"></span>
              </th>
            </>
          )}
          {data?.employeeTaxes ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3 font-[16px]">Tax</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="p-3"></span>
              </th>
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 text-start"
            >
              <span className="p-3 text-start">Total Tax</span>
            </th>
          )}
          {data?.totals ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3">Totals</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="p-3"></span>
              </th>
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 text-start"
            >
              <span className="p-3">Net Pay</span>
            </th>
          )}
          {data?.payrollEarnings?.length > 0 ? (
            <>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="py-3 text-start font-[16px]">Information</span>
              </th>
              <th
                colSpan={1}
                className="border-r-[#000] border-r-1 text-start"
              >
                <span className="p-3 text-start"></span>
              </th>
            </>
          ) : (
            <th
              colSpan={1}
              className="border-r-[#000] border-r-1 text-start"
            >
              <span className="p-3 text-start whitespace-nowrap">
                Total Information
              </span>
            </th>
          )}
        </tr>
        <tr className="box-border p-2 border-t-[2px]">
          {data?.customInfo && <th className="border-r-[#000] border-r-1"></th>}
          {data?.employeeEarnings ? (
            <>
              <th className="text-start">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 p-3 text-start">
                <span className="px-3">Amount</span>
              </th>
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
          {data?.deductions ? (
            <>
              <th className="text-start">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 p-3 text-start">
                <span className="px-3">Amount</span>
              </th>
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
          {data?.contributions && (
            <>
              <th className="text-start">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 p-3 text-start">
                <span className="px-3">Amount</span>
              </th>
            </>
          )}
          {data?.employeeTaxes ? (
            <>
              <th className="text-start">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 p-3 text-start">
                <span className="px-3">Amount</span>
              </th>
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
          {data?.totals ? (
            <>
              <th className="text-start">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 px-3 text-start">
                <span className="">Amount</span>
              </th>
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
          {data?.payrollEarnings?.length > 0 ? (
            <>
              <th className="text-start">
                <span className="">Description</span>
              </th>
              <th className="border-r-[#000] border-r-1 px-3 text-start">
                <span className="">Amount</span>
              </th>
            </>
          ) : (
            data?.customInfo && <th className="border-r-[#000] border-r-1"></th>
          )}
        </tr>
      </thead>
      <tbody className="">{items}</tbody>
    </>
  )
}

const personNetPay = (it: IEmployeePayrollItem) => {
  const peopleInDepartment = Array.from(
    new Set(it.groups?.map((v) => v?.person_id))
  )

  const afterTaxAmounts = it?.groups?.filter(
    (it) =>
      it?.payroll_item_type_id == 1 ||
      it?.payroll_item_type_id == 3 ||
      it?.payroll_item_type_id == 4
  )
  const deductionsAmounts = it?.groups?.filter(
    (it) => it?.payroll_item_type_id == 2
  )

  const finalArray: any[] = []

  for (let person of peopleInDepartment) {
    const afterTaxAmountForEmployee = afterTaxAmounts
      .filter((x) => x.person_id == person)
      ?.map((v) =>
        v?.after_tax_amount
          ? v?.after_tax_amount > 0
            ? v?.after_tax_amount
            : 0
          : 0
      )
      ?.reduce((a, b) => a + b, 0)
    const deductionAmountForEmployee = deductionsAmounts
      .filter((x: any) => x.person_id == person)
      ?.map((v: any) =>
        v?.deducted_amount
          ? v?.deducted_amount > 0
            ? v?.deducted_amount
            : 0
          : 0
      )
      ?.reduce((a: any, b: any) => a + b, 0)

    finalArray.push(
      afterTaxAmountForEmployee - deductionAmountForEmployee > 0
        ? afterTaxAmountForEmployee - deductionAmountForEmployee
        : 0
    )
  }

  return formatCurrencyNoSymbol(finalArray.reduce((a, b) => a + b, 0))
}
const totalNetPay = (it: any) => {
  const peopleInDepartment = Array.from(
    new Set(it?.map((v: any) => v?.person_id))
  )

  const afterTaxAmounts = it?.filter(
    (it: any) =>
      it?.payroll_item_type_id == 1 ||
      it?.payroll_item_type_id == 3 ||
      it?.payroll_item_type_id == 4
  )
  const deductionsAmounts = it?.filter(
    (it: any) => it?.payroll_item_type_id == 2
  )

  const finalArray: any[] = []

  for (let person of peopleInDepartment) {
    const afterTaxAmountForEmployee = afterTaxAmounts
      .filter((x: any) => x.person_id == person)
      ?.map((v: any) =>
        v?.after_tax_amount
          ? v?.after_tax_amount > 0
            ? v?.after_tax_amount
            : 0
          : 0
      )
      ?.reduce((a: any, b: any) => a + b, 0)
    const deductionAmountForEmployee = deductionsAmounts
      .filter((x: any) => x.person_id == person)
      ?.map((v: any) =>
        v?.deducted_amount
          ? v?.deducted_amount > 0
            ? v?.deducted_amount
            : 0
          : 0
      )
      ?.reduce((a: any, b: any) => a + b, 0)

    finalArray.push(
      afterTaxAmountForEmployee - deductionAmountForEmployee > 0
        ? afterTaxAmountForEmployee - deductionAmountForEmployee
        : 0
    )
  }

  return formatCurrencyNoSymbol(finalArray.reduce((a, b) => a + b, 0))
}
