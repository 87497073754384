import NotStarted from "assets/images/not_started.svg"
import OnTrack from "assets/images/on_track.svg"
import Achieved from "assets/images/achieved.svg"
import React, { useState } from "react"
import { useObjectivesStore } from "stores/performance/Objectives"

type measurementprops = {
  task: any
  index: number
  handleSuccess: Function
  creator: string
}
export const TaskBody: React.FC<measurementprops> = ({
  task,
  index,
  handleSuccess,
  creator,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false)

  const { updateKeyItemStatus } = useObjectivesStore()
  const statusIcon = UpdateStatus.find(
    (stat) => stat.id == Number(task.key_result_item_status_id)
  )
  const handleUpdateFunction = (status: number) => {
    updateKeyItemStatus({
      id: task.key_result_item_id,
      statusId: String(status),
    }).then((res) => {
      if (res.status == "success") {
        handleSuccess()
      }
    })
  }

  return (
    <div className="relative font-avenir">
      <div
        key={index}
        className=" flex justify-between items-center mb-4 border-2  rounded-5px  bg-white py-3 px-[6px]"
      >
        <div
          className=" flex  items-center"
          onClick={() => {
            setOpenDropdown(!openDropdown)
          }}
        >
          <img
            src={
              statusIcon?.icon ||
              "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
            }
            alt=""
            className="h-5 w-5"
          />
          <div className=" ml-2">
            <p className=" text-neutral500 text-sm">{task.description}</p>
          </div>
        </div>
        <div className="border flex items-center gap-1 border-neutral40 p-1 px-2  rounded-lg justify-center">
          <p className=" text-neutral500 text-xs">{creator}</p>
        </div>
      </div>
      {openDropdown && (
        <div className="w-[130px] absolute justify-between flex flex-col h-auto  rounded-5px border border-cardgrey p-1 left-[30px] -top-[150px] shadow-card bg-white">
          <p className=" text-neutral200 text-sm px-2  py-1 border-b border-cardgrey">
            Set status
          </p>
          {UpdateStatus.map((stat, index) => (
            <div
              key={index}
              className="flex-grow rounded-5px  flex justify-start px-2 py-[5px] items-center hover:bg-primary500 hover:text-white cursor-pointer text-sm text-neutral300"
              onClick={() => {
                handleUpdateFunction(stat.id)
                setOpenDropdown(false)
              }}
            >
              <div className="h-4 w-4 flex items-center justify-center mr-1">
                <img
                  src={stat.icon}
                  alt=""
                />
              </div>
              {stat.title}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export const UpdateStatus = [
  // {
  //   title: "Achieved",
  //   icon: Achieved,
  //   id: 4,
  // },
  {
    title: "Not started",
    icon: NotStarted,
    id: 1,
  },
  {
    title: "In Progress",
    icon: OnTrack,
    id: 2,
  },
  {
    title: "Completed",
    icon: Achieved,
    id: 3,
  },

  // {
  //   title: "Exceeded",
  //   icon: Measurement,
  //   id: 5,
  // },
  // {
  //   title: "Not Measured",
  //   icon: Measurement,
  //   id: 6,
  // },
]
