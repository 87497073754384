import { FC, useMemo } from "react"

import { useAuthStore } from "stores"
import {
  DetailsSummaryTable,
  FormatADetailsSummaryTable,
} from "./DetailsSummaryTable"
import { extend } from "lodash"

type Props = {
  data: IPayrollJournalData
  format: "compact" | "detailed"
}

export const DetailsSummary: FC<Props> = ({ data, format }) => {
  const { user } = useAuthStore()

  const payPeriods = useMemo(() => {
    const payrollItems: IPayrollItem[] = data?.payrollItems || []
    if (payrollItems?.length) {
      const grouped = payrollItems

      const payrollItemIds = Array.from(
        new Set(grouped.map((it) => it?.payroll_id))
      )

      let deductions = payrollItems
        ?.filter((it) => it?.payroll_item_type_id == 2)
        ?.map((it) => it?.description)
      let employeeEarnings = payrollItems
        ?.filter(
          (it) =>
            it?.payroll_item_type_id == 1 ||
            it?.payroll_item_type_id == 3 ||
            it?.payroll_item_type_id == 4
        )
        ?.map((it) => it?.description)
      let contributions = payrollItems
        ?.filter((it) => it?.payroll_item_type_id == 5)
        ?.map((it) => it?.description)

      const amortizations = data?.payrollEarnings || []
      let amortizationsDescriptions =
        amortizations?.map((it) => it?.description) || []

      if (deductions?.length) {
        deductions = Array.from(new Set(deductions))
      }
      if (contributions?.length) {
        contributions = Array.from(new Set(contributions))
      }
      if (employeeEarnings?.length) {
        employeeEarnings = Array.from(new Set(employeeEarnings))
      }

      if (amortizationsDescriptions?.length) {
        amortizationsDescriptions = Array.from(
          new Set(amortizationsDescriptions)
        )
      }

      const payperiodData: any[] = []
      for (let item of payrollItemIds) {
        const filtered = grouped
          ?.filter((it) => it?.payroll_id === item)
          .sort((a: any, b: any) =>
            a?.description?.localeCompare(b?.description)
          )

        payperiodData.push({
          payroll_item_type_id: item,
          pay_period: grouped?.find((v) => v?.payroll_id == item)?.pay_period,
          payroll_month: grouped?.find((v) => v?.payroll_id == item)
            ?.payroll_month,
          employeeData: (() => {
            const employeeData: any[] = []
            const employees = Array.from(
              new Set(grouped?.map((it) => it?.person_id))
            )

            for (let person of employees) {
              const filteredData = filtered?.filter(
                (it) => it?.person_id == person
              )
              employeeData.push({
                ...grouped?.find((it) => it?.person_id == person),
                groups: filteredData,
                deductions,
                employeeEarnings,
                contributions,
                amortizations: amortizationsDescriptions,
              })
            }

            return employeeData
          })(),
        })
      }

      return payperiodData
    } else {
      return []
    }
  }, [data])

  if (data?.payrollItems && data?.payrollItems?.length)
    return (
      <div className="flex flex-col gap-2 w-full font-avenir">
        <div className="w-full">
          <div className="text-lg text-neutral500 font-semibold">
            Employee Earnings
          </div>
          <div className="w-full">
            {payPeriods?.map((x) => (
              <div className="mt-[40px] w-full">
                {/* <div className="flex gap-2 items-center w-full my-3 period-title">
                  <div className="text-[16px] font-[700] text-neutral500">
                    {x?.payroll_month} Payroll
                    <br />
                  </div>
                  <div className="text-sm text-neutral500 font-semibold">
                    Payroll period: {x?.pay_period}
                  </div>
                </div> */}

                <table className="max-w-[100%] overflow-x-scroll">
                  <thead>
                    <tr>
                      <th className="text-[16px] font-[700] text-neutral500 whitespace-nowrap">
                        {x?.payroll_month} Payroll
                      </th>{" "}
                    </tr>
                    <tr className="py-2">
                      <th className="text-sm text-neutral500 font-semibold whitespace-nowrap">
                        Payroll period: {x?.pay_period}
                      </th>
                    </tr>
                  </thead>

                  {format === "detailed" ? (
                    <FormatADetailsSummaryTable
                      data={data}
                      groupPayPeriodsData={payPeriods}
                      groupedEmployeeData={x?.employeeData}
                      user={user}
                      activeItem={x}
                    />
                  ) : (
                    <DetailsSummaryTable
                      data={data}
                      groupPayPeriodsData={payPeriods}
                      groupedEmployeeData={x?.employeeData}
                      user={user}
                      activeItem={x}
                    />
                  )}
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    )

  return (
    <div className="max-w-full overflow-scroll">
      <div className="mt-[40px] w-full">
        <div className="text-lg text-neutral500 font-semibold">
          Payroll Journal Report
        </div>
      </div>
      <div className="w-full">
        <></>
      </div>
    </div>
  )
}

export interface IPayrollItem {
  payroll_item_key?: null
  payroll_item_id: number
  parent_payroll_item_id?: null
  payroll_key?: null
  payroll_id: number
  payroll_type_id?: null
  payroll_type?: null
  payroll_schedule_id?: null
  payroll_schedule?: null
  payroll_item_type_id: number
  payroll_item_type?: null
  payroll_status_id?: null
  payroll_status?: null
  payroll_item_status_id?: null
  payroll_item_status?: null
  entity_type_id?: null
  entity_type?: null
  paygrade_id: number
  paygrade: string
  annual_gross?: null
  employee_request_id?: null
  payroll_change_id?: null
  payment_reversal_id?: null
  description: string
  alias?: null
  person_key?: null
  person_id: number
  first_name: string
  middle_initial: string
  last_name: string
  full_name: string
  nick_name: string
  email?: null
  employee_title: string
  identification_number: string
  department: string
  office_address_id?: null
  office_address_line_1?: null
  office_address_line_2?: null
  office_address_city?: null
  office_address_state?: null
  office_address_zip_code?: null
  office_address_country?: null
  office_full_address?: null
  office_display_name?: null
  office_address_phone?: null
  tax_office_name?: null
  tax_office_code?: null
  home_address_line_1?: null
  home_address_line_2?: null
  home_address_city?: null
  home_address_state?: null
  home_address_zip_code?: null
  home_address_country?: null
  home_full_address?: null
  pfa_name?: null
  pfa_code?: null
  rsa_pin?: null
  hmo_name?: null
  hmo_code?: null
  hmo_plan?: null
  nhf_epn?: null
  total_days: number
  days_worked: number
  workplace: string
  actual_amount?: null
  amount: number
  is_taxable?: null
  tax_type_id?: null
  effective_tax_rate?: null
  tax_amount?: null
  after_tax_amount?: null
  deducted_amount: number
  cummulative_deducted_amount?: null
  avatar_url?: null
  pay_period: string
  start_date: string
  cut_off_date: string
  payroll_month: string
  pay_date: string
  payment_date?: null
  nextPaymentDate?: null
  nextPaymentDate_Short?: null
  isFuturePaymentDate?: null
  isTodayPaymentDate?: null
  is_deleted: boolean
  is_active: boolean
  dateRange?: null
  document_download_title?: null
  currency?: null
  currency_code?: null
  currency_symbol: string
}

export interface IEmployeePayrollItem extends IPayrollItem {
  groups: IPayrollItem[]
  deductions: string[]
  contributions: string[]
  employeeEarnings: string[]
  amortizations: string[]
}

export interface IPayrollAmortization {
  payroll_earning_key?: null
  payroll_earning_id: number
  payroll_id: number
  pay_period: string
  payroll_change_id: number
  amount: number
  tax_amount: number
  after_tax_amount: number
  currency_code?: null
  currency_symbol: string
  description: string
  person_id: number
  first_name: string
  middle_initial: string
  last_name: string
  full_name: string
  nick_name: string
  email?: null
  identification_number: string
  employee_title_id?: null
  employee_title: string
  department_id?: null
  department: string
  office_address_id: number
  office_display_name?: null
  avatar_url?: null
  is_deleted: boolean
  is_active: boolean
}

export interface IPayrollJournalData {
  range?: null
  grouping?: null
  report_date: string
  report_download_title: string
  payrollItems?: IPayrollItem[]
  payrollEarnings: PayrollEarningsEntity[]
  employeeBenefits?: null
  employeeRequests?: null
  employeeLeaveBalances?: null
  payrollChanges?: null
  payrollExemptions?: null
  customFields?: null
  employeeCustomFields?: null
  employeeSummary?: null
  officeAddress: OfficeAddress
  persons?: null
  homeAddresses?: null
  nextofKins?: null
  payrollReportSummaries?: null
  payrollReportSummaryValues?: null
  payrollReportSummaryAllowances?: null
  payrollReportSummaryEarnings?: null
  statutoryBenefits?: StatutoryBenefitsEntity[] | null
  reviewRound?: null
  paygradeReportSummaries?: null
  paygradeReportSummaryAllowances?: null
  paygradeReportSummaryStatutoryBenefits?: null
  paygradeReportSummaryTaxReliefs?: null
  paygradeReportSummaryValues?: null
  paybandReportSummaries?: null
  paybandReportSummaryAllowances?: null
  paybandReportSummaryStatutoryBenefits?: null
  paybandReportSummaryValues?: null
  pensionSchedules?: null
  taxSchedules?: null
  nhfSchedules?: null
  nsitfSchedules?: null
  itfSchedules?: null
  earnedWageWithdrawals?: null
  organisationAvatarFilename?: null
  organisationName?: null
  logoUploaded: boolean
  colspan: number
  qualifiedPayrollDate?: null
  payPeriod?: null
  payrollStatusId: number
  employeeEarnings: boolean
  employeeTaxes: boolean
  deductions: boolean
  contributions: boolean
  totals: boolean
  customInfo: boolean
  employeeTitle: boolean
  hireDate: boolean
  department: boolean
  location: boolean
  gender: boolean
  daysWorked: boolean
  pension: boolean
  nhis: boolean
  nhf: boolean
  lifeAssurance: boolean
  currentYear: number
  employeeNumber: boolean
  paygrade: boolean
  benefit?: null
  alias: boolean
  routineAnnualGross: boolean
  monthlyGross: boolean
  routineMonthlyGross: boolean
  annualNet: boolean
  routineAnnualNet: boolean
  routineMonthlyNet: boolean
  annualAllowances: boolean
  annualStatutoryBenefits: boolean
  annualTaxReliefs: boolean
  annualTax: boolean
  createdDate: boolean
  employeeRequestTypeId: number
  liability: boolean
  pensionReferenceNumber?: null
  taxIdentificationNumber?: null
  housingReferenceNumber?: null
  nsitfReferenceNumber?: null
  itfReferenceNumber?: null
  paymentScheduleItemStatusId: number
  scheduleDate?: null
}
export interface PayrollItemsEntity {
  payroll_item_key?: null
  payroll_item_id: number
  parent_payroll_item_id?: null
  payroll_key?: null
  payroll_id: number
  payroll_type_id?: null
  payroll_type?: null
  payroll_schedule_id?: null
  payroll_schedule?: null
  payroll_item_type_id: number
  payroll_item_type?: null
  payroll_status_id?: null
  payroll_status?: null
  payroll_item_status_id?: null
  payroll_item_status?: null
  entity_type_id?: null
  entity_type?: null
  paygrade_id: number
  paygrade: string
  annual_gross?: null
  employee_request_id?: null
  payroll_change_id?: null
  payment_reversal_id?: null
  description?: string | null
  alias?: string | null
  person_key?: null
  person_id: number
  first_name: string
  middle_initial?: string | null
  last_name: string
  full_name: string
  nick_name: string
  email?: null
  employee_title?: string | null
  identification_number: string
  department: string
  office_address_id?: number | null
  office_address_line_1?: null
  office_address_line_2?: null
  office_address_city?: null
  office_address_state?: null
  office_address_zip_code?: null
  office_address_country?: null
  office_full_address?: null
  office_display_name?: null
  office_address_phone?: string | null
  tax_office_name?: null
  tax_office_code?: null
  home_address_line_1?: null
  home_address_line_2?: null
  home_address_city?: null
  home_address_state?: null
  home_address_zip_code?: null
  home_address_country?: null
  home_full_address?: null
  pfa_name?: null
  pfa_code?: null
  rsa_pin?: null
  hmo_name?: null
  hmo_code?: null
  hmo_plan?: null
  nhf_epn?: null
  total_days: number
  days_worked: number
  actual_amount?: null
  amount: number
  is_taxable?: null
  tax_type_id?: number | null
  effective_tax_rate?: null
  tax_amount?: number | null
  after_tax_amount?: number | null
  deducted_amount?: number | null
  cummulative_deducted_amount?: null
  avatar_url?: null
  pay_period: string
  start_date: string
  cut_off_date: string
  payroll_month: string
  pay_date: string
  payment_date?: null
  nextPaymentDate?: null
  nextPaymentDate_Short?: null
  isFuturePaymentDate?: null
  isTodayPaymentDate?: null
  is_deleted: boolean
  is_active: boolean
  dateRange?: null
  document_download_title?: null
  currency?: null
  currency_code?: null
  currency_symbol: string
}
export interface PayrollEarningsEntity {
  payroll_earning_key?: null
  payroll_earning_id: number
  payroll_id: number
  pay_period: string
  payroll_change_id: number
  amount: number
  tax_amount: number
  after_tax_amount: number
  currency_code?: null
  currency_symbol: string
  description: string
  person_id: number
  first_name: string
  middle_initial: string
  last_name: string
  full_name: string
  nick_name: string
  email?: null
  identification_number: string
  employee_title_id?: null
  employee_title: string
  department_id?: null
  department: string
  office_address_id: number
  office_display_name?: null
  avatar_url?: null
  is_deleted: boolean
  is_active: boolean
}
export interface OfficeAddress {
  office_address_key?: null
  office_address_id: number
  address_line_1: string
  address_line_2?: null
  address_city: string
  address_state: string
  state_code: string
  country: string
  country_code: string
  zip_code: string
  phone: string
  display_name?: null
  full_address?: null
  tax_office_name?: null
  tax_office_code?: null
  housing_location_name?: null
  housing_location_code?: null
  tax_id?: null
  has_children?: null
  is_deleted: boolean
  is_active: boolean
}
export interface StatutoryBenefitsEntity {
  statutory_benefit_key?: null
  statutory_benefit_id: number
  statutory_benefit_type_id?: null
  description: string
  is_deleted: boolean
  is_active: boolean
}
