import React, { useEffect, useRef } from "react"
import { useGet } from "utils/useFetch"
import { useLocation } from "react-router-dom"
import { IPayslipDetailed } from "./interface/Payslip"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { Button } from "components"
import ReactToPrint from "react-to-print"
import DownloadComToPrint from "./component/DownloadComToPrint"
import { useAppNotificationStore, useAuthStore } from "stores"

const DownloadPayslip: React.FC<{}> = () => {
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const id = new URLSearchParams(myParam).get("id")
  const s = new URLSearchParams(myParam).get("s")
  const { signOut } = useAuthStore()
  const componentRef: any = useRef()

  const {
    isLoading: isPayslipLoading,
    data,
    isError,
  } = useGet<IPayslipDetailed>({
    url: `payroll_items/view/payslip?id=${id}&x=${x}`,
  })

  useEffect(() => {
    if (isError) {
      toast.error("Error! please log in")
      setTimeout(() => {
        signOut()
      }, 4000)
    }
  }, [isError])
  return (
    <div className="relative py-4 px-1 sm:px-4  ">
      <div className="flex items-center justify-between mb-8">
        <h6 className=" text-bluesubtext text-lg font-medium">
          Employee Payslip
        </h6>

        {componentRef && data && (
          <ReactToPrint
            trigger={() => (
              <Button
                color="primary"
                title="Download"
              >
                Download
              </Button>
            )}
            content={() => componentRef.current}
            documentTitle={`${data?.download_title}-payslips.pdf`}
          />
        )}
      </div>
      <>
        {
          isPayslipLoading ? (
            <div className="h-screen flex w-full justify-center items-center">
              <LoadingIndicatorWhite />
            </div>
          ) : data ? (
            <DownloadComToPrint
              ref={componentRef}
              data={data}
            />
          ) : (
            <div className="w-full  h-screen my-10 flex flex-col text-center justify-center items-center text-neutral500">
              <h6 className="text-neutral500 text-base">No available data</h6>
              <p className="text-sm text-neutral300">
                You will be redirected to the log in page, kindly log in
              </p>
            </div>
          )
          // <div
          //   className=" pb-40"
          //   id="report"
          //   // id="payslip"
          //   ref={componentRef}
          // >
          //   <PayslipTemplate data={data} />
          // </div>
        }
      </>
    </div>
  )
}
export default DownloadPayslip
