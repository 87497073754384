import { useMutation } from "@tanstack/react-query"
import { useState } from "react"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"
import { IConversationRoundItem } from "../interface"
import { DatePicker, Form, Input, Spin, Tooltip } from "antd"
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline"
import { Button } from "components"
import dayjs from "dayjs"
import { UpdateStatus } from "pages/employee-dashboard/performance/initiatives/TaskBody"
interface props {
  item: IConversationRoundItem
  refetch: () => void
}
const Action = ({ item, refetch }: props) => {
  const [form] = Form.useForm()

  const { toast } = useAppNotificationStore()
  const [openDropdown, setOpenDropdown] = useState(false)

  const [editActionId, setEditActionId] = useState("")

  const editAction = Form.useWatch("edit-action", form)
  const actionStart = Form.useWatch("action-start", form)
  const actionEnd = Form.useWatch("action-end", form)
  const [deleteActionId, setDeleteActionId] = useState(0)
  const { isLoading: isEditingAction, mutate: submitEditAction } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/employee/performance/edit_conversationround_action?id=${editActionId}`,
        body
      )
      return res.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data?.msg && toast.success(data?.msg)
        refetch()

        setEditActionId("")
      }
    },
  })
  const { isLoading: isUpdatingAction, mutate: submitUpdateAction } =
    useMutation({
      mutationFn: async (body: any) => {
        const res = await axiosInstance.post(
          `/employee/performance/update_conversationround_action?id=${body?.id}&statusId=${body?.statusId}`,
          body
        )
        return res.data
      },
      onSuccess: (data: any) => {
        if (data.status === "success") {
          data?.msg && toast.success(data?.msg)
          refetch()
          form.setFieldValue("edit-note", "")
          form.resetFields()
          setEditActionId("")
        }
      },
    })
  const { isLoading: isDeletingAction, mutate: DeleteAction } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/employee/performance/delete_conversationround_action?id=${deleteActionId}`
      )
      return res.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data?.msg && toast.success(data?.msg)
        refetch()
      }
    },
  })

  const handleUpdateFunction = (status: number) => {
    submitUpdateAction({
      id: item?.conversation_round_item_id,
      statusId: String(status),
    })
  }
  const statusIcon = UpdateStatus.find(
    (stat) => stat.id == Number(item?.conversation_round_item_status_id)
  )
  const format1 = "DD/MM/YYYY"
  return (
    <Form
      form={form}
      initialValues={{
        "edit-action": item?.description,
        "action-start": item?.start_date
          ? dayjs(item.start_date, "DD/MM/YYYY")
          : null,
        "action-end": item?.due_date
          ? dayjs(item.due_date, "DD/MM/YYYY")
          : null,
      }}
    >
      <div className="rounded  py-2.5 text-padeBlack font-medium font-circular text-sm flex justify-between items-center">
        {String(item?.conversation_round_item_id) === editActionId ? (
          <div className=" w-full">
            <div className="flex-grow grid grid-cols-2 gap-x-4">
              <Form.Item
                name="edit-action"
                className="col-span-2"
              >
                <Input.TextArea
                  rootClassName="!h-[50px]"
                  className="w-full "
                  placeholder="Edit action"
                  defaultValue={item?.description}
                />
              </Form.Item>
              <Form.Item name="action-start">
                <DatePicker
                  placeholder={"Start Date"}
                  className="w-full !text-sm text-padeBlack"
                  disabledDate={(date) => date.isBefore(dayjs(), "day")}
                />
              </Form.Item>

              <Form.Item name="action-end">
                <DatePicker
                  placeholder={"End Date"}
                  className="w-full !text-sm text-padeBlack"
                  disabledDate={(date) => {
                    const startDate = form.getFieldValue("action-start")
                    return startDate && date.isBefore(dayjs(startDate), "day")
                  }}
                />
              </Form.Item>
            </div>
            <div className="flex justify-end  items-center gap-2">
              <Button
                color="secondary"
                onClick={() => setEditActionId("")}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                submitting={isEditingAction}
                onClick={() => {
                  const format1 = "DD/MM/YYYY"

                  const startUpdated = actionStart
                    ? dayjs(actionStart).format(format1)
                    : null
                  const endUpdated = actionEnd
                    ? dayjs(actionEnd).format(format1)
                    : null
                  const body = {
                    description: editAction,
                    start_date: startUpdated,
                    due_date: endUpdated,
                  }

                  submitEditAction(body)
                }}
              >
                Update
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className=" flex relative ">
              <Tooltip title={item?.conversation_round_item_status}>
                <button
                  className="mr-3"
                  onClick={() => {
                    setOpenDropdown(!openDropdown)
                  }}
                >
                  <img
                    src={statusIcon?.icon || ""}
                    alt=""
                    className="h-5 w-5"
                  />
                </button>
              </Tooltip>
              <div>
                <p className="text-padeBlack text-sm"> {item?.description}</p>
                <p className="text-[#9B9DA1] text-xs font-circular font-light">
                  {item?.start_date_display}
                  {"-"}
                  {item?.due_date_display}
                </p>
              </div>
              {openDropdown && (
                <div className="w-[130px] absolute justify-between flex flex-col h-auto  rounded-5px border border-cardgrey p-1 left-[30px] -top-[150px] shadow-card bg-white">
                  <p className=" text-neutral200 text-sm px-2  py-1 border-b border-cardgrey">
                    Set Status
                  </p>
                  {UpdateStatus.map((stat, index) => (
                    <div
                      key={index}
                      className="flex-grow rounded-5px  flex justify-start px-2 py-[5px] items-center hover:bg-primary500 hover:text-white cursor-pointer text-sm text-neutral300"
                      onClick={() => {
                        handleUpdateFunction(stat.id)
                        setOpenDropdown(false)
                      }}
                    >
                      <div className="h-4 w-4 flex items-center justify-center mr-1">
                        <img
                          src={stat.icon}
                          alt=""
                        />
                      </div>
                      {stat.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={() => {
                  setEditActionId(String(item?.conversation_round_item_id))
                }}
              >
                <PencilIcon className="h-4 w-4" />
              </button>{" "}
              <button
                onClick={() => {
                  const body = {
                    id: String(item?.conversation_round_item_id),
                  }
                  setDeleteActionId(item?.conversation_round_item_id)
                  DeleteAction(body)
                }}
              >
                {item?.conversation_round_item_id === deleteActionId &&
                isDeletingAction ? (
                  <Spin spinning />
                ) : (
                  <TrashIcon className="h-4 w-4" />
                )}
              </button>{" "}
            </div>
          </>
        )}
      </div>
    </Form>
  )
}

export default Action
