import { CloseOutlined, LoadingOutlined } from "@ant-design/icons"
import { DatePicker, Drawer, Form, Input, Spin, Tag } from "antd"
import { useGet } from "utils/useFetch"
import { IViewConversationRound } from "./interface"
import {
  CalendarIcon,
  ChevronUpIcon,
  ClockIcon,
  PlusIcon,
} from "@heroicons/react/24/outline"

import dayjs from "dayjs"
import { axiosInstance } from "utils/request"
import { useMutation } from "@tanstack/react-query"
import { useAppNotificationStore } from "stores"
import { useState } from "react"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import Action from "./component/Action"
import Note from "./component/Note"

interface prop {
  onClose: (e: boolean) => void
  open: boolean
  ViewInfo: string
}
const ViewRounds = ({ onClose, open, ViewInfo }: prop) => {
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const action = Form.useWatch("action", form)
  const [note, setNote] = useState("")

  const actionStart = Form.useWatch("action_start", form)
  const actionEnd = Form.useWatch("action_end", form)
  const [isActive, setIsActive] = useState("")

  const { isLoading, data, refetch } = useGet<IViewConversationRound>({
    url: `/employee/performance/view/conversation?id=${ViewInfo}`,
    enabled: (() => {
      if (ViewInfo) {
        return true
      } else {
        return false
      }
    })(),
    onSuccess: (data: any) => {},
  })
  const { isLoading: isLoadingActionAdd, mutate: submitAction } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/employee/performance/add_conversationround_action?id=${String(
          data?.conversation_id
        )}`,
        body
      )
      return res.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data?.msg && toast.success(data?.msg)
        form.resetFields()
        refetch()
      } else {
        data?.msg && toast.error(data?.msg)
      }
    },
    onError: (data: any) => {
      data?.msg && toast.error(data?.msg)
    },
  })

  const { isLoading: isLoadingNote, mutate: submitNote } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/employee/performance/add_conversationround_note?id=${String(
          data?.conversation_id
        )}`,
        body
      )
      return res.data
    },
    onSuccess: (data: any) => {
      if (data.status === "success") {
        data?.msg && toast.success(data?.msg)
        refetch()
        form.setFieldValue("note", "")
        form.resetFields()
        setNote("")
      } else {
        data?.msg && toast.error(data?.msg)
      }
    },
    onError: (data: any) => {
      data?.msg && toast.error(data?.msg)
    },
  })

  const onFinish = () => {
    const format1 = "DD/MM/YYYY"
    const startUpdated = actionStart ? dayjs(actionStart).format(format1) : null
    const endUpdated = actionEnd ? dayjs(actionEnd).format(format1) : null

    const body = {
      description: action,
      start_date: startUpdated,
      due_date: endUpdated,
    }

    submitAction(body)
  }
  const onFinishNote = () => {
    const body = {
      note,
    }

    submitNote(body)
  }
  return (
    <Drawer
      width={540}
      title={
        <span className=" text-padeBlack font-circular text-xl">
          {data?.conversation_type} Conversation with {data?.target_full_name}
        </span>
      }
      open={open}
      // onClose={() => }
      closeIcon={false}
      className="bg-white relative"
      extra={
        <button onClick={() => onClose(false)}>
          <CloseOutlined className="h-6 w-6" />
        </button>
      }
    >
      {isLoading ? (
        <div className="flex h-full w-full justify-center items-center">
          <Spin />
        </div>
      ) : (
        <div className=" flex flex-col">
          {/* DOWNLOAD */}
          <div className=" pb-4 rounded flex items-center justify-between">
            <p className=" text-padeBlack font-circular text-sm flex items-center">
              <CalendarIcon className="h-4 w-4 mr-1" />
              <span className="text-[#9B9DA1] mr-2">Date: </span>
              {data?.start_date_display}
            </p>
            <p className=" text-padeBlack font-circular text-sm flex items-center">
              <ClockIcon className="h-4 w-4 mr-1" />
              <span className="text-[#9B9DA1] mr-2"> Time: </span>
              {data?.start_time} {data?.start_time_meridian} - {data?.end_time}{" "}
              {data?.end_time_meridian}
            </p>
            <Tag
              color={
                data?.conversation_round_status_id == 2 ? "red" : "default"
              }
              className="ml-[10px]"
            >
              {data?.conversation_round_status}
            </Tag>
          </div>
          <div className=" bg-[#6C7A9314] py-5 px-6">
            <h6 className="text-padeBlack text-lg mb-6 gap-6 font-bold">
              Prompts for Discussion
            </h6>
            <div className=" flex flex-col gap-6">
              {data?.prompts.map((item, index) => (
                <div
                  key={index}
                  className=" flex items-start gap-[10px] "
                >
                  <p className=" font-circular text-padeBlack bg-padeLightBlue  rounded-full px-1 text-center  text-primary500 text-sm">
                    {index + 1}
                  </p>
                  <div>
                    <p className=" font-circular text-padeBlack font-medium text-[15px]">
                      {item?.description}
                    </p>
                    <p className=" font-circular text-[#0C1B3D] font-normal text-sm">
                      {item?.alias}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* ACTION */}
          <div className="mt-6 bg-white p-5 border border-[#6C7A9333]">
            <button
              className=" flex items-center justify-between w-full"
              onClick={() =>
                isActive === "actions"
                  ? setIsActive("")
                  : setIsActive("actions")
              }
            >
              <h6 className="text-padeBlack text-base   font-bold">Actions</h6>
              {isActive === "actions" ? (
                <ChevronUpIcon className="h-5 w-5 text-padeBlack font-bold" />
              ) : (
                <ChevronDownIcon className="h-5 w-5  text-padeBlack font-bold" />
              )}
            </button>
            {isActive === "actions" && (
              <>
                <div className=" mt-6 flex flex-col gap-3">
                  {data?.items
                    ?.filter((it) => it.conversation_round_item_type_id === 1)
                    .map((item, index) => (
                      <Action
                        item={item}
                        refetch={refetch}
                      />
                    ))}
                </div>
                <Form
                  form={form}
                  className=" mt-6 flex items-center gap-[14px]"
                  onFinish={onFinish}
                >
                  <div className="flex-grow grid grid-cols-2 gap-x-4">
                    <Form.Item
                      name="action"
                      className="col-span-2"
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <Input
                        className="w-full "
                        placeholder="Add New Action"
                      />
                    </Form.Item>

                    <Form.Item
                      name="action_start"
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder={" Start Date"}
                        className="w-full !text-sm"
                        disabledDate={(date) => {
                          return date.isBefore(dayjs(), "day")
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="action_end"
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder={"End Date"}
                        className="w-full !text-sm"
                        disabledDate={(date) => {
                          const startDate = form.getFieldValue("action_start")
                          return date.isBefore(dayjs(startDate), "day")
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="flex justify-end">
                    <button className="w-10 h-10 bg-primary500 rounded-5px text-center flex justify-center items-center">
                      {isLoadingActionAdd ? (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 24, color: "white" }}
                              spin
                            />
                          }
                        />
                      ) : (
                        <PlusIcon className="w-6 h-6 font-bold text-white" />
                      )}{" "}
                    </button>
                  </div>
                </Form>
              </>
            )}
          </div>
          {/* NOTES */}
          <div className="mt-6 bg-white p-5 border border-[#6C7A9333]">
            <button
              className="  flex items-center justify-between w-full"
              onClick={() =>
                isActive === "notes" ? setIsActive("") : setIsActive("notes")
              }
            >
              <h6 className="text-padeBlack text-base   font-bold">Notes</h6>
              {isActive === "notes" ? (
                <ChevronUpIcon className="h-5 w-5 text-padeBlack font-bold" />
              ) : (
                <ChevronDownIcon className="h-5 w-5  text-padeBlack font-bold" />
              )}
            </button>
            {isActive === "notes" && (
              <>
                <div className=" mt-6 flex flex-col gap-3">
                  {data?.items
                    ?.filter((it) => it.conversation_round_item_type_id === 2)
                    .map((item, index) => (
                      <Note
                        item={item}
                        refetch={refetch}
                      />
                    ))}
                </div>
                <Form
                  form={form}
                  className="mt-6 flex items-center gap-[14px]"
                  onFinish={onFinishNote}
                >
                  <div className="flex-grow grid grid-cols-2 gap-x-4">
                    <Form.Item
                      name="note"
                      className="col-span-2"
                      rules={[
                        {
                          required: true,
                          message: "Please enter this field!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        rootClassName="!h-[90px]"
                        className="w-full "
                        placeholder="Add new note"
                        onChange={(val) => setNote(val.target.value)}
                      />
                    </Form.Item>
                  </div>
                  <div className="flex justify-end">
                    <button className="w-10 h-10 bg-primary500 rounded-5px text-center flex justify-center items-center">
                      {isLoadingNote ? (
                        <Spin className="text-white" />
                      ) : (
                        <PlusIcon className="w-6 h-6 font-bold text-white" />
                      )}{" "}
                    </button>
                  </div>
                </Form>
              </>
            )}
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default ViewRounds
