import React, { useState } from "react"
import totalEmpAnalytics from "assets/svg/totalEmpAnalytics.svg"
import enrolledEmpAnalytics from "assets/svg/enrolledEmpAnalytics.svg"
import withdrawalEmpAnalytics from "assets/svg/withdrawalEmpAnalytics.svg"

import { Dropdown, Select, Spin, Table } from "antd"
import { formatCurrencyNoSymbol } from "utils/currency"
import {
  EllipsisHorizontalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"

import { useGet } from "utils/useFetch"
import { newBaseUrl } from "utils/newbaseurl"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { StatsCard } from "../documents/company-details/Cards"
import CustomCard from "components/new-stuff/ui/CustomCard"
import Barchart from "components/charts/Barchart"
import { useNavigate } from "react-router-dom"
import { IAnalytics } from "./interface/analytics"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import EWANotEnabled from "./EWANotEnabled"

const Analytics: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [statusId, setStatusId] = useState("week")
  const { isLoading: isWithdrawalsLoading, data: withdrawalsData } =
    useGet<any>({
      url: `${newBaseUrl}/v1/employer/withdrawals?populate=employee`,
      external: true,
    })
  const { isLoading: isAnalyticsLoading, data: analyticsData } =
    useGet<IAnalytics>({
      url: `${newBaseUrl}/v1/employer/withdrawals/analytics?period=${statusId}`,
      external: true,
      onSuccess: (data: any) => {
        mixPanelEvent("ewa-analytics-listing")
      },
    })
  const TableData =
    withdrawalsData?.data?.docs
      .slice(0, 5)
      ?.filter((emp: any) => {
        const name = `${emp?.employee?.firstName}`
        return name?.toLowerCase().includes(search?.toLowerCase())
      })
      ?.map((transaction: any) => {
        return {
          key: transaction?._id,
          date: {
            date: formatDateReadable(String(transaction?.createdAt)),
            time: formatTimeReadble(String(transaction?.createdAt)),
          },
          type: transaction.channel,
          amount: `₦ ${formatCurrencyNoSymbol(
            Number(transaction?.amountToPayBack)
          )}`,
          employee: {
            name: `${transaction?.employee?.firstName} ${transaction?.employee?.lastName}`,
          },
          idNumber: transaction?.employee?.employeeId,
          charge: `₦ ${formatCurrencyNoSymbol(Number(transaction?.fee))}`,
          reference: transaction.reference,
          status: {
            id: transaction.status,
            title: transaction.status,
          },
          action: {
            key: transaction?._id,
            id: transaction?._id,
            status: transaction.status,
          },
        }
      }) || []
  const TableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Employee",
        dataIndex: "employee",
        render: (employee: { name: string }) => (
          <div className="flex items-center gap-2">
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]`}
            >
              {employee.name}
            </span>
          </div>
        ),
      },
      {
        title: "ID Number",
        dataIndex: "idNumber",
        render: (idNumber: string) => (
          <div className="flex items-center gap-2">
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]`}
            >
              {idNumber}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (amount: string) => (
          <span
            className={`whitespace-nowrap  font-avenir text-sm text-[#42526D] `}
          >
            {amount}{" "}
          </span>
        ),
      },

      {
        title: "Status",
        dataIndex: "status",
        render: (status: { id: string; title: any; method: string }) => (
          <span
            className={`text-sm font-medium py-1 block text-center w-[80px] lowercase rounded-[4px]
                    ${
                      status?.title == "APPROVED"
                        ? "bg-success100 text-success800"
                        : ""
                    }
                    ${
                      status?.title == "PAID"
                        ? "bg-success100 text-success800"
                        : ""
                    }

                    ${
                      status?.title == "PROCESSING"
                        ? "bg-[#FEE3B1] text-[#E69702]"
                        : ""
                    }
                    ${
                      status?.title == "PENDING"
                        ? "bg-[#FEE3B1] text-[#E69702]"
                        : ""
                    }
                    ${status?.title == "DECLINED" ? "bg-neutral40 " : ""}
                    ${status?.title == "FAILED" ? "bg-neutral40 " : ""}
                    ${status?.title == "CUTOFF" ? "bg-neutral40 " : ""}

                    `}
          >
            {status?.title}
          </span>
        ),
      },
    ] || []

  const cardData = [
    {
      icon: totalEmpAnalytics,
      title: "Total Employees",
      value: analyticsData?.data?.totalEmployees || 0,
      id: "ewa-analytics-total-employees",
    },
    {
      icon: enrolledEmpAnalytics,
      title: "Registered employees",
      value: analyticsData?.data?.totalEnrolledEmployees || 0,
      id: "ewa-analytics-registered-employees",
    },
    {
      icon: withdrawalEmpAnalytics,
      title: "Withdrawal this month",
      value: `₦ ${formatCurrencyNoSymbol(
        Number(analyticsData?.data?.totalWithdrawalAmountForCurrentMonth || 0)
      )}`,
      id: "ewa-analytics-withdrawals",
    },
    {
      icon: withdrawalEmpAnalytics,
      title: "Total Amount withdrawn",
      value: `₦ ${formatCurrencyNoSymbol(
        Number(analyticsData?.data?.totalWithdrawalAmountForAllTime || 0)
      )}`,
      id: "ewa-analytics-total-withdrawals",
    },
  ]

  return (
    <DashboardWrapper>
      {isWithdrawalsLoading || isAnalyticsLoading ? (
        <div className="flex justify-center w-full h-screen items-center">
          <Spin />
        </div>
      ) : withdrawalsData || analyticsData ? (
        <div className="flex font-avenir flex-col  gap-6">
          <div>
            <h5 className="text-lg font-bold text-neutral700 mb-4  ">
              Analytics
            </h5>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  gap-8 ">
              {cardData.map((card, idx) => (
                <StatsCard
                  key={idx}
                  image={card.icon}
                  title={card.title}
                  text={card.value}
                  id={card.id}
                />
              ))}
            </div>
          </div>
          <CustomCard
            loading={false}
            id="ewa-analytics-withdrawals-chart"
          >
            <div className="flex justify-between items-center mb-4">
              <h6 className="text-neutral700 font-bold text-base">
                Withdrawal
              </h6>
              <Select
                defaultValue="week"
                placeholder="Filter by"
                onChange={(value) => {
                  setStatusId(value)
                }}
                options={[
                  { value: "year", label: "From 12 months" },
                  { value: "week", label: "From 7 days" },
                  { value: "month", label: "From 30 Days" },
                ]}
                rootClassName="font-avenir  text-neutral500 "
              />
            </div>
            <Barchart
              currency="#"
              radius="8"
              thick={20}
              labels={
                statusId == "year"
                  ? analyticsData?.data?.periodStats.map((stat) =>
                      String(stat?.month)
                    )
                  : analyticsData?.data?.periodStats.map((stat) =>
                      String(stat?.day)
                    ) || []
              }
              datasets={[
                {
                  // label: "# of Votes",
                  data: analyticsData?.data?.periodStats.map((stat) =>
                    String(stat?.totalAmount)
                  ),
                  backgroundColor: ["#93B8FA"],
                  borderColor: ["#93B8FA"],
                  borderWidth: 1,
                  borderRadius: 10,
                  barThickness: 20,
                },
              ]}
            />
          </CustomCard>
          <div
            id={"ewa-analytics-recent-transactions"}
            className="bg-white p-4  rounded-5px"
          >
            <div className="flex justify-between items-center mb-4">
              <h6 className="text-neutral700 font-bold text-base">
                Recent transaction
              </h6>
              <div className="flex  items-center gap-4">
                <div className="hidden md:flex border-2  h-[36px]  rounded-5px w-[268px]   items-center px-2 bg-white ">
                  <MagnifyingGlassIcon className=" text-neutral80 w-4" />
                  <input
                    placeholder="Search"
                    className="bg-none w-full px-2 outline-none  text-sm !h-full "
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value)
                    }}
                  />
                </div>
                <button
                  className="text-primary500 font-semibold"
                  onClick={() => navigate("/dashboard/ewa/withdrawals")}
                >
                  See more
                </button>
              </div>
            </div>
            <div className="border border-cardgrey my-5"></div>
            <Table
              columns={TableColumns}
              dataSource={TableData}
              loading={isWithdrawalsLoading}
              className="text-[#42526D]"
              pagination={{
                defaultPageSize: 20,
              }}
              scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
            />
          </div>
        </div>
      ) : (
        <EWANotEnabled />
      )}
    </DashboardWrapper>
  )
}
export default Analytics
