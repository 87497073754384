import { Button, Label } from "components"
import React, { useState } from "react"
import { Select, Form, Alert, Radio, Tooltip } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Link } from "react-router-dom"
// import EnterPin from "../pin/EnterPin"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAuthStore } from "stores"
import AntToggle from "components/inputs/AntToggle"
import { useGet, usePost } from "utils/useFetch"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import SegmentWrapper from "components/custom/SegmentWrapper"
import { AllBeneficiaryType, DataItem } from "./SingleSend"
import { ICountry } from "pages/employee-dashboard/typings"
interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<any[]>>
  // currentLevel: null | KycLevel
  // x?: string | null
  handleProceed: () => void
  // found: any | undefined
  currencyCode: string
  isActive: boolean
  setActive: any
  // availableBalance: number | undefined
}
const BenBankDetails = ({
  currencyCode,
  isActive,
  setActive,
  setPreviewData,
  handleProceed,
}: Props) => {
  const [form] = Form.useForm()
  const { user } = useAuthStore()

  const EmployeeWalletID = user?.userWalletId
  const beneficiaryType = Form.useWatch("beneficiaryType", form)
  const transferMethod = Form.useWatch("transferMethod", form) ?? "local"

  const [saveBeneficiary, setSaveBeneficiary] = useState(false)
  const { isLoading: isLoadingCountries, data: countries } = useGet<ICountry[]>(
    {
      url: `/commonutils/getcountriesbycurrencycode?code=${currencyCode}`,
    }
  )

  const { isLoading: isLoadingAllCountries, data: allCountries } = usePost<
    ICountry[]
  >({
    url: "/commonutils/getcountries",
  })
  // Purpose TYPES
  const { isLoading: isLoadingPurposes, data: purposeRes } = useQuery<any>({
    queryKey: [`transfer-purposes`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/transfer-purposes`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
  })
  const isIntlCurrency = ["USD", "GBP", "CAD", "DKK", "AUD"].includes(
    currencyCode
  )

  const onFinish = (values: any) => {
    const {
      accountNumber,
      bankCode,
      beneficiaryCountryISO,
      beneficiaryType,
      beneficiaryFirstName,
      beneficiaryLastName,
      beneficiaryCompanyName,
      addbeneficiary,
      purpose,
    } = values

    let transferMethodSelected: string
    if (transferMethod === "local" && currencyCode === "USD") {
      transferMethodSelected = "AbaCode"
    } else if (
      transferMethod === "international" ||
      (transferMethod === "local" && !["USD", "GBP"].includes(currencyCode))
    ) {
      transferMethodSelected = "SwiftCode"
    } else if (transferMethod === "local" && currencyCode === "GBP") {
      transferMethodSelected = "SortCode"
    } else if (transferMethod === "local" && currencyCode === "INR") {
      transferMethodSelected = "IfcCode"
    } else {
      transferMethodSelected = "BankCode"
    }
    setPreviewData((prevData) => [
      {
        ...prevData[0],
        amount: prevData[0]?.amount,
        description: prevData[0]?.description,
        paymentMethodType: transferMethodSelected,
        accountNumber,
        bankCode,
        beneficiaryCountryISO,
        beneficiaryType,
        beneficiaryFirstName,
        beneficiaryLastName,
        beneficiaryCompanyName,
        addToBeneficiary: saveBeneficiary,
        purposeId: String(purpose),
      },
    ])

    handleProceed()
  }
  return (
    <SegmentWrapper
      title="Bank Details"
      subtitle="Please provide us with the bank details of the beneficiary"
      headerClassName=" xl:!px-[40px]"
      bodyClassName="!pb-0"
      isActive={isActive}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          transferMethod: "local",
        }}
      >
        <div className="w-full   bg-white  rounded-md ">
          <div className="px-4 md:px-6 lg:px-8 xl:px-[40px] flex flex-col lg:grid grid-cols-2 gap-x-6 ">
            {isIntlCurrency && (
              <div className="col-span-2">
                <Form.Item
                  label={
                    <span className="font-circular text-padeBlack text-sm  ">
                      Payment method
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter this field!",
                    },
                  ]}
                  name="transferMethod"
                >
                  <Radio.Group defaultValue="local">
                    <Radio
                      value="local"
                      // className="flex items-center"
                    >
                      {" "}
                      <span className="flex items-center">
                        Local Bank{" "}
                        <Tooltip title="The beneficiary’s bank is located in domestic country of the currency. E.g US Dollar being sent to the USA.">
                          {" "}
                          <InformationCircleIcon className="h-4 w-4 ml-2" />{" "}
                        </Tooltip>
                      </span>{" "}
                    </Radio>
                    <Radio value="international">
                      {" "}
                      <span className="flex items-center">
                        International Bank Transfer
                        <Tooltip title="The beneficiary’s bank is located outside the domestic country of the currency. E.g US Dollar being sent to Nigeria.">
                          {" "}
                          <InformationCircleIcon className="h-4 w-4 ml-2" />{" "}
                        </Tooltip>
                      </span>{" "}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}

            {/* BENEFICIARY TYPES */}
            <div className="col-span-2">
              <Form.Item
                label={
                  <span className="font-circular text-padeBlack text-sm  ">
                    Beneficiary type
                  </span>
                }
                name="beneficiaryType"
                rules={[
                  {
                    required: true,
                    message: "Please enter this field!",
                  },
                ]}
              >
                <Select
                  showSearch
                  options={AllBeneficiaryType?.map((item) => ({
                    label: item?.label,
                    value: item?.value,
                  }))}
                  filterOption={(input, option) => {
                    if (option) {
                      const value = (option?.label as string) || ""
                      return (
                        value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      )
                    } else {
                      return false
                    }
                  }}
                  placeholder="Select"
                />
              </Form.Item>
            </div>
            {/* BENEFICIARY INDIVIDUAL INFO */}
            {beneficiaryType === "individual" && (
              <>
                <CustomInput
                  label="First name"
                  name="beneficiaryFirstName"
                  required
                />
                <CustomInput
                  label="Last name"
                  name="beneficiaryLastName"
                  required
                />
              </>
            )}
            {/* BENEFICIARY COMPANY NAME */}
            {beneficiaryType === "company" && (
              <CustomInput
                label="Company name"
                name="beneficiaryCompanyName"
                required
              />
            )}

            {/*ACCOUNT NUMBER  */}

            {!["EUR"].includes(currencyCode) && transferMethod === "local" ? (
              <CustomInput
                label={"Account number"}
                name="accountNumber"
                required
              />
            ) : null}

            {transferMethod === "international" ||
            (transferMethod === "local" && ["EUR"].includes(currencyCode)) ? (
              <CustomInput
                label="Account number/IBAN"
                name="accountNumber"
                required
              />
            ) : null}

            {/* BANK CODES */}
            {transferMethod === "local" && currencyCode === "USD" && (
              <div>
                <CustomInput
                  label="ABA Code/Routing number"
                  name="bankCode"
                  required
                />
              </div>
            )}
            {transferMethod === "international" ||
            (transferMethod === "local" &&
              !["USD", "GBP"].includes(currencyCode)) ? (
              <CustomInput
                label="Swift code"
                name="bankCode"
                required
              />
            ) : null}

            {transferMethod === "local" && currencyCode === "GBP" && (
              <CustomInput
                label="Sort code"
                name="bankCode"
                required
              />
            )}
            {transferMethod === "local" && currencyCode === "INR" && (
              <CustomInput
                label="IFCS"
                name="bankCode"
                required
              />
            )}

            {/* // COUNTRYISO */}
            <Form.Item
              label={
                <span className="text-padeBlack text-sm font-circular  ">
                  {" "}
                  Country
                </span>
              }
              name="beneficiaryCountryISO"
              rules={[
                {
                  required: true,
                  message: "Please enter this field!",
                },
              ]}
            >
              <Select
                showSearch
                loading={isLoadingCountries || isLoadingAllCountries}
                options={
                  transferMethod === "international"
                    ? allCountries?.map((item) => ({
                        label: item?.description,
                        value: item?.alpha2Code,
                      }))
                    : countries?.map((item) => ({
                        label: item?.description,
                        value: item?.alpha2Code,
                      }))
                }
                filterOption={(input, option) => {
                  if (option) {
                    const value = (option?.label as string) || ""
                    return (
                      value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    )
                  } else {
                    return false
                  }
                }}
                placeholder="Select country"
              />
            </Form.Item>
            {/*Purpose*/}
            <div>
              <Form.Item
                label={
                  <span className="font-circular text-padeBlack text-sm  ">
                    Purpose
                  </span>
                }
                name="purpose"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter this field!",
                //   },
                // ]}
              >
                <Select
                  showSearch
                  options={purposeRes?.data?.map((item: DataItem) => ({
                    label: item.title,
                    value: item.id,
                  }))}
                  loading={isLoadingPurposes}
                  filterOption={(input, option) => {
                    if (option) {
                      const value = (option?.label as string) || ""
                      return (
                        value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                      )
                    } else {
                      return false
                    }
                  }}
                  placeholder="Select"
                />
              </Form.Item>
            </div>
            <div className=" col-span-2">
              <Alert
                message={""}
                type="warning"
                showIcon
                description={
                  "Please double-check your payment details before proceeding. We won't be liable for any losses due to incorrect information."
                }
                className="invoice-alert font-avenir text-neutral500 text-sm w-full items-center mb-6 rounded-none"
              />
            </div>
            <div className=" flex  items-center gap-2 mb-6">
              <Label>Save beneficiary</Label>
              <AntToggle
                name="addbeneficiary"
                label=""
                checked={saveBeneficiary}
                onChange={(e: any) => {
                  setSaveBeneficiary(!saveBeneficiary)
                }}
              />
            </div>
          </div>
          <div className="flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link to="/employee/dashboard/wallet/cross-border/overview">
              <Button
                color="secondary"
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  setActive(0)
                }}
              >
                Previous
              </Button>
            </Link>

            <Button
              color="primary"
              submitting={false}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Confirm{" "}
            </Button>
          </div>
        </div>
      </Form>
    </SegmentWrapper>
  )
}

export default BenBankDetails
