import SurveyLayout from "components/new-stuff/layout/survey/SurveyLayout"
import React from "react"
import { Navigate, Route } from "react-router-dom"

const OAuth = React.lazy(() => import("pages/OAuth/OAuth"))

const OAuthRoutes = () => {
  return (
    <Route
      path="/sso"
      element={<OAuth />}
    />
  )
}

export default OAuthRoutes
