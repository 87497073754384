import { Button } from "components"
import React from "react"
import { Select, Form, Alert, InputNumber, Spin } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Link, useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
// import EnterPin from "../pin/EnterPin"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { KycLevel } from "pages/employee-dashboard/wallet/wallet/kyc/interface"
import { availableFlags } from "utils/flags"
import Global from "assets/svg/countries/Global.svg"
import { usePost } from "utils/useFetch"
import { ICurrencies } from "pages/dashboard/wallets/integration/interface/kyc"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { LoadingOutlined } from "@ant-design/icons"
import { DivideByRate } from "utils/rate"

interface Props {
  setPreviewData: React.Dispatch<React.SetStateAction<any[]>>
  currentLevel: null | KycLevel
  x?: string | null
  handleProceed: () => void
  found: any | undefined
  currencyUpdated: string
  currencyCode: string
  availableBalance: number | undefined
  setCurrencyUpdated: any
  setUpdSymbol: any
  updSymbol: any
}

const SingleSend = ({
  setPreviewData,
  currentLevel,
  handleProceed,
  x,
  found,
  currencyUpdated,
  availableBalance,
  setCurrencyUpdated,
  setUpdSymbol,
  updSymbol,
  currencyCode,
}: Props) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { user } = useAuthStore()

  const { toast } = useAppNotificationStore()

  const currSelected = Form.useWatch("currency_type", form)
  const inputAmount = Form.useWatch("amount", form)

  const USDElement = availableFlags?.find((item) => item?.code === "USD")
  const USDSymbol = USDElement?.symbol

  const { isLoading: isCurrencyLoading, data: currencies } = usePost<
    ICurrencies[]
  >({
    url: "/commonutils/getcurrencies",
    body: {
      q: "",
      page: "",
    },
  })
  const {
    isLoading: isLoadingRate,
    data: rateData,
    mutate: getRate,
  } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/conversion-rate`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })
  const rateValue = rateData?.data?.rate

  const onFinish = (values: any) => {
    const { amount, description } = values

    setPreviewData((prevData) => [
      {
        ...prevData[0],
        amountconverted: rateData ? DivideByRate(amount, rateValue) : amount,
        amount,
        currency: currSelected,
        description,
      },
    ])

    handleProceed()
  }

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <>
          <div className="mt-5 px-4 md:px-6 lg:px-8 xl:px-[40px] flex flex-col lg:grid grid-cols-2 gap-x-6">
            <div className="">
              <Form.Item
                label={
                  <span className="font-circular text-padeBlack text-sm">
                    Select currency{" "}
                  </span>
                }
                name="currency_type"
                rules={[
                  { required: true, message: "Please enter this field!" },
                ]}
                extra={
                  currSelected !== undefined && isLoadingRate ? (
                    <Spin
                      spinning={true}
                      indicator={
                        <LoadingOutlined
                          style={{ color: "blue", fontSize: 16 }}
                          spin
                        />
                      }
                    />
                  ) : (
                    currencyCode === "USD" &&
                    currSelected !== undefined &&
                    currSelected !== "USD" &&
                    rateData && (
                      <span className="text-padeBlack  font-medium">
                        {USDSymbol}1 = {updSymbol}
                        {formatCurrencyNoSymbol(Number(rateValue || 0), {
                          minimumFractionDigits: 7,
                          maximumFractionDigits: 7,
                        })}
                      </span>
                    )
                  )
                }
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  loading={isCurrencyLoading}
                  onChange={(e) => {
                    const currency = currencies?.find((item) => item?.code == e)
                    setCurrencyUpdated(e)
                    setUpdSymbol(currency?.symbol)
                    const body: any = {
                      sourceCurrency: currencyCode,
                      destinationCurrency: e,
                    }
                    getRate(body)
                    form.setFieldValue("amount", "")
                  }}
                  options={[
                    ...(currencyCode === "USD"
                      ? currencies ?? []
                      : (currencies ?? []).filter(
                          (curr) => curr.code === currencyCode
                        )
                    ).map((curr) => ({
                      label: (
                        <span
                          title={curr?.description}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <img
                              src={curr?.flagUrl || Global}
                              alt={curr?.code}
                              style={{
                                width: "18px",
                                height: "18px",
                                marginRight: "8px",
                                marginLeft: "8px",
                              }}
                            />
                            <span>{curr.code}</span>
                          </div>
                        </span>
                      ),
                      value: curr?.code,
                    })),
                    {
                      label: "",
                      value: null,
                      disabled: true,
                    },
                  ]}
                />
              </Form.Item>
            </div>
            {/* AMOUNT */}
            <div className="">
              <Form.Item
                name="amount"
                label={
                  <span className="text-padeBlack text-sm font-circular  ">
                    {" "}
                    Enter amount
                  </span>
                }
                required
                extra={
                  availableBalance && (
                    <span>
                      Balance:{" "}
                      <span className="font-medium  text-padeBlack ">
                        {found?.symbol}
                        {formatCurrencyNoSymbol(availableBalance)}
                      </span>
                    </span>
                  )
                }
                rules={[
                  // {
                  //   validator: (_, value) => {
                  //     const limit =
                  //       currentLevel && currentLevel.level > 2
                  //         ? Number.MAX_SAFE_INTEGER
                  //         : 200000
                  //     if (!value || value <= limit) {
                  //       return Promise.resolve()
                  //     }
                  //     return Promise.reject(
                  //       new Error(
                  //         `You can only send a maximum of ${formatCurrencyNoSymbol(
                  //           limit
                  //         )} to other banks, kindly upgrade your account `
                  //       )
                  //     )
                  //   },
                  // },
                  {
                    validator: (_, value) => {
                      const minimumAmount = 5
                      if (!value || value >= minimumAmount) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          `Amount must be at least  ${found?.symbol} ${minimumAmount}`
                        )
                      )
                    },
                  },
                ]}
              >
                <InputNumber<number>
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) =>
                    value?.replace(/,/g, "") as unknown as number
                  }
                  className="w-full font-circular text-neutral400"
                  addonBefore={updSymbol}
                  placeholder="e.g 200"
                  // onBlur={() => {
                  //   const body: any = {
                  //     sourceCurrency: currencyCode,
                  //     destinationCurrency: currSelected,
                  //   }
                  //   getRate(body)
                  // }}
                />
              </Form.Item>
            </div>
            {/* {rateData && ( */}
            {currencyCode === "USD" &&
              currSelected !== undefined &&
              currSelected !== "USD" &&
              rateData &&
              inputAmount && (
                <div className=" col-span-2">
                  <Alert
                    type="warning"
                    showIcon
                    icon={
                      <InformationCircleIcon className="h-4 w-4 lg:h-6 lg:w-6 !text-primary500" />
                    }
                    message={`${USDSymbol}
${DivideByRate(inputAmount, rateValue)}
                     will be debited from your  USD Balance`}
                    className="bg-[#CCDBFD] font-circular text-padeBlack text-sm w-full items-center mb-6 border-[#CCDBFD] !rounded-none"
                  />
                </div>
              )}
            {/* )} */}
            {/* DESCRIPTION */}
            <div className="w-full col-span-2">
              <CustomInput
                label="Payment description"
                name="description"
                className="text-padeBlack"
              />{" "}
            </div>
          </div>

          <div className="flex justify-end w-full gap-6 px-4 md:px-6 lg:px-8 xl:px-[40px]  py-3 bg-white border-t shadow-footer  items-center">
            <Link to="/employee/dashboard/wallet/cross-border/overview">
              <Button
                title="Previous"
                color="secondary"
              >
                Cancel
              </Button>
            </Link>

            <Button
              title="Next"
              color="primary"
              submitting={false}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Confirm{" "}
            </Button>
          </div>
        </>
      </Form>
    </>
  )
}

export default SingleSend
export const PaymentMethods = [
  {
    value: "RoutingNumber",
    label: "Routing Number",
  },
  {
    value: "SortCode",
    label: "Sort Code",
  },
  {
    value: "BankCode",
    label: "Bank Code",
  },
  {
    value: "AbaCode",
    label: "Aba Code",
  },
  {
    value: "BicCode",
    label: "Bic Code",
  },
  {
    value: "SwiftCode",
    label: "Swift Code",
  },
  {
    value: "IfcCode",
    label: "Ifc Code",
  },
]
export const AllBeneficiaryType = [
  {
    value: "individual",
    label: "Individual",
  },
  {
    value: "company",
    label: "Company",
  },
]
export interface IPurposeResponse {
  success: boolean
  code: number
  message: string
  data: DataItem[]
}

export interface DataItem {
  id: number
  title: string
}
