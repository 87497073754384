import { Form, Spin } from "antd"
import { Button, DashboardContainer, H5, P2 } from "components"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CustomInput, CustomSelect } from "../custom-form"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAppNotificationStore } from "stores"
import { usePost } from "utils/useFetch"
import { IState, ICountry } from "pages/dashboard/people/people/typings"
import { axiosInstance } from "utils/request"
import { IOfficeAddress } from "./typings"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import getIdFromKey from "utils/getIdFromKey"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"

const EditWorkplaceForm = () => {
  const queryClient = useQueryClient()
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const [alpha2Code, setAlpha2Code] = useState<string>("NG")
  const [stateCode, setStateCode] = useState<string>("")
  const navigate = useNavigate()
  const params = useParams()
  const id = getIdFromKey(params.id as string)

  const reqBody = {
    q: "",
    page: "",
  }

  const { isLoading: isAddressesLoading, data: addresses } = usePost<
    IOfficeAddress[]
  >({
    url: "/admin/company/getofficeaddresses?canAdd=true",
    body: reqBody,
    onSuccess: (data: IOfficeAddress[]) => {
      const address = data?.find(
        (item) => item.officeAddressId.toString() === id
      )

      setStateCode(address?.stateCode || "")
      setAlpha2Code(address?.countryCode || "")
    },
  })

  const { isLoading: isCountriesLoading, data: countries } = usePost<
    ICountry[]
  >({
    url: "/commonutils/getcountries",
    body: reqBody,
  })

  const {
    isLoading: isStatesLoading,
    data: states,
    refetch,
  } = usePost<IState[]>({
    url: `/commonutils/getstatesbycountrycode?id=${alpha2Code}`,
    body: reqBody,
    enabled: !!alpha2Code,
  })

  const {
    isLoading: isTaxOfficesLoading,
    data: taxOffices,
    refetch: refetchTaxOffices,
  } = usePost<
    {
      description: string
      code: string
    }[]
  >({
    url: `/admin/company/gettaxofficesbystatecode?id=${stateCode}`,
    body: reqBody,
    enabled: !!stateCode,
  })

  const {
    isLoading: isHousingLocationsLoading,
    data: housingLocations,
    refetch: refetchHousingLocationsOffices,
  } = usePost<
    {
      description: string
      code: string
    }[]
  >({
    url: `/admin/company/gethousinglocationsbystatecode?id=${stateCode}`,
    enabled: !!stateCode,
  })

  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        `/admin/company/update_officeaddress`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        queryClient.invalidateQueries({
          queryKey: [
            // "admin/company/view/details",
            "/admin/company/getofficeaddresses?canAdd=true",
          ],
        })
        navigate("/dashboard/company-details/manage-workplace")
        mixPanelEvent("company-edit-workplace-success")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const { isLoading: isEnablingAddress, mutate: enableAddress } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/company/enable_officeaddress?id=${id}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["/admin/company/getofficeaddresses?canAdd=true"],
        })
        navigate("/dashboard/company-details/manage-workplace")
      } else {
        toast.error(data.msg)
      }
    },
  })

  const { isLoading: isDisablingAddress, mutate: disableAddress } = useMutation(
    {
      mutationFn: async () => {
        const res = await axiosInstance.post(
          `/admin/company/disable_officeaddress?id=${id}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status === "success") {
          queryClient.invalidateQueries({
            queryKey: ["/admin/company/getofficeaddresses?canAdd=true"],
          })
          navigate("/dashboard/company-details/manage-workplace")
        } else {
          toast.error(data.msg)
        }
      },
    }
  )

  if (isAddressesLoading) {
    return (
      <div className="w-full h-[400px] flex items-center justify-center">
        <Spin size="large" />
      </div>
    )
  }

  const address = addresses?.find(
    (item) => item.officeAddressId.toString() === id
  )

  const onFinish = (values: any) => {
    const { addressLine1, addressLine2, city, zip, phone, displayName, taxId } =
      values
    const item = states?.find((state) => state.description === values.state)
    const ctry = countries?.find(
      (country) => country.description === values.country
    )
    const taxOffice = taxOffices?.find(
      (taxOffice) => taxOffice.description === values.taxOffice
    )

    const nhf = housingLocations?.find(
      (housingLocation) =>
        housingLocation.description === values.housingLocation
    )

    submit({
      office_address_id: id,
      edit_office_address_line_1: addressLine1,
      edit_office_address_line_2: addressLine2,
      edit_office_address_city: city,
      edit_office_address_state_code: item?.code,
      edit_office_address_zip_code: zip,
      edit_office_address_country_code: ctry?.alpha2Code,
      edit_office_address_phone: phone,
      edit_display_name: displayName,
      edit_tax_office_name: taxOffice?.description,
      edit_tax_office_code: taxOffice?.code,
      edit_tax_id: taxId,
      edit_housing_location_name: nhf?.description || "",
      edit_housing_location_code: nhf?.code || "",
      au_comment: "",
    })
    mixPanelEvent("company-edit-workplace-init")
  }

  return (
    <DashboardContainer>
      <FormSectionWrapper
        isActive
        title={`Edit workplace `}
        subtitle="To automate your payroll filings, we need to have your company’s
            accurate addresses. Please take a minute and enter them below."
        footerBtns={
          <div className="flex justify-between items-center gap-2">
            {address?.isActive ? (
              <button
                onClick={() => disableAddress()}
                className="text-[#F41418] font-avenir text-sm font-bold disabled:opacity-50"
                disabled={isDisablingAddress}
              >
                Deactivate
              </button>
            ) : (
              <button
                onClick={() => enableAddress()}
                className="text-[#F41418] font-avenir text-sm font-bold disabled:opacity-50"
                disabled={isEnablingAddress}
              >
                Activate
              </button>
            )}

            <div className="flex justify-end w-full gap-3">
              <Button
                color="neutral"
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/dashboard/company-details/manage-workplace")
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting}
                submitting={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        }
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          addressLine1: address?.addressLine1,
          addressLine2: address?.addressLine2,
          city: address?.addressCity,
          state: address?.addressState,
          zip: address?.zipCode,
          country: address?.country,
          taxOffice: address?.taxOfficeName,
          housingLocation: address?.housingLocationName,
          phone: address?.phone,
          displayName: address?.displayName,
          taxId: address?.taxId,
        }}
      >
        <div className="px-4 md:px-6 xl:px-[60px]">
          <div className="bg-[#FFF6E6] border-l-8 border-[#F39F03] text-[##42526D] py-2.5 px-2 mb-6 ">
            Remember to update your filing and mailing address with relevant
            government authorities. This is your current filing and mailing
            address, so remember to update your filing and mailing address with
            relevant government authorities. To deactivate this workplace, use
            another workplace as your filing and mailing address.
          </div>

          <div className="flex flex-col lg:flex-row lg:gap-10 ">
            <div className="w-full lg:w-1/2">
              <CustomInput
                label="Address line 1"
                name="addressLine1"
                required
              />
              <CustomInput
                label="Address line 2"
                name="addressLine2"
              />
              <CustomInput
                label="City"
                name="city"
              />
              {/* country */}
              <CustomSelect
                label="Country"
                name="country"
                required
                onChange={(selected) => {
                  const country = countries?.find(
                    (country) => country.description === selected
                  )
                  setAlpha2Code(country?.alpha2Code || "")
                  setTimeout(() => {
                    form.setFieldsValue({
                      state: "",
                      taxOffice: "",
                      housingLocation: "",
                    })
                    refetch()
                  }, 100)
                }}
                isLoading={isCountriesLoading}
                options={
                  isCountriesLoading
                    ? []
                    : countries?.map((state) => ({
                        label: state.description,
                        value: state.description,
                      }))
                }
                placeholder="Please select"
              />
              {/* ---- States ---- */}
              <CustomSelect
                label="State/Province"
                name="state"
                required
                onChange={(selected) => {
                  const state = states?.find(
                    (state) => state.description === selected
                  )
                  setStateCode(state?.code || "")
                  setTimeout(() => {
                    form.setFieldsValue({
                      taxOffice: "",
                      housingLocation: "",
                    })
                    refetchTaxOffices()
                    refetchHousingLocationsOffices()
                  }, 100)
                }}
                isLoading={isStatesLoading}
                options={
                  isStatesLoading
                    ? []
                    : states?.map((state) => ({
                        label: state.description,
                        value: state.description,
                      }))
                }
                placeholder="Please select"
              />
            </div>

            <div className="w-full lg:w-1/2">
              {/*  */}
              <CustomInput
                label="Zip Code"
                name="zip"
              />
              <CustomInput
                label="Phone"
                name="phone"
                required
                maxLength={15}
              />
              <CustomInput
                label="Display Name"
                name="displayName"
                required
              />
              {/* ---- Tax Office ---- */}
              <CustomSelect
                label="Tax Office"
                name="taxOffice"
                isLoading={isTaxOfficesLoading}
                options={
                  isTaxOfficesLoading
                    ? []
                    : taxOffices?.map((state) => ({
                        label: state.description,
                        value: state.description,
                      }))
                }
                placeholder="Please select"
              />
              <CustomInput
                label="Tax ID"
                name={"taxId"}
              />
              <CustomSelect
                label="NHF Location"
                name="housingLocation"
                isLoading={isHousingLocationsLoading}
                options={
                  isHousingLocationsLoading
                    ? []
                    : housingLocations?.map((state) => ({
                        label: state.description,
                        value: state.description,
                      }))
                }
                placeholder="Please select"
              />
            </div>
          </div>
        </div>
      </FormSectionWrapper>
    </DashboardContainer>
  )
}

export default EditWorkplaceForm
