import { matchRoutes, useLocation, useNavigate } from "react-router-dom"
import { TopActionBar } from "../layouts/TopActionBar"
import { ActionBarTab } from "./ActionBarTab"
import { useAuthStore } from "stores"
import { devUrl, localUrl, stagingUrl } from "utils/constants"

export const SettingsTopActionBar: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useAuthStore()
  const origin = window.location.origin
  const show = origin === stagingUrl || origin === devUrl || origin === localUrl
  const isOando = user?.userOrganisationId == "354" || show
  return (
    <div className="h-full flex items-center gap-6 px-4 md:px-6 lg:px-10 border-y scrollbar-hide border-y-neutral40 bg-white overflow-x-auto">
      {[
        // {
        //   title: "Permissions",
        //   route: "/dashboard/settings/permissions",
        // },
        {
          title: "Admins",
          route: "/dashboard/settings/permissions/admins",
        },
        // {
        //   title: "Integration",
        //   route: "/dashboard/settings/integration",
        // },
        {
          title: "HR & Payroll",
          route: "/dashboard/settings/hr-and-payroll",
        },
        {
          title: "Approval Workflows",
          route: "/dashboard/settings/approval-workflow",
        },
        {
          title: "Plan & Billing",
          route: "/dashboard/settings/plan-and-billing",
        },
        {
          title: "Customization",
          route: "/dashboard/settings/customization",
        },
        isOando
          ? {
              title: "Integrations",
              route: "/dashboard/settings/integrations",
            }
          : null,
        {
          title: "Single Sign-On",
          route: "/dashboard/settings/sso",
        },
      ]
        .filter((it) => it)
        .map(
          (item, index) =>
            item && (
              <div>
                {" "}
                <ActionBarTab
                  key={index}
                  action={() => navigate(item.route)}
                  active={
                    matchRoutes([{ path: `${item.route}/*` }]!, pathname) !==
                    null
                  }
                  title={item.title}
                />
              </div>
            )
        )}
    </div>
  )
}
