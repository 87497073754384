import { Button, LinkButton } from "components"
import { useCustomizationSettingsStore } from "stores/customization-settings"

const SSO = () => {
  const { customizationSettings } = useCustomizationSettingsStore()

  return (
    <>
      <div className="f grid place-content-center ">
        <div className="mt-20">
          <a
            color="primary"
            className={`bg-primary500 text-white rounded-5px disabled:opacity-50 focus:outline-none focus:shadow-none disabled:cursor-not-allowed py-2 px-4 text-sm  font-avenir focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 font-semibold transition-all duration-100 ease-linear hover:bg-primary700 hover:text-a11y focus:ring-primary500 active:bg-primary700 focus:bg-primary700`}
            href={
              "https://pade-node-stg.azurewebsites.net/v1/client-auth/authenticate/sso?email=guestpadeuser1@gmail.com&clientId=64128148569A&clientSecret=a365b965d4ab20badf3ce5aebd60d8c7b12aba90bb9e1dfccba4b9da89940c5d"
            }
            // target="_blank"
          >
            Continue with SSO
          </a>
        </div>
      </div>
    </>
  )
}

export default SSO
