import {
  ArrowDownCircleIcon,
  ArrowRightCircleIcon,
  ArrowRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"
import { Alert } from "antd"
import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import React from "react"
interface props {
  onClose: () => void
  open: boolean
}
const FeeIncrease = ({ onClose, open }: props) => {
  return (
    <CustomModal
      title={
        <div className=" text-primary500 gap-1 flex items-center">
          <img
            src="https://padefiles.blob.core.windows.net/images/c81a5a34-8c43-472b-823f-e7ebac73d3e0.svg"
            alt=""
          />
          <p> New Update</p>
        </div>
      }
      onCancel={onClose}
      open={open}
      width={514}
    >
      <div className="">
        <h4 className="text-padeBlack font-bold text-xl mb-1 font-circular">
          {" "}
          Our EWA Fees Have Been Revised.
        </h4>
        <p className="text-[#102149BA] font-circular mb-[31px]">
          We have updated the Earned Wage Access (EWA) fees. The new fees will
          be calculated and applied at the withdrawal page. Thank you for your
          understanding.
        </p>

        <Button
          color="primary"
          className="w-full"
          onClick={onClose}
        >
          Got It
        </Button>
      </div>
    </CustomModal>
  )
}

export default FeeIncrease
