import React from "react"
import styled from "styled-components"

export const AppTableContainer = ({ children }: any) => {
  return <div className="max-w-full overflow-x-scroll">{children}</div>
}

const TableContainer = styled.div`
  th {
    min-width: 150px;
  }

  td {
    width: 50px;
  }

  thead,
  tfoot {
    display: table;
    width: 100%;
  }
  tbody {
    height: 300px;
    overflow: auto;
    overflow-x: hidden;
    display: table;
    display: block;
    width: 100%;
  }
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
`

export const ScrollTable = styled.table`
  // tbody {
  //   height: 300px;
  //   overflow: auto;
  //   overflow-x: hidden;
  //   display: block;
  //   width: 100%;
  // }

  // thead,
  // tbody tr {
  //   display: table;
  //   width: 100%;
  //   table-layout: fixed;
  // }
`
