import {
  CalendarIcon,
  ChevronRightIcon,
  ClockIcon,
} from "@heroicons/react/24/outline"
import { OverviewConversation } from "pages/employee-dashboard/performance/overview/interface"
interface props {
  item: OverviewConversation
  handleView: () => void
}
const Conversation = ({ item, handleView }: props) => {
  return (
    <div className="border rounded py-2.5 px-3 flex items-center gap-2.5 justify-between">
      <div className="">
        <h6 className="text-sm  font-circular text-padeBlack font-medium">
          {item?.nick_name}
        </h6>
        <div className="flex items-center  mt-1">
          <span className="text-[#6C7A93E5] text-[13px] font-circular mr-6 flex items-center">
            <CalendarIcon className="h-3 w-3" />

            {item?.start_date}
          </span>
          <div className="flex items-center gap-1">
            <span className="text-[#6C7A93E5] text-[13px] font-circular flex items-center">
              <ClockIcon className="h-3 w-3" />

              {item?.start_time}
              {item?.start_time_meridian}
            </span>
            <span className="text-[#6C7A93E5] text-[13px] font-circular flex items-center">
              -
            </span>
            <span className="text-[#6C7A93E5] text-[13px] font-circular flex items-center">
              <ClockIcon className="h-3 w-3" />

              {item?.end_time}
              {item?.end_time_meridian}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <p className="text-padeBlack  bg-padeLightBlue px-2.5 rounded  text-[13px]">
          {item?.conversation_type}
        </p>
        <button onClick={handleView}>
          <ChevronRightIcon className="text-primary700 h-4 w-4" />
        </button>
      </div>
    </div>
  )
}

export default Conversation
