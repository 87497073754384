import React from "react"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { IBeneficiaries } from "pages/employee-dashboard/wallet/wallet/beneficiaries/interface"
import { getAcronym } from "pages/employee-dashboard/wallet/wallet/Wallet"
interface Props {
  beneficiariesData: IBeneficiaries | undefined
  handleClick: () => void
}
const QuickSend = ({ beneficiariesData, handleClick }: Props) => {
  return (
    <div className=" h-[194px] mt-6">
      <CustomCard
        title="Beneficiaries"
        ctaBtn={
          <button
            className="text-primary500 flex text-sm items-center font-avenir"
            onClick={handleClick}
          >
            See saved beneficiaries{" "}
            <span>
              <ChevronRightIcon className="h-4 w-4 ml-1" />
            </span>
          </button>
        }
        rootClassName="h-full !px-0"
        headerClassName="px-4 border-b border-[#F0F2F5]"
      >
        <div className=" flex px-[36px] py-6 items-center gap-[40px] justify-start overflow-scroll scrollbar-hide">
          {beneficiariesData && beneficiariesData?.data?.length > 0 ? (
            beneficiariesData?.data?.map((beneficiary, index) => (
              <button
                key={index}
                className="w-auto flex flex-col items-center   "
                // onClick={() => {
                //   if (beneficiary?.type === "bank") {
                //     navigate(
                //       `/employee/dashboard/wallet/send-money-bank?x=${beneficiary?._id}`
                //     )
                //   } else {
                //     navigate(
                //       `/employee/dashboard/wallet/send-money-wallet?x=${beneficiary?._id}`
                //     )
                //   }
                // }}
              >
                <div className="h-12 w-12  rounded-full bg-[#E8F0FE] text-lg font-circular  text-center flex justify-center items-center">
                  {getAcronym(beneficiary?.accountName)}
                </div>
                <h6 className="max-w-[110px] mt-2 text-[12px] text-[#101928] font-circular truncate   ">
                  {beneficiary?.accountName}
                </h6>
                <p className="text-[#98A2B3] text-[11px]  ">
                  {beneficiary?.bankName}
                </p>
              </button>
            ))
          ) : (
            <div className="w-full h-full text-center">
              Your beneficiaries will appear here
            </div>
          )}
        </div>
      </CustomCard>
    </div>
  )
}

export default QuickSend
