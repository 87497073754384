import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useRef } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"
import { useAuthStore } from "stores"
import { useGet } from "utils/useFetch"
import { Dropdown, Spin } from "antd"
import { AppTableContainer } from "components/custom/AppTableContainer"
import { DetailsSummary } from "../components/DetailsSummary"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import ReactToPrint from "react-to-print"
import * as XLSX from "xlsx"
import { ChevronDownIcon } from "@heroicons/react/24/outline"

const PayRollReportDetails = () => {
  const { user } = useAuthStore()
  const { pathname } = useLocation()
  const { payrollId } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()
  // const [format, setFormat] = useState<"a" | "b">("a")

  const format: "compact" | "detailed" =
    (searchParams.get("format") as "compact" | "detailed") || "detailed"

  const type = searchParams.get("type")

  const { isLoading: getting, data } = useGet<any>({
    url:
      type === "preview"
        ? `${
            pathname.includes("approval") ? "" : "/admin"
          }/payrolls/view/report_preview?id=${payrollId}&x=full`
        : `${
            pathname.includes("approval") ? "" : "/admin"
          }/payrolls/view/report?id=${payrollId}&x=full`,
    onsuccess: () => {
      mixPanelEvent(
        type === "preview"
          ? "payroll-report-preview-listing"
          : "payroll-report-listing"
      )
    },
  })

  //@: For pdf report printing
  const ref: any = useRef()

  const className = "text-padeBlack font-[600] cursor-pointer select-none"

  //rgba(232, 236, 247, 1)

  return (
    <DashboardWrapper>
      <Spin spinning={getting}>
        <div className="font-avenir w-[100%]">
          <div className="w-full flex items-center justify-between gap-3 py-[30px]">
            <div>{/* <AppBackButton /> */}</div>
            <div className="flex gap-3 items-center">
              {/* {ref && (
                <AppPdfDownloadButton
                  title={data?.report_download_title}
                  ref={ref}
                />
              )} */}
              <div className="p-2 flex items-center gap-2 border bg-white cursor-pointer rounded-[5px]">
                <div
                  className={`flex items-center gap-1 cursor-pointer py-1 px-2 rounded-sm`}
                  style={{
                    background:
                      format === "compact"
                        ? "rgba(232, 236, 247, 1)"
                        : "inherit",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_2103_18765"
                      // style="mask-type:alpha"
                      style={{
                        maskType: "alpha",
                      }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                      />
                    </mask>
                    <g mask="url(#mask0_2103_18765)">
                      <path
                        d="M3 11V3H11V11H3ZM3 21V13H11V21H3ZM13 11V3H21V11H13ZM13 21V13H21V21H13ZM5 9H9V5H5V9ZM15 9H19V5H15V9ZM15 19H19V15H15V19ZM5 19H9V15H5V19Z"
                        fill="#0C1B3D"
                        fill-opacity="0.7"
                      />
                    </g>
                  </svg>
                  <div
                    className={className}
                    onClick={() => {
                      searchParams.set("format", "compact")
                      setSearchParams(searchParams)
                    }}
                  >
                    Compact
                  </div>
                </div>
                <div
                  className={`flex items-center gap-1 cursor-pointer py-1 px-2 rounded-sm`}
                  style={{
                    background:
                      format === "detailed"
                        ? "rgba(232, 236, 247, 1)"
                        : "inherit",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_2103_18779"
                      // style="mask-type:alpha"
                      style={{
                        maskType: "alpha",
                      }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect
                        width="24"
                        height="24"
                        fill="#D9D9D9"
                      />
                    </mask>
                    <g mask="url(#mask0_2103_18779)">
                      <path
                        d="M3 18.7143V7.28571C3 6.65714 3.19583 6.11905 3.5875 5.67143C3.97917 5.22381 4.45 5 5 5H19C19.55 5 20.0208 5.22381 20.4125 5.67143C20.8042 6.11905 21 6.65714 21 7.28571V18.7143C21 19.3429 20.8042 19.881 20.4125 20.3286C20.0208 20.7762 19.55 21 19 21H5C4.45 21 3.97917 20.7762 3.5875 20.3286C3.19583 19.881 3 19.3429 3 18.7143ZM10.325 11.8571H19V7.28571H10.325V11.8571ZM15.675 18.7143H19V14.1429H15.675V18.7143ZM10.325 18.7143H13.675V14.1429H10.325V18.7143ZM5 18.7143H8.325V7.28571H5V18.7143Z"
                        fill="#0C1B3D"
                        fill-opacity="0.7"
                      />
                    </g>
                  </svg>

                  <div
                    className={className}
                    onClick={() => {
                      searchParams.set("format", "detailed")
                      setSearchParams(searchParams)
                    }}
                  >
                    Detailed
                  </div>
                </div>
              </div>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      // label: <button>PDF</button>,
                      label: (
                        <ReactToPrint
                          content={() => ref.current}
                          trigger={() => (
                            <button className="w-[100%] h-[100%] text-left">
                              PDF
                            </button>
                          )}
                          documentTitle={`${data?.report_download_title}.pdf`}
                          // ref={ref}
                          // pageStyle={
                          //   "overflow:scroll;width:100%;height:100%;background-color:red"
                          // }
                        />
                      ),

                      // onClick: () => {
                      //   // setFormat("a")
                      //   const doc = new jsPDF({
                      //     orientation: "landscape",
                      //     unit: "in",
                      //     format: [4, 2],
                      //   })

                      //   doc.html(ref.current, {
                      //     callback: (doc: jsPDF) =>
                      //       doc.save(`${data?.report_download_title}.pdf`, {
                      //         returnPromise: true,
                      //       }),
                      //     html2canvas:
                      //   })
                      //   // searchParams.set("format", "a")
                      //   // setSearchParams(searchParams)
                      //   // exportTableToCSV(data?.report_download_title + ".csv")
                      // },
                    },
                    {
                      key: "2",
                      label: <button>Excel</button>,
                      onClick: async () => {
                        const wb = XLSX.utils.book_new()
                        const ws = XLSX.utils.table_to_sheet(ref.current, {
                          cellStyles: true,
                        })
                        const sheet =
                          data?.payrollStatusId < 5
                            ? "Payroll Journal Report (Draft)"
                            : "Payroll Journal Report"
                        XLSX.utils.book_append_sheet(wb, ws, sheet)
                        XLSX.writeFile(
                          wb,
                          `${data?.report_download_title}.xlsx`,
                          {
                            cellStyles: true,
                          }
                        )
                      },
                    },
                  ],
                }}
              >
                <div className="flex items-center gap-2 bg-primary500 text-white px-2 py-2 rounded-[5px] cursor-pointer select-none">
                  <div className="whitespace-nowrap">Export As</div>
                  <ChevronDownIcon className="w-5 h-5 text-white" />
                </div>
              </Dropdown>
              {/* {format === "a" && (
                <AppCSVDownloadButton title={data?.report_download_title} />
              )} */}
              {/* {format === "b" && ( */}
              {/* <div
                onClick={() => {
                  const wb = XLSX.utils.book_new()
                  const ws = XLSX.utils.table_to_sheet(ref.current, {
                    cellStyles: true,
                  })
                  XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
                  XLSX.writeFile(wb, `${data?.report_download_title}.xlsx`, {
                    cellStyles: true,
                  })
                }}
                className="px-4 py-2 font-circular !text-sm text-white rounded-md bg-primary400 flex items-center cursor-pointer"
              >
                <DocumentArrowDownIcon className="w-5 h-5 mr-1" />
                <span>Download</span>
              </div> */}
            </div>
          </div>
          <div
            className=" bg-white p-4 flex gap-2 flex-col w-[100%] box-border"
            ref={ref}
          >
            <div className="py-4 border-b border-gray-100">
              <div className="flex justify-between  gap-2 items-center">
                <div className=" text-lg font-[600] text-neutral500 ">
                  {data?.payrollStatusId < 5
                    ? "Payroll Journal Report (Draft)"
                    : "Payroll Journal Report"}
                </div>
                {data?.logoUploaded && (
                  <div className="h-[50px] w-[50px]">
                    <img
                      className="float-right object-contain clear-left"
                      src={`${user?.organisationAvatarFilename}`}
                      alt="logo"
                      height="100%"
                      width="100%"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between gap-3 items-center">
              <div className="text-neutral500">
                Report created on: {data?.report_date}
              </div>
              <div className="flex justify-end  flex-col items-end">
                <div className="text-neutral500">{user?.userOrganisation}</div>
                <div className="text-neutral500">
                  {data?.officeAddress?.address_line_1}
                </div>
                <div className="text-neutral500">
                  {data?.officeAddress?.address_city}&nbsp;
                  {data?.officeAddress?.address_state}&nbsp;
                  {data?.officeAddress?.zip_code}&nbsp;
                  {data?.officeAddress?.country}
                </div>
              </div>
            </div>

            <AppTableContainer>
              <DetailsSummary
                data={data}
                format={format}
              />
            </AppTableContainer>
          </div>
        </div>
      </Spin>
    </DashboardWrapper>
  )
}

export default PayRollReportDetails
