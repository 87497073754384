import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useState } from "react"
import { Input, Spin, Tooltip } from "antd"

import { useGet } from "utils/useFetch"
import { useLocation, useNavigate } from "react-router-dom"
import { ICurrencyInfo } from "../crossborder/interface/payouts"
import ViewGlobal from "../crossborder/ViewGlobal"
import { Button, Label, P2 } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import { useMutation } from "@tanstack/react-query"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

const PreviewCustomSchedule = () => {
  const navigate = useNavigate()
  const myParam = useLocation().search
  const { toast } = useAppNotificationStore()
  const x = new URLSearchParams(myParam).get("x")
  const location = useLocation()
  const { description, status, statusId, date, payment_schedule_id } =
    location.state || {}
  const [showViewModal, setShowViewModal] = useState(false)
  const [reviewType, setReviewType] = useState("")
  const [modalData, setModalData] = useState<any>(null)
  const [reason, setReason] = useState("")
  const [activeCurrency, setActiveCurrency] = useState<ICurrencyInfo | null>(
    null
  )

  const { isLoading: isCurrenciesLoading, data: currenciesData } = useGet<
    ICurrencyInfo[]
  >({
    url: `admin/payment_schedules/view/currencies?id=${x}`,
    onSuccess: (data: any) => {
      {
        data.length > 1
          ? setActiveCurrency({
              currency_code: "Global",
              currency_symbol: "$",
              total: 0,
              count: 0,
            })
          : setActiveCurrency({
              currency_code: data[0]?.currency_code,
              currency_symbol: data[0]?.currency_symbol,
              total: data[0]?.total,
              count: data[0]?.count,
            })
      }
    },
  })

  function handleDeclineAndApprove(type: "approve" | "decline") {
    // mixPanelEvent(`req-leaves-request-${type}-init`)
    declineOrApprove({
      id: Number(modalData?.payment_schedule_id),
      reason,
      type,
    })
  }
  const { isLoading: isApprovingOrDeclininng, mutate: declineOrApprove } =
    useMutation({
      mutationFn: async ({
        id,
        reason,
        type,
      }: {
        id: number
        reason: string
        type: "decline" | "approve"
      }) => {
        const endpoint =
          type === "approve"
            ? `/admin/payment_schedules/review_payment_schedule?id=${id}&note=${
                reason || "approve"
              }&actionType=Approve`
            : `/admin/payment_schedules/review_payment_schedule?id=${id}&note=${
                reason || '" "'
              }&actionType=Decline`

        const res = await axiosInstance.post(endpoint)
        return res.data
      },
      onSuccess: (data, { type }) => {
        if (data.status === "success") {
          navigate(-1)
          data?.msg && toast.success(data.msg)
        } else {
          toast.error(data.msg)
        }
      },
    })
  return (
    <DashboardWrapper>
      <div>
        <h6 className="text-sm text-padeBlack font-circular mb-3">
          Payment for {description}
        </h6>

        <ViewGlobal
          currencies={currenciesData}
          activeCurrency={activeCurrency}
        />
        {/* FOOTER */}
        <div className="fixed bottom-0 flex justify-end left-0 right-0 h-[64px] gap-4  py-3 px-20 bg-white ">
          <Button
            color="primary"
            onClick={() => {
              setModalData({
                status,
                statusId,
                date,
                description,
                payment_schedule_id,
              })
              setShowViewModal(true)
            }}
          >
            Review
          </Button>
        </div>
        <CustomModal
          destroyOnClose
          open={showViewModal}
          onCancel={() => {
            setShowViewModal(false)
            setModalData(null)
          }}
          title={
            <>
              {modalData?.status === "New"
                ? "Pending"
                : modalData?.status === "Approved"
                ? "Approved"
                : "Declined"}{" "}
              Payment Schedule
            </>
          }
          footer={
            <div className="w-full justify-end flex">
              {modalData?.statusId === "3" ? (
                <>
                  <Button
                    color="destructive"
                    className="mr-3"
                    disabled={reason === "" || isApprovingOrDeclininng}
                    submitting={isApprovingOrDeclininng}
                    onClick={() => {
                      setReviewType("decline")
                      handleDeclineAndApprove("decline")
                    }}
                  >
                    Decline
                  </Button>
                  {/* ======== Approve Btn ======== */}
                  {/* ======== Approve Btn ======== */}
                  <Button
                    color="primary"
                    className=""
                    disabled={isApprovingOrDeclininng}
                    onClick={() => {
                      setReviewType("approve")
                      handleDeclineAndApprove("approve")
                    }}
                    submitting={
                      isApprovingOrDeclininng && reviewType === "approve"
                    }
                  >
                    Approve
                  </Button>
                </>
              ) : (
                <Button
                  color="neutral"
                  className=""
                  onClick={() => {
                    setShowViewModal(false)
                    setModalData(null)
                  }}
                >
                  Close
                </Button>
              )}
            </div>
          }
        >
          <div className="flex flex-col items-center justify-center w-full h-full gap-2 ">
            <div className="!px-0 w-full">
              <div className="mb-4 space-y-1 ">
                <P2 className=" font-semibold text-neutral500 ">Date</P2>
                <P2 className="font-normal text-neutral400">
                  {modalData?.date}
                </P2>
              </div>

              <div className="mb-4 space-y-1 ">
                <P2 className=" font-semibold  text-neutral500">Description</P2>
                <P2 className="font-normal text-neutral400">
                  {modalData?.description}
                </P2>
              </div>

              {/* ======================= */}
              {/* ======= Note ========= */}
              {modalData?.statusId === "3" && (
                <div className="w-full mb-4">
                  <Tooltip
                    title={`Leave a note, this can be helpful if you're not the final approver.`}
                  >
                    <div className="flex gap-2 w-fit">
                      <Label
                        htmlFor="note"
                        className="font-avenir text-neutral500 font-semibold"
                      >
                        Note{" "}
                      </Label>

                      <ExclamationCircleIcon className="w-5 h-5 opacity-60 hover:opacity-100" />
                    </div>
                  </Tooltip>
                  <div className="my-1" />
                  <Input.TextArea
                    className="min-h-[82px]"
                    id="mobile-number"
                    required={false}
                    placeholder="Provide reason if declining"
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </CustomModal>
      </div>
    </DashboardWrapper>
  )
}

export default PreviewCustomSchedule
