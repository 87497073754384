import SurveyLayout from "components/new-stuff/layout/survey/SurveyLayout"
import React from "react"
import { Navigate, Route } from "react-router-dom"

const Survey = React.lazy(() => import("pages/survey/Survey"))

const SurveyRoutes = () => {
  return (
    <Route
      path="/survey"
      element={<SurveyLayout />}
    >
      <Route
        index
        element={<Survey />}
      />
    </Route>
  )
}

export default SurveyRoutes
